import { ActionType, IAction } from 'types/action';
import {
  IAppFormType,
  IUserModel,
  IUserState,
  IUserStatusModel,
} from 'types/user';

const initialUserStatus: IUserStatusModel = {
  isError: false,
  isLogin: false,
  token: '',
  message: '',
  country: '',
  role: 0,
  userId: 0,
  guid: '',
  name: '',
};

export const initialState: IUserState = {
  loading: false,
  currentUserStatus: initialUserStatus,
  userSession: {
    confirmAccount: false,
    isNewUser: false,
    sessionAttr: null,
  },
  currentUser: {} as IUserModel,
  appFormType: IAppFormType.INSPECTION,
  restoreNetConnection: false,
  hasNetConnection: true,
  showPasswdReqAlert: false,
  isErrorPasswdReqAlert: false,
  showCreateUsrAlert: false,
  msgCreateUsrAlert: '',
  isErrorCreateUsrAlert: false,
  showChangePasswdAlert: false,
  isErrorChangePasswdAlert: false,
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const userReducer = (state: IUserState, action: IAction) => {
  switch (action.type) {
    case ActionType.REQUEST_SIGN_IN:
      return {
        ...state,
        loading: true,
      };
    case ActionType.SET_USER_STATUS:
      return {
        ...state,
        currentUserStatus: action.payload,
        loading: false,
      };
    case ActionType.SET_USER_NEW_PASSWORD:
      return {
        ...state,
        userSession: {
          ...state.userSession,
          confirmAccount: action.payload?.confirmAccount,
          isNewUser: action.payload?.isNewUser,
          sessionAttr: action.payload?.sessionAttr,
        },
        currentUserStatus: { ...state.currentUserStatus, isError: false },
        loading: false,
      };
    case ActionType.SIGN_IN_SUCCESS:
      return {
        ...state,
        loading: true,
      };
    case ActionType.SIGN_IN_FAIL:
      return {
        ...state,
        loading: false,
      };
    case ActionType.INIT_STATE:
      return {
        ...initialState,
      };
    case ActionType.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload,
      };
    case ActionType.SET_APP_FORM_TYPE:
      return {
        ...state,
        appFormType: action.payload,
      };
    case ActionType.SET_RESTORE_NET_CONNECTION:
      return {
        ...state,
        restoreNetConnection: action.payload,
      };
    case ActionType.SET_NET_CONNECTION:
      return {
        ...state,
        hasNetConnection: action.payload,
      };
    case ActionType.SET_ALERT_REQUEST_PASSWORD:
      return {
        ...state,
        showPasswdReqAlert: action.payload.show,
        isErrorPasswdReqAlert: action.payload.isError,
      };
    case ActionType.SET_ALERT_NEW_USER:
      return {
        ...state,
        showCreateUsrAlert: action.payload.show,
        msgCreateUsrAlert: action.payload.message,
        isErrorCreateUsrAlert: action.payload.isError,
      };
    case ActionType.SET_ALERT_CHANGE_PASSWORD:
      return {
        ...state,
        showChangePasswdAlert: action.payload.show,
        isErrorChangePasswdAlert: action.payload.isError,
      };
    case ActionType.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      break;
  }

  return {
    ...state,
    loading: false,
  };
};

export default userReducer;
