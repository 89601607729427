import { IClaim, IWorkshopInfo } from './claimsTracker';
import { IRotateImage, ITransition, Paginator } from './common';
import { Event } from './event';
import { EventPhoto } from './images';

interface Owner {
  EdadDuenno: number;
  DuenoCodigoPostal: string;
  DuenoCompaniaSeguro: string;
  DuenoEstadoLicencia: string;
  DuenoLicencia: string;
  DuenoPoliza: string;
  DuenoPueblo: string;
  DuenoSexo: string;
  DuenoSS: string;
  DuenoEmail: string;
  DuenoTelCelular: string;
  DuenoTelTrabajo: string;
  DuenoDireccion1: string;
  DuenoDireccion2: string;
  EdadDuenno2: number;
  ClaimsTrackerId?: string;
}

export interface IInsuredOwnwer extends Owner {
  OwnerLastNames: string;
  OwnerName: string;
  OwnerPhone: string;
}

export interface IInjuredOwnwer extends Owner {
  OwnerLastNames2: string;
  OwnerName2: string;
  OwnerPhone2: string;
  Propietarioduenno: string;
  DepositoDirecto: string;
}

interface Driver {
  AseguradoNumCasaConductor: string;
  ConductorCodigoPostal: string;
  ConductorEstadoLicencia: string;
  ConductorMarbetePago: string;
  ConductorPoliza: string;
  ConductorPueblo: string;
  ConductorSS: string;
  ConductorEmail: string;
  ConductorTelTrabajo: string;
  ConductorVencimientoMarbete: string;
  ConductorDireccion2: string;
}

export interface IInsuredDriver extends Driver {
  AseguradoEdadConductor: number;
  DriverAddress: string;
  DriverLastName: string;
  DriverLicense: string;
  DriverMovil: string;
  DriverName: string;
  DriverPhone: string;
  DriverSex: string;
}

export interface IInjuredDriver extends Driver {
  DriverAddress2: string;
  DriverLastName2: string;
  DriverLicense2: string;
  DriverMovil2: string;
  DriverName2: string;
  DriverPhone2: string;
  DriverSex2: string;
  PerjudicadoEdadConductor: number;
}

export interface IVehicle {
  Marca: string;
  Modelo: string;
  Anno: string;
  Color: string;
  Millaje: string;
  VehicleVin: string;
  VehicleVin2: string;
  Reclamacion: string;
  Disclaimer: string;
}

export interface IGuia {
  IdInspeccion1: string;
  Fecha1: string;
  Placa1: string;
  Aseguradora1: string;
  Conductor1: string;
  Marca1: string;
  Modelo1: string;
  Year1: string;
  Firma1: string;
  IdInspeccion2: string;
  Fecha2: string;
  Placa2: string;
  Aseguradora2: string;
  Conductor2: string;
  Marca2: string;
  Modelo2: string;
  Year2: string;
  Firma2: string;
  CodigoCausaAsegurado: string;
  CodigoCausaTercero: string;
  Responsable: string;
  CuentaValidada: string;
  NoAapReasonId: string;
  RazonNoAap: string;
  OtraRazon: string;
  CorreoElectronico1: string;
  Justificacion: string;
  Adicional: string;
  Inciso: string;
  Imagen: string;
}

export interface IConfigData {
  NotificationEmail: string;
  PhoneNumber: string;
}

export interface IAditionalInfo {
  EventDigitalInspectOptimaAdicionalId: number;
  EventId: number;
  ConductorNombre: string;
  ConductorCedula: string;
  ConductorSS: string;
  ConductorSexo: string;
  ConductorEdad: string;
  ConductorNacionalidad: string;
  ConductorLugarTrabajo: string;
  ConductorOcupacion: string;
  ConductorTelResidencia: string;
  ConductorTelTrabajo: string;
  ConductorLugarResidencia: string;
  ConductorOtrosCausantesDelAccidente: string;
  ConductorCorreoElectronico: string;
  PropietarioNombre: string;
  PropietarioCedula: string;
  PropietarioSS: string;
  VehiculoPlaca: string;
  VehiculoColor: string;
  VehiculoMarca: string;
  VehiculoTipo: string;
  VehiculoAnno: string;
  Aseguradora: string;
  NumeroPoliza: string;
  EsResponsableDelAccidente1: string;
  EsResponsableDelAccidente2: string;
  ResponsableAccidenteCedula1: string;
  ResponsableAccidenteCedula2: string;
  ResponsableAccidenteFirma1: string;
  ResponsableAccidenteFirma2: string;
  NumeroVehiculo: string;
  TipoConductor: string;
}

export interface IOptima {
  InspectionId: number;
  FechaAccidente: string;
  HoraAccidente: string;
  NumeroVehiculosEnAccidente: string;
  ProvinciaAccidente: string;
  DistritoAccidente: string;
  CorregimientoAccidente: string;
  PobladoAccidente: string;
  CalleAccidente: string;
  AccidenteSucedioId: number;
  AccidenteTrayectoId: number;
  AccidenteSuperficieViaId: number;
  AccidenteCondicionesViaId: number;
  AccidenteDispositivosTransitoId: number;
  AccidenteClaseId: number;
  SuperficieOtros: string;
  CondicionesViaOtros: string;
  DispositivosTransitoOtros: string;
  ClaseAccidenteOtros: string;
  TestigoCedula1: string;
  TestigoFirma1: string;
  TestigoCedula2: string;
  TestigoFirma2: string;
  ResponsableFirmante1: string;
  ResponsableFirmante2: string;
  NarracionHechos: string;
  InspectorFirma: string;
}

export interface IThird {
  thirdId: number;
  thirdAdditionalId: number;
  thirdType: string;
  thirdTypeOwner: string;
  thirdTypeOwnerID: string;
  thirdDriverName: string;
  thirdDriverLastName: string;
  thirdDriverTypeID: string;
  thirdDriverID: string;
  thirdDriverPhone: string;
  thirdDriverEmail: string;
  thirdDriverInsuranceName: string;
  thirdDriverInsurancePolicy: string;
  thirdVehicleType: string;
  thirdVehiclePlate: string;
  thirdVehicleBrand: string;
  thirdVehicleModel: string;
  thirdVehicleYear: string;
  thirdVehicleColor: string;
  theyHaveInjured: string;
  VehicleDescription2: string; //TODO:: should check if this property is valid
  thirdDriverAddress: string;
  thirdDriverWorkPlace: string;
  thirdOwnerAddress: string;
  InjuryDescription: string;
  insuranceAffected: string;
  damageNotes: string;
}

export interface IInjuryPerson {
  injuryId: number;
  injuryNumber: number;
  injuryName: string;
  injuryIdentification: string;
  injuryPhone: string;
  injuryBirthday: string;
  injuryDescription: string;
  isMedicalTransfer: boolean;
  injuryMedicalPlace: string;
}

export interface ICoverage {
  coverageId: number;
  coverageName: string;
  Selected?: boolean;
}

export interface IInsuredVehicle extends IVehicle {
  VehicleLisencePlate: string;
  Ocurrido: string;
  Signature: string;
  FechaDueno1: string;
  AudioOcurrido: string;
}

export interface IInjuredVehicle extends IVehicle {
  VehicleLicensePlate2: string;
  VehicleDescription2: string;
  FirmaDueno2: string;
  FechaDueno2: string;
  AudioOcurrido: string;
}

export interface IConsequence {
  CodigoAsegurado: string;
  CodigoTercero: string;
  Responsable: string;
  Placa1: string;
  Placa2: string;
  Aseguradora1: string;
  Aseguradora2: string;
}

interface IInspectTemplate {
  [key: string]:
    | string
    | IInsuredOwnwer
    | IInjuredOwnwer
    | IInsuredDriver
    | IInjuredDriver
    | IInsuredVehicle
    | IInjuredVehicle
    | ITransition[]
    | IThird[]
    | IOptima
    | IAditionalInfo[]
    | IConfigData[]
    | number
    | boolean
    | undefined
    | IInjuryPerson[]
    | ICoverage[]
    | IGuia
    | IConsequence
    | null;
}

export interface IInspection extends IInspectTemplate {
  formIsValid: boolean;
  EventId?: number;
  EventRecord: string;
  APIKey: string;
  CompanyId?: number;
  CountryCode: string;
  CountryId?: number;
  UserInspectorId?: string;
  EventStateId?: number;
  ClaimantType?: string;
  InsuredOwner?: IInsuredOwnwer;
  InjuredOwner?: IInjuredOwnwer;
  InsuredDriver?: IInsuredDriver;
  InjuredDriver?: IInjuredDriver;
  InsuredVehicle?: IInsuredVehicle;
  InjuredVehicle?: IInjuredVehicle;
  Transitions?: ITransition[];
  EventDate: string;
  Cierre: string;
  Hora: string;
  LugarDelAccidente: string;
  EventAddress: string;
  CarreteraAccidente: string;
  KilometroAccidente: string;
  BarrioAccidente: string;
  MunicipioAccidente: string;
  Codificacion: string;
  VehiculosInvolucrados: number;
  NombrePolicia: string;
  ApellidosPolicia: string;
  PlacaPolicia: string;
  CuartelPolicia: string;
  QuerellaPolicia: string;
  EstuvoPresentePolicia: string;
  NombreReclamante: string;
  NumeroReclamacion: string;
  NumeroPoliza: string;
  InstitucionFinanciera: string;
  RutaTransito: string;
  Direccion: string;
  Sucursal: string;
  PersonalContacto: string;
  Telefono: string;
  Fecha: string;
  IndInvestigation: string;
  IndExpressAdjust: boolean;
  InvestigationObs: string;
  InspectorObservation: string;
  IsVitualInspector: number;
  Instruccion: string;
  ProjectId: string;
  EvaluationId: string;
  AcceptTerms: string;
  AcceptTermsSignature: string;
  AcceptTermsEmail: string;
  sinisterType: string;
  sinisterCause: string;
  noPoliza: string;
  provinceInspect: string;
  cantonInspect: string;
  districtInspect: string;
  placeDirection: string;
  eventDate: string;
  time: string;
  phone: string;
  idInspection: string;
  name: string;
  unitCode: string;
  registerDate: string;
  dispatchDate: string;
  endingDate: string;
  corredor: string;
  codePlan: string;
  idTitular: string;
  emailTitular: string;
  isOwner: string;
  brand: string;
  model: string;
  year: string;
  color: string;
  plate: string;
  plateMetallic: string;
  vehicleVIN: string;
  vehicleEngine: string;
  odometer: string;
  driverName: string;
  driverLastName: string;
  driverIdType: string;
  driverId: string;
  driverGender: string;
  driverBirthDate: string;
  driverPhone1: string;
  driverPhone2: string;
  driverEmail: string;
  driverLicenseType: string;
  driverNumLicense: string;
  driverExpireLicenseDate: string;
  eventDateInspection: string;
  timeInspection: string;
  provinceDirectionInspect: string;
  cantonDirectionInspect: string;
  districtDirectionInspect: string;
  placeDirectionInspect: string;
  eventDateSinister: string;
  timeSinister: string;
  provinceDirectionSinister: string;
  cantonDirectionSinister: string;
  districtDirectionSinister: string;
  placeDirectionSinister: string;
  collisionType: string;
  storyDetail: string;
  officerPresent: string;
  hasTrafficTicket: string;
  trafficTicketNumber: string;
  hearingDate: string;
  hasAlcolemia: string;
  alcolemiaOptions: string;
  hasFud: string;
  hasLegalAssistance: string;
  isInsuranceClient: string;
  ShouldUseExpressAdjust: boolean;
  thirds?: IThird[];
  optima?: IOptima;
  guia?: IGuia;
  AditionalInfo?: IAditionalInfo[];
  configdata?: IConfigData[];
  signatureAccident: string;
  signatureAccID: string;
  signatureInspector: string;
  courtName: string;
  inspectorDriverName: string;
  inspectorAcceptResp: string;
  inspectorName: string;
  despacho: string;
  arribo: string;
  cierre: string;
  EventInjury: string;
  injuryPersons?: IInjuryPerson[];
  coverages?: ICoverage[];
  qualitasCoverages?: ICoverage[];
  IndMRSAccompaniment: string;
  InsuredAccompanimentId: string;
  InsuredAccompanimentAmount: string;
  InsuredAccompanimentWorkshop: string;
  InsuredAccompanimentOther: string;
  IndFalconHeavy: string;
  IndFalconHeavyAffected: string;
  FalconHeavyAmount: string;
  AffectedAccompanimentId: string;
  AffectedAccompanimentAmount: string;
  AffectedAccompanimentWorkshop: string;
  AffectedAccompanimentOther: string;
  InSchedule: string;
  ResponsableOption: string;
  VehicleInjuryA: string;
  VehicleInjuryB: string;
  MRSAValueNumber: string;
  orbikaCode: string;
  orbikaCodeAffected: string;
  affectedTypeFollowUpId: string;
  insuredTypeFollowUpId: string;
  responOwnerName: string;
  responOwnerID: string;
  insuredVehiclePlate: string;
  damageVehiclePlate: string;
  harmedClientName: string;
  damageDetail: string;
  responSignature: string;
  insuranceName: string;
  indInspectionPlace: string;
  indRespAAP: string;
  vehicleDefinition: string;
  accountStatus: string;
  plateCircunstancesInsured: string;
  insuranceCircunstancesInsured: string;
  plateCircunstancesThird: string;
  insuranceCircunstancesThird: string;
  insuredCodeConsequences: string;
  thirdCodeConsequences: string;
  responsableConsequences: string;
  consequence?: IConsequence;
  siseReport: string;
  siseSiniestro: string;
  insuredDamageNotes: string;
  injuredDamageNotes: string;
  coordinates: string;
  scene: string;
  Croquis: string;
  confirmAAPForm?: boolean;
  optionAAPForm?: string;
  optionTextAAPForm?: string;
  Audited?: boolean;
  ReasonId: string;
  NoCoverageApplied: string;
  isOtherDriver: boolean | null;
  isDigitalForm: boolean | null;
  InsuredAccompanimentList?: string;
  AffectedAccompanimentList?: string;
  FollowUpList?: string;
  isActive: number;
  AudioStoryUrl?: any;
  HasNotInsuredDamage?: string;
  PaymentStatus?: string;
  InRangeDays?: string;
  sinisterTypePR: string;
  indAirbagsActive: string;
  indSeatBeltStagnant: string;
  indFrontAxleDamage: string;
  indRearAxleDamage: string;
  indVehicleLooseParts: string;
  indCustomerOpenTrunk: string;
  sinisterTypeInsuredPR: string;
  indAirbagsActiveInsured: string;
  indSeatBeltStagnantInsured: string;
  indFrontAxleDamageInsured: string;
  indRearAxleDamageInsured: string;
  indVehicleLoosePartsInsured: string;
  indCustomerOpenTrunkInsured: string;
  EnableClaimsTracker: boolean;
  RequireAssistance: string;
}

export interface IInspectionGrid {
  eventId?: number;
  statusName?: string;
  quotationNumber?: number;
  eventDate?: string;
  placa?: string;
  ownerName?: string;
  id?: string;
  cuentaCliente?: string;
  inspeccionadoPor?: string;
  nombre?: string;
  identificacion?: string;
  insertDate?: string;
  idPreinspeccion?: number;
  estado?: number;
}

export interface IAuditEvent {
  auditSection: number;
  auditIsActive: boolean;
  auditNoApply: boolean;
  auditTimeInit: string;
  auditTimeFini: string;
  auditEffectiveTime: number;
  auditMessage: string;
  sectionsAudit: number[];
  auditThirdSection: boolean;
  auditInjuredSection: boolean;
  auditPhotoSection: boolean;
}

export interface IInspectionState {
  inspections: Event[];
  statusesIndicators: any[];
  currentInspection: IInspection | null;
  newInspection: IInspection | null;
  actionMessage: string;
  currentImages: EventPhoto[];
  rotateImages: IRotateImage[];
  loading: boolean;
  plateFilter: string;
  recordFilter: string;
  requestParams: any;
  alertMessage?: string;
  alertType?: string;
  showAlert: boolean;
  showErrorModalImages: boolean;
  totalUploadImages: number;
  countUploadImages: number;
  inspectorUserName: string | null;
  audioStoryUrl?: any;
  audioPresignedUrl?: any;
  auditEvent: IAuditEvent;
  audioVehicleInsuredPresigned?: string;
  audioVehicleInjuredPresigned?: string;
  integrationResendConfirm: boolean;
  integrationResendEventId: number;
  customPhotosIsValid: boolean;
  claimsTrackerInfo?: IClaim[];
  claimsManagementInfo?: IClaim;
  workshopsInfo?: IWorkshopInfo[];
  aapFormRefresh?: boolean;
  expenseAmount?: string;
  partsAmount?: string;
  adjustAmount?: string;
  estimatedAmount?: string;
  initializeRetriesProcess: boolean;
  showAlertPhoto: boolean;
  alertMessagePhoto?: string;
  alertTypePhoto?: string;
  inProgress?: boolean;
  paginator?: Paginator;
}

// Default values
export const consequenceDefault: IConsequence = {
  CodigoAsegurado: '',
  CodigoTercero: '',
  Responsable: '',
  Placa1: '',
  Placa2: '',
  Aseguradora1: '',
  Aseguradora2: '',
};

export const insuredOwnerDefault: IInsuredOwnwer = {
  DuenoDireccion1: '',
  DuenoDireccion2: '',
  OwnerLastNames: '',
  OwnerName: '',
  OwnerPhone: '',
  EdadDuenno: 0,
  DuenoCodigoPostal: '',
  DuenoCompaniaSeguro: '',
  DuenoEstadoLicencia: '',
  DuenoLicencia: '',
  DuenoPoliza: '',
  DuenoPueblo: '',
  DuenoSexo: '',
  DuenoSS: '',
  DuenoEmail: '',
  DuenoTelCelular: '',
  DuenoTelTrabajo: '',
  EdadDuenno2: 0,
};

export const injuredOwnerDefault: IInjuredOwnwer = {
  DuenoDireccion1: '',
  DuenoDireccion2: '',
  OwnerLastNames2: '',
  OwnerName2: '',
  OwnerPhone2: '',
  EdadDuenno2: 0,
  DuenoCodigoPostal: '',
  DuenoCompaniaSeguro: '',
  DuenoEstadoLicencia: '',
  DuenoLicencia: '',
  DuenoPoliza: '',
  DuenoPueblo: '',
  DuenoSexo: '',
  DuenoSS: '',
  DuenoEmail: '',
  DuenoTelCelular: '',
  DuenoTelTrabajo: '',
  EdadDuenno: 0,
  Propietarioduenno: '2',
  DepositoDirecto: '2',
};

export const insuredVehicleDefault: IInsuredVehicle = {
  Marca: '',
  Modelo: '',
  Anno: '',
  Color: '',
  VehicleLisencePlate: '',
  Millaje: '',
  VehicleVin: '',
  VehicleVin2: '',
  Reclamacion: '',
  Ocurrido: '',
  Disclaimer: '',
  Signature: '',
  FechaDueno1: '',
  AudioOcurrido: '',
};

export const injuredVehicleDefault: IInjuredVehicle = {
  Marca: '',
  Modelo: '',
  Anno: '',
  Color: '',
  VehicleLicensePlate2: '',
  Millaje: '',
  VehicleVin2: '',
  VehicleVin: '',
  Reclamacion: '',
  VehicleDescription2: '',
  Disclaimer: '',
  FirmaDueno2: '',
  FechaDueno2: '',
  AudioOcurrido: '',
};

export const insuredDriverDefault: IInsuredDriver = {
  ConductorDireccion2: '',
  DriverAddress: '',
  DriverLastName: '',
  DriverLicense: '',
  DriverMovil: '',
  DriverName: '',
  DriverPhone: '',
  DriverSex: '',
  AseguradoEdadConductor: 0,
  AseguradoNumCasaConductor: '',
  ConductorCodigoPostal: '',
  ConductorEstadoLicencia: '',
  ConductorMarbetePago: '',
  ConductorPoliza: '',
  ConductorPueblo: '',
  ConductorSS: '',
  ConductorEmail: '',
  ConductorTelTrabajo: '',
  ConductorVencimientoMarbete: '',
};

export const injuredDriverDefault: IInjuredDriver = {
  ConductorDireccion2: '',
  DriverAddress2: '',
  DriverLastName2: '',
  DriverLicense2: '',
  DriverMovil2: '',
  DriverName2: '',
  DriverPhone2: '',
  DriverSex2: '',
  AseguradoNumCasaConductor: '',
  ConductorCodigoPostal: '',
  ConductorEstadoLicencia: '',
  ConductorMarbetePago: '',
  ConductorPoliza: '',
  ConductorPueblo: '',
  ConductorSS: '',
  ConductorEmail: '',
  ConductorTelTrabajo: '',
  ConductorVencimientoMarbete: '',
  PerjudicadoEdadConductor: 0,
};

export const OptimaDefault: IOptima = {
  InspectionId: 0,
  FechaAccidente: '',
  HoraAccidente: '',
  NumeroVehiculosEnAccidente: '',
  ProvinciaAccidente: '',
  DistritoAccidente: '',
  CorregimientoAccidente: '',
  PobladoAccidente: '',
  CalleAccidente: '',
  AccidenteSucedioId: 0,
  AccidenteTrayectoId: 0,
  AccidenteSuperficieViaId: 0,
  AccidenteCondicionesViaId: 0,
  AccidenteDispositivosTransitoId: 0,
  AccidenteClaseId: 0,
  SuperficieOtros: '',
  CondicionesViaOtros: '',
  DispositivosTransitoOtros: '',
  ClaseAccidenteOtros: '',
  TestigoCedula1: '',
  TestigoFirma1: '',
  TestigoCedula2: '',
  TestigoFirma2: '',
  ResponsableFirmante1: '',
  ResponsableFirmante2: '',
  NarracionHechos: '',
  InspectorFirma: '',
};

export const injuryPersonDefault: IInjuryPerson = {
  injuryId: 0,
  injuryNumber: 0,
  injuryName: '',
  injuryIdentification: '',
  injuryPhone: '',
  injuryBirthday: '',
  injuryDescription: '',
  isMedicalTransfer: false,
  injuryMedicalPlace: '',
};

export const coverageDefault: ICoverage = {
  coverageId: 0,
  coverageName: '',
};

export const qualitasCoveragesDefault: ICoverage = {
  coverageId: 0,
  coverageName: '',
  Selected: false,
};

export const GuiaDefault: IGuia = {
  IdInspeccion1: '',
  Fecha1: '',
  Placa1: '',
  Aseguradora1: '',
  Conductor1: '',
  Marca1: '',
  Modelo1: '',
  Year1: '',
  Firma1: '',
  IdInspeccion2: '',
  Fecha2: '',
  Placa2: '',
  Aseguradora2: '',
  Conductor2: '',
  Marca2: '',
  Modelo2: '',
  Year2: '',
  Firma2: '',
  CodigoCausaAsegurado: '',
  CodigoCausaTercero: '',
  Responsable: '',
  CuentaValidada: '',
  NoAapReasonId: '',
  RazonNoAap: '',
  OtraRazon: '',
  CorreoElectronico1: '',
  Justificacion: '',
  Adicional: '',
  Inciso: '',
  Imagen: '',
};

export const inspectionDefault: IInspection = {
  formIsValid: false,
  EventId: 0,
  EventRecord: '',
  APIKey: '',
  CompanyId: 0,
  CountryCode: '',
  CountryId: 0,
  UserInspectorId: '',
  EventStateId: 0,
  InsuredOwner: insuredOwnerDefault,
  InjuredOwner: injuredOwnerDefault,
  InsuredDriver: insuredDriverDefault,
  InjuredDriver: injuredDriverDefault,
  InsuredVehicle: insuredVehicleDefault,
  InjuredVehicle: injuredVehicleDefault,
  EventDate: '',
  Cierre: '',
  Hora: '',
  LugarDelAccidente: '',
  EventAddress: '',
  CarreteraAccidente: '',
  KilometroAccidente: '',
  BarrioAccidente: '',
  MunicipioAccidente: '',
  Codificacion: '',
  VehiculosInvolucrados: 0,
  NombrePolicia: '',
  ApellidosPolicia: '',
  PlacaPolicia: '',
  CuartelPolicia: '',
  QuerellaPolicia: '',
  EstuvoPresentePolicia: '',
  NombreReclamante: '',
  NumeroReclamacion: '',
  NumeroPoliza: '',
  InstitucionFinanciera: '',
  RutaTransito: '',
  Direccion: '',
  TipoCuenta: '',
  Sucursal: '',
  PersonalContacto: '',
  Telefono: '',
  Fecha: '',
  IndInvestigation: '',
  IndExpressAdjust: false,
  InvestigationObs: '',
  InspectorObservation: '',
  Croquis: '',
  FirmaCroquis: '',
  Firma: '',
  IsVitualInspector: 0,
  Instruccion: '',
  ProjectId: '',
  EvaluationId: '',
  AcceptTerms: '',
  AcceptTermsSignature: '',
  AcceptTermsEmail: '',
  ShouldUseExpressAdjust: false,
  sinisterType: '',
  sinisterCause: '',
  noPoliza: '',
  provinceInspect: '',
  cantonInspect: '',
  districtInspect: '',
  placeDirection: '',
  eventDate: '',
  time: '',
  phone: '',
  idInspection: '',
  name: '',
  unitCode: '',
  registerDate: '',
  dispatchDate: '',
  endingDate: '',
  corredor: '',
  codePlan: '',
  idTitular: '',
  emailTitular: '',
  isOwner: '',
  brand: '',
  model: '',
  year: '',
  color: '',
  plate: '',
  plateMetallic: '',
  vehicleVIN: '',
  vehicleEngine: '',
  odometer: '',
  driverName: '',
  driverLastName: '',
  driverIdType: '',
  driverId: '',
  driverGender: '',
  driverBirthDate: '',
  driverPhone1: '',
  driverPhone2: '',
  driverEmail: '',
  driverLicenseType: '',
  driverNumLicense: '',
  driverExpireLicenseDate: '',
  eventDateInspection: '',
  timeInspection: '',
  provinceDirectionInspect: '',
  cantonDirectionInspect: '',
  districtDirectionInspect: '',
  placeDirectionInspect: '',
  eventDateSinister: '',
  timeSinister: '',
  provinceDirectionSinister: '',
  cantonDirectionSinister: '',
  districtDirectionSinister: '',
  placeDirectionSinister: '',
  collisionType: '',
  storyDetail: '',
  officerPresent: '',
  hasTrafficTicket: '',
  trafficTicketNumber: '',
  hearingDate: '',
  hasAlcolemia: '',
  alcolemiaOptions: '',
  hasFud: '',
  hasLegalAssistance: '',
  isInsuranceClient: '',
  optima: { ...OptimaDefault },
  guia: { ...GuiaDefault },
  signatureAccident: '',
  signatureAccID: '',
  signatureInspector: '',
  courtName: '',
  inspectorDriverName: '',
  inspectorAcceptResp: '',
  inspectorName: '',
  despacho: '',
  arribo: '',
  cierre: '',
  EventInjury: '',
  IndMRSAccompaniment: '',
  InsuredAccompanimentId: '',
  InsuredAccompanimentAmount: '',
  InsuredAccompanimentWorkshop: '',
  InsuredAccompanimentOther: '',
  IndFalconHeavy: '',
  IndFalconHeavyAffected: '',
  FalconHeavyAmount: '',
  AffectedAccompanimentId: '',
  AffectedAccompanimentAmount: '',
  AffectedAccompanimentWorkshop: '',
  AffectedAccompanimentOther: '',
  InSchedule: '',
  ResponsableOption: '',
  VehicleInjuryA: '',
  VehicleInjuryB: '',
  MRSAValueNumber: '',
  orbikaCode: '',
  orbikaCodeAffected: '',
  affectedTypeFollowUpId: '',
  insuredTypeFollowUpId: '',
  responOwnerName: '',
  responOwnerID: '',
  insuredVehiclePlate: '',
  damageVehiclePlate: '',
  harmedClientName: '',
  damageDetail: '',
  responSignature: '',
  insuranceName: '',
  indInspectionPlace: '',
  indRespAAP: '',
  vehicleDefinition: '',
  accountStatus: '',
  plateCircunstancesInsured: '',
  insuranceCircunstancesInsured: '',
  plateCircunstancesThird: '',
  insuranceCircunstancesThird: '',
  insuredCodeConsequences: '',
  thirdCodeConsequences: '',
  responsableConsequences: '',
  consequence: consequenceDefault,
  siseReport: '',
  siseSiniestro: '',
  insuredDamageNotes: '',
  injuredDamageNotes: '',
  coordinates: '',
  scene: '',
  Audited: false,
  ReasonId: '',
  NoCoverageApplied: '',
  isOtherDriver: null,
  isDigitalForm: null,
  isActive: 1,
  HasNotInsuredDamage: '0',
  sinisterTypePR: '',
  indAirbagsActive: '',
  indSeatBeltStagnant: '',
  indFrontAxleDamage: '',
  indRearAxleDamage: '',
  indVehicleLooseParts: '',
  indCustomerOpenTrunk: '',
  sinisterTypeInsuredPR: '',
  indAirbagsActiveInsured: '',
  indSeatBeltStagnantInsured: '',
  indFrontAxleDamageInsured: '',
  indRearAxleDamageInsured: '',
  indVehicleLoosePartsInsured: '',
  indCustomerOpenTrunkInsured: '',
  EnableClaimsTracker: false,
  RequireAssistance: '',
};

export const transitionDefault: ITransition = {
  Name: '',
  Description: '',
  TransitionDate: '',
};

export const thirdDefault: IThird = {
  thirdId: 0,
  thirdAdditionalId: 0,
  thirdType: '',
  thirdTypeOwner: '',
  thirdTypeOwnerID: '',
  thirdDriverName: '',
  thirdDriverLastName: '',
  thirdDriverTypeID: '',
  thirdDriverID: '',
  thirdDriverPhone: '',
  thirdDriverEmail: '',
  thirdDriverInsuranceName: '',
  thirdDriverInsurancePolicy: '',
  thirdVehicleType: '',
  thirdVehiclePlate: '',
  thirdVehicleBrand: '',
  thirdVehicleModel: '',
  thirdVehicleYear: '',
  thirdVehicleColor: '',
  theyHaveInjured: '',
  VehicleDescription2: '',
  thirdDriverAddress: '',
  thirdDriverWorkPlace: '',
  thirdOwnerAddress: '',
  InjuryDescription: '',
  insuranceAffected: '',
  damageNotes: '',
};

export const ConfigDataDefault: IConfigData = {
  NotificationEmail: '',
  PhoneNumber: '',
};

export const AditionalInfoDefault: IAditionalInfo = {
  EventDigitalInspectOptimaAdicionalId: 0,
  EventId: 0,
  ConductorNombre: '',
  ConductorCedula: '',
  ConductorSS: '',
  ConductorSexo: '',
  ConductorEdad: '',
  ConductorNacionalidad: '',
  ConductorLugarTrabajo: '',
  ConductorOcupacion: '',
  ConductorTelResidencia: '',
  ConductorTelTrabajo: '',
  ConductorLugarResidencia: '',
  ConductorOtrosCausantesDelAccidente: '',
  ConductorCorreoElectronico: '',
  PropietarioNombre: '',
  PropietarioCedula: '',
  PropietarioSS: '',
  VehiculoPlaca: '',
  VehiculoColor: '',
  VehiculoMarca: '',
  VehiculoTipo: '',
  VehiculoAnno: '',
  Aseguradora: '',
  NumeroPoliza: '',
  EsResponsableDelAccidente1: '',
  EsResponsableDelAccidente2: '',
  ResponsableAccidenteCedula1: '',
  ResponsableAccidenteCedula2: '',
  ResponsableAccidenteFirma1: '',
  ResponsableAccidenteFirma2: '',
  NumeroVehiculo: '',
  TipoConductor: '',
};
