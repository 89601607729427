import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import clsx from 'clsx';
import { IInspectionContext, InspectionContext } from 'context/inspections';
import { getNoteTypes, getNotes, getNotesByType, saveNote } from 'context/inspections/inspectionUtils';
import styles from 'override-mui-styles';
import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { userActionsPermissions } from 'shared/utils';
import { NoteType, ResourceAction } from 'types/common';
import NotesList from '../notes/NotesList';
import NotesModal from '../notes/NotesModal';
import './styles.scss';

interface ClaimInfoProps {
  sicId?: number;
  className?: string;
}

const Observations: FunctionComponent<ClaimInfoProps> = ({
  sicId,
  className
}): JSX.Element => {
  const classes = styles();
  const containerRef = useRef<HTMLDivElement>(null);

  const {
    state: { currentInspection: event },
  } = useContext<IInspectionContext>(InspectionContext);

  const [showModal, setShowModal] = useState(false);
  const [notes, setNotes] = useState<any[]>([]);
  const [noteTypes, setNoteTypes] = React.useState<NoteType[]>([]);
  const [noteTypeSelected, setNoteTypeSelected] = React.useState('0');

  const fetchNotes = async (eventId: number) => {
    setNoteTypeSelected('0');

    return (await getNotes(eventId)).map((note) => ({
      ...note,
      creationDate: new Date(note.creationDate).toUTCString(),
    }));
  };

  useEffect(() => {
    loadNoteTypes().catch(console.error);
  }, []);

  async function loadNoteTypes() {
    const noteTypesToLoad = await getNoteTypes();
    if (noteTypesToLoad) {
      setNoteTypes(noteTypesToLoad);
    }
  }

  useEffect(() => {
    if (sicId) {
      fetchNotes(sicId).then(setNotes);
    }
    else if (event?.EventId) {
      fetchNotes(event.EventId).then(setNotes);
    }
  }, [event]);

  const addNote = useCallback(async (eventId: number, comment: string, noteTypeId?: number) => {
    const eventID = eventId ?? sicId;
    const newComment = {
      eventId: eventID,
      userId: Number(localStorage.getItem('userId')), // TODO: Assign current user Id
      commentText: comment,
    };

      setNoteTypeSelected('0');
      setShowModal(false);
      const resp = await saveNote(eventID, newComment, noteTypeId);

      //Espera de un segundo mientras actualiza en BD
      await new Promise((resolve) => setTimeout(resolve, 1000));

      setNotes(await fetchNotes(eventID));

    return resp;
  }, []);

  const addObservationPermission = (): boolean => {
    return Boolean(userActionsPermissions(ResourceAction.USE_BTN_ADD_OBSERV));
  };

  useEffect(() => {
    const containerElement = containerRef.current;
    if (containerElement) {
      containerElement.scrollTo(0, 0);
    }
  }, [notes]);

  const handleNoteTypeChange = async (changeEvent) => {
    const selectedValue = changeEvent.target.value;
    const eventID = sicId ?? event?.EventId;
    setNoteTypeSelected(selectedValue);
    if (selectedValue == 0) {
      setNotes(await fetchNotes(eventID ?? 0));
    } else {
      const notesFiltered = (
        await getNotesByType(eventID ?? 0, selectedValue)
      ).map((note) => ({
        ...note,
        creationDate: new Date(note.creationDate).toUTCString(),
      }));

      setNotes(notesFiltered);
    }
  };

  return (
    <div ref={containerRef} className={className}>
      <NotesModal
        visible={showModal}
        event={
          {
            eventId: event?.EventId || sicId || 0,
            eventRecord: event?.EventRecord || '',
            notes,
          } as any
        }
        onAddNote={addNote}
        onRefresh={(event) => fetchNotes(event.eventId)}
        onClose={() => setShowModal(false)}
        inspectorName={''}
        noteTypes={noteTypes}
      />
      {addObservationPermission() && (
        <div className='observations_head_menu'>
          <Button
            variant="contained"
            color="primary"
            className={clsx(classes.button, 'add-note')}
            size="large"
            onClick={() => setShowModal(true)}
            startIcon={<AddIcon />}
          >
            Agregar Observación
          </Button>
          <div className='labelClasification'>Tipo de Nota: </div>
          <div>
            <select name="noteTypes" id="noteTypes"
              className='selectClasification'
              value={noteTypeSelected}
              onChange={handleNoteTypeChange}
            >
              <option key={0} value={0}>Todas</option>
              {noteTypes?.map(noteType =>
                <option key={noteType.id} value={noteType.id}>{noteType.name}</option>
              )}
            </select>
          </div>
        </div>
      )}
      <NotesList
        notes={notes}
        noteTypes={noteTypes}
        onRefresh={(eventId: number) => fetchNotes(eventId).then(setNotes)}
      />
    </div>
  );
};

export default Observations;
