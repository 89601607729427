import { Grid, Link, TextField } from '@mui/material';
import { GridColDef, GridRowId } from '@mui/x-data-grid';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import ClaimsTrackerClient from 'shared/clients/ClaimsTrackerClient';
import Button from '../button';
import DataTable from '../grid';
import './index.scss';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import DialogComponent from 'components/common/dialog';
import {
  IInspectionContext,
  InspectionContext,
  uploadMediaFile,
} from 'context/inspections';
import dayjs from 'dayjs';
import {
  BUCKET_NAME_CLAIMS_TRACKER,
  BUCKET_REGION_CLAIMS_TRACKER,
} from 'shared/api/config';
import { formatNumber, formatNumberMoney } from 'shared/utils';
import CATALOGS from 'static/constants/catalogs';
import { ActionType } from 'types/action';
import { ClaimantType } from 'types/claimsTracker';
import { MetadataTag } from 'types/common';
import { PhotoSection } from 'types/images';
import DepreciationCalculation from '../depreciationCalculation';
import FileInput from '../inputFile';

interface Props {
  claimUId?: string;
  claimNumber: string;
  poNumber: string;
  totalIsured: string;
  claimantType: string;
}
export interface Depreciation {
  dateDepreciation: string;
  price: string;
  observations: string;
  fileName: string;
}

const ClaimDepreciation: FunctionComponent<Props> = ({
  claimUId,
  claimNumber,
  poNumber,
  totalIsured,
  claimantType,
}): JSX.Element => {

  useEffect(() => {
    getAllDepreciation().catch(console.error);
  }, []);

  const { dispatch } = useContext<IInspectionContext>(InspectionContext);
  const [fileUpload, setFileUpload] = useState<File>();
  const [dataHistoric, setDataHistoric] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [depreciationState, setDepreciationState] = useState<Depreciation>({
    dateDepreciation: '',
    price: '0',
    observations: '',
    fileName: ''
  });
  const [selectionFields, setSelectionFields] = useState<GridRowId[]>([]);
  const [openDialogConfirm, setOpenDialogConfirm] = useState(false);
  const [txtDate, setTxtDate] = useState<string>(dayjs().format('YYYY-MM-DD'));
  const [txtPrice, setTxtPrice] = useState<string>('');
  const [txtObservations, setTxtObservations] = useState<string>('');
  const [fileName, setFileName] = useState('');
  const [txtTotal, setTxtTotal] = useState('');
  const [txtAverage, setTxtAverage] = useState('');
  const [comparableErrorDisplay, setComparableErrorDisplay] = useState('none');

  const columns: GridColDef[] = [
    { field: 'dateDepreciation', headerName: 'FECHA', width: 100 },
    { field: 'price', headerName: 'VALOR', width: 100 },
    { field: 'observations', headerName: 'OBSERVACIONES', width: 200 },
    {
      field: 'fileUrl',
      headerName: 'DOCUMENTOS',
      width: 150,
      renderCell: (params) => (
        <Link
          className="claim-depreciation__table--link"
          target="_blank"
          href={params.value}
          hidden={!params.value}
        >
          Ver documento
        </Link>
      ),
    },
  ];

  const handleCancel = (): void => {
    setSelectionFields([]);
  };

  const openConfirmDialog = (): void => {
    setOpenDialogConfirm(true);
  };
  const handleUploadFile = (event: any): void => {
    if (event?.target?.files.length > 0) {
      setFileName(event.target.files[0].name);
      const data = depreciationState;
      data.fileName = event.target.files[0].name;
      setDepreciationState(data);
      setFileUpload(event.target.files[0]);
    }
  };
  const handleConfirm = async (): Promise<void> => {
    setOpenDialogConfirm(false);
    await handleDelete();
  };
  const handleDelete = async (): Promise<void> => {
    try {
      dispatch({ type: ActionType.SET_LOADING, payload: true });

      for (const id of selectionFields) {
        await new ClaimsTrackerClient().deleteDepreciation(id as number);
      }

      setSelectionFields([]);
      await getAllDepreciation();

      dispatch({ type: ActionType.SET_LOADING, payload: false });
    } catch (e) {
      dispatch({ type: ActionType.SET_LOADING, payload: false });
    }
  };
  const onPriceChange = (event: any): void => {
    setTxtPrice(event?.target?.value);
    const data = depreciationState;
    data.price = event?.target?.value;
    setDepreciationState(data);
  };
  const onDateChange = (date): void => {
    setTxtDate(date);
    const data = depreciationState;
    data.dateDepreciation = date;
    setDepreciationState(data);
  };
  const onObservationsChange = (event: any): void => {
    setTxtObservations(event?.target?.value);
    const data = depreciationState;
    data.observations = event?.target?.value;
    setDepreciationState(data);
  };
  const getAllDepreciation = async (): Promise<void> => {
    try {
      dispatch({
        type: ActionType.SET_DEPRECIATIONS,
        payload: undefined,
      });
      const result = await new ClaimsTrackerClient().getAllDepreciations(
        claimUId,
      );

      if (result?.depreciations?.length > 0) {
        const data = result.depreciations.slice(-1)[0];
        dispatch({
          type: ActionType.SET_DEPRECIATIONS,
          payload: '1',
        });

        setDepreciationState({ ...data.depreciations });
        setTxtTotal(formatNumberMoney(result.total));
        setTxtAverage(formatNumberMoney(result.average));
      }
      result.depreciations.forEach(x => {
        x.dateDepreciation = x.dateDepreciation.substring(0, 10);
      });
      setDataHistoric(result.depreciations);
    } catch (e) {
      console.error(e);
    }


  };

  const modifiedData = dataHistoric.map((item: any) => ({
    ...item,
    price: `$${item.price}`,
  }));

  const onClickSave = async (): Promise<void> => {
    try {
      if (txtDate) {
        const dayjsDate = dayjs(txtDate);
        if (dayjsDate.isValid()) {
          onDateChange(dayjsDate.format('YYYY-MM-DD'));
        }
      }

      if (
        !depreciationState.dateDepreciation ||
        !depreciationState.observations ||
        !depreciationState.price
      ) {
        setComparableErrorDisplay('block');

        return;
      }

      dispatch({ type: ActionType.SET_LOADING, payload: true });
      setComparableErrorDisplay('none');
      setTxtDate(dayjs().format('YYYY-MM-DD'));
      let fileUrl;

      if (fileUpload) {
        if (fileUpload.size <= 3000000) {
          const upload = await uploadMediaFile(
            fileUpload,
            `${poNumber}/${claimNumber}`,
            claimantType?.toUpperCase() === ClaimantType.CLAIM_INJURED
              ? PhotoSection.INJURED_OTHERS_DOCS
              : PhotoSection.INSURED_OTHERS_DOCS,
            BUCKET_NAME_CLAIMS_TRACKER,
            BUCKET_REGION_CLAIMS_TRACKER,
            `mitchell_${Date.now().toString()}`,
            MetadataTag.CMT_ESTIMATED_MITCHELL_DOCUMENT,
          );

          fileUrl = upload.urlUpload?.split('?')[0];

          const data = depreciationState;
          data.fileName = fileUrl;
          setDepreciationState(data);
          setFileName('');
          setFileUpload(undefined);
        } else {
          setOpenDialog(true);
          dispatch({ type: ActionType.SET_LOADING, payload: false });

          return;
        }
      }
      const result = await new ClaimsTrackerClient().saveDepreciation(
        depreciationState,
        claimUId,
      );
      if (result?.status === 200) {
        setDepreciationState({
          price: '',
          observations: '',
          dateDepreciation: '',
          fileName: ''
        });

        setTxtPrice('');
        setFileName('');
        setTxtObservations('');

        await getAllDepreciation();
        dispatch({ type: ActionType.SET_LOADING, payload: false });
      }
      dispatch({ type: ActionType.SET_LOADING, payload: false });
    } catch (e) {
      console.warn(e);
      dispatch({ type: ActionType.SET_LOADING, payload: false });
    }
  };

  return (
    <>
      <Grid item xs={12} sm={6} md={6} className="claim-depreciation">
        <Grid item xs={12} sm={6} md={6} className="claim-depreciation__content">
          <Grid className="claim-depreciation__data">
            <Grid>
              <Grid className="claim-depreciation__data--title">
                <p>COMPARABLES</p>
              </Grid>
              <Grid>
                <label
                  style={{ color: 'red', display: comparableErrorDisplay }}
                >
                  LLenar datos obligatorios antes de agregar
                </label>
              </Grid>
              <Grid className="claim-depreciation__data--row">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <div style={{ width: '300px' }}>
                    <DesktopDatePicker
                      label="Fecha"
                      format={CATALOGS.getCountryFormat().dateTimeFormat}
                      value={dayjs(txtDate)}
                      slotProps={{
                        textField: {
                          variant: 'outlined',
                          fullWidth: true,
                          margin: 'none',
                          'aria-label': 'change date',
                        },
                      }}
                      onChange={(date: any) => {
                        if (date) {
                          const dayjsDate = dayjs(date);
                          if (dayjsDate.isValid()) {
                            onDateChange(dayjsDate.format('YYYY-MM-DD'));
                          }
                        }
                      }}
                    />
                  </div>
                </LocalizationProvider>
              </Grid>
              <Grid className="claim-depreciation__data--row">
                <TextField
                  name="price"
                  className="claim-depreciation__data--row--input"
                  label="Valor *"
                  onChange={onPriceChange}
                  value={formatNumber(txtPrice)}
                ></TextField>
              </Grid>
              <Grid className="claim-depreciation__data--row">
                <TextField
                  name="totalInsuranceAmount"
                  className="claim-depreciation__data--row--input"
                  label="Observaciones *"
                  onChange={onObservationsChange}
                  value={txtObservations}
                ></TextField>
              </Grid>
              <Grid className="claim-depreciation__data--row">
                <FileInput
                  fileName={fileName}
                  style={{ width: '300px' }}
                  handleFileChange={handleUploadFile}
                />
              </Grid>
              <Grid className="claim-depreciation__data--row">
                <Button
                  text="Agregar comparable"
                  onClick={onClickSave}
                  style={{width:'300px'}}
                />
              </Grid>
            </Grid>
          </Grid>
          {dataHistoric?.length > 0 && (
            <Grid className="claim-depreciation__historic">

              <Grid className="claim-depreciation__table">
                {selectionFields.length > 0 && (
                  <Grid className="claim-depreciation__table--options">
                    <span>{selectionFields.length} items selected</span>
                    <Grid className="claim-depreciation__table--options--actions">
                      <Button
                        text="Eliminar"
                        variant="text"
                        style={{ color: 'white', fontSize: 14 }}
                        onClick={openConfirmDialog}
                      ></Button>
                      <span>|</span>
                      <Button
                        text="Cancel"
                        variant="text"
                        style={{ color: 'white', fontSize: 14 }}
                        onClick={handleCancel}
                      ></Button>
                    </Grid>
                  </Grid>
                )}
                <Grid className="claim-depreciation__table--values">
                  <div className="titleValueTable">VALORES</div>
                  <div className="containerValuesTable">
                    <span className="containerValuesTable__subtitle">
                      Subtotal:
                    </span>
                    <b> ${txtTotal}</b>
                  </div>
                  <div className="containerValuesTable">
                    <span className="containerValuesTable__subtitle">
                      Promedio:
                    </span>
                    <b>${txtAverage}</b>
                  </div>
                </Grid>
                <DataTable
                  columns={columns}
                  data={modifiedData}
                  showCheckBox={true}
                  selectionModel={selectionFields}
                  onRowSelectionModelChange={(rows: any): void =>
                    setSelectionFields(rows)
                  }
                ></DataTable>
              </Grid>
            </Grid>
          )}
        </Grid>


      </Grid>
      <DialogComponent
        messageStyle={{ fontSize: '16px' }}
        message={`¿Desea eliminar los ${selectionFields.length} registros seleccionados?`}
        open={openDialogConfirm}
        handleClose={() => setOpenDialogConfirm(false)}
        handleConfirm={handleConfirm}
      />
      <DialogComponent
        open={openDialog}
        message="El archivo cargado supera el tamaño máximo de 3 MB"
        handleClose={() => setOpenDialog(false)}
      ></DialogComponent>

      <DepreciationCalculation
        claimUId={claimUId}
        totalIsured={totalIsured}
        depreciationValue={txtAverage.replace('Promedio: ', '').replace(',', '')}
      />
    </>
  );
};

export default ClaimDepreciation;
