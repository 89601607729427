import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CardMedia,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import RemoveSelected from 'assets/icons/remove_icon.svg';
import DocumentIcon from 'assets/images/document-icon.svg';
import VideoIcon from 'assets/images/i-360video.svg';
import deleteIcon from 'assets/images/modal_delete_image.svg';
import PdfIcon from 'assets/images/pdf.svg';
import Loader from 'components/common/loader/Loader';
import {
  IInspectionContext,
  InspectionContext,
  getDocumentTypes,
  uploadMediaFile,
} from 'context/inspections';
import { IUserContext, UserContext } from 'context/user';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import APIClient, { endpoints } from 'shared/api';
import {
  BUCKET_NAME_CLAIMS_TRACKER,
  BUCKET_REGION_CLAIMS_TRACKER,
} from 'shared/api/config';
import ClaimTrackerClient from 'shared/clients/ClaimsTrackerClient';
import { ActionType } from 'types/action';
import { IClaimFile, IClaimGallery } from 'types/claimsTracker';
import { DocumentType, MetadataTag } from 'types/common';
import { PhotoSection } from 'types/images';
import './index.scss';
import ProgressBarModal from 'components/common/progressBar/ProgressBarModal';

interface ClaimStepsProps {
  claim: any;
  uId: any;
  disabled?: boolean;
}

const Documents: FunctionComponent<ClaimStepsProps> = ({
  claim,
  uId,
  disabled,
}): JSX.Element => {
  const { state: inspectionState, dispatch } = useContext<IInspectionContext>(
    InspectionContext,
  );
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [urlDelete, setUrlDelete] = useState<string>('');
  const [documents, setDocuments] = useState<(IClaimFile | undefined)[]>([]);
  const [allDocuments, setAllDocuments] = useState<(IClaimFile | undefined)[]>(
    [],
  );
  const [docUploadShow, setDocUploadShow] = React.useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [selectedFiles, setSelectedFiles] = useState<File[] | null>();
  const [errorVisibility, setErrorVisibility] = useState(false);
  const [loadData, setLoadData] = useState(true);
  const [progressbarIsOpen, setProgressbarIsOpen] = useState(false);
  const [progressbarPercentage, setProgressbarPercentage] = useState(0);
  const [documentTypeSelected, setDocumentTypeSelected] = React.useState('0');
  const [
    documentTypeFilterSelected,
    setDocumentTypeFilterSelected,
  ] = React.useState('0');
  const [documentTypes, setDocumentTypes] = React.useState<DocumentType[]>([]);
  const {
    state: { currentUser },
  } = useContext(UserContext) as IUserContext;

  useEffect(() => {
    loadFiles().catch(console.error);
  }, []);

  useEffect(() => {
    loadFiles().catch(console.error);
  }, [claim.info?.case]);

  async function loadDocumentTypes() {
    try {
      const documentTypesToLoad = await getDocumentTypes();
      setDocumentTypes(documentTypesToLoad);
    } catch (e) {
      dispatch({ type: ActionType.SET_LOADING, payload: false });
    }
  }

  const checkIsVideo = (fileUrl: string): boolean => {
    return Boolean(
      fileUrl.indexOf('.mp4') > -1 ||
      fileUrl.indexOf('.avi') > -1 ||
      fileUrl.indexOf('.mov') > -1 ||
      fileUrl.indexOf('.qt') > -1 ||
      fileUrl.indexOf('.quicktime') > -1 ||
      fileUrl.indexOf('.webm') > -1,
    );
  };

  const checkIsPdf = (fileUrl: string): boolean => {
    return Boolean(
      fileUrl.indexOf('.pdf') > -1 || fileUrl.indexOf('.PDF') > -1,
    );
  };

  const checkIsImage = (fileUrl: string): boolean => {
    return Boolean(
      fileUrl.indexOf('.png') > -1 ||
      fileUrl.indexOf('.jpg') > -1 ||
      fileUrl.indexOf('.jpeg') > -1 ||
      fileUrl.indexOf('.PNG') > -1 ||
      fileUrl.indexOf('.JPG') > -1 ||
      fileUrl.indexOf('.JPEG') > -1,
    );
  };

  const loadFiles = async (): Promise<void> => {
    setLoadData(true);

    try {      
      const sections = await new ClaimTrackerClient().getFiles(uId);

      const sectionClaim = sections.filter(
        (file: IClaimGallery) => file.sectionName === 'CT_OTHERS',
      );

      if (sectionClaim.length > 0) {
        const filesClaim = sectionClaim[0].files.map((file: IClaimFile) => {
          if (!file.expenseDocument) {
            if (checkIsPdf(file.url)) {
              file.type = 'pdf';
            } else if (checkIsVideo(file.url)) {
              file.type = 'video';
            } else if (checkIsImage(file.url)) {
              file.type = 'image';
            } else {
              file.type = 'document';
            }

            return file;
          }
        });
        setDocuments(filesClaim);
        setAllDocuments(filesClaim);
        setDocumentTypeFilterSelected('0');
        loadDocumentTypes();
        setLoadData(false);        
      }
    } catch (e) {
      setDocuments([]);
      setDocumentTypeFilterSelected('0');
      setLoadData(false);
    }
  };

  const handleFileChangeUD = async () => {
    if (documentTypeSelected == '0') {
      setErrorVisibility(true);

      return;
    }

    if (selectedFiles && selectedFiles.length > 0) {
      try {
        setDocUploadShow(false);

        let progress = 0;
        const PercentageByTask = Math.ceil(100 / selectedFiles.length);
        setProgressbarIsOpen(true);

        for (let i = 0; i < selectedFiles.length; i++) {
          progress = progress + PercentageByTask;

          const selectedFile = selectedFiles[i];
          dispatch({ type: ActionType.SET_LOADING, payload: true });

          await uploadMediaFile(
            selectedFile,
            `${claim?.info?.poNumber}/${claim?.info?.case}`,
            PhotoSection.CMT_DOCUMENTS,
            BUCKET_NAME_CLAIMS_TRACKER,
            BUCKET_REGION_CLAIMS_TRACKER,
            undefined,
            MetadataTag.CMT_DOCUMENTS,
            documentTypeSelected,
            currentUser.userName,
            currentUser.rolName,
          );

          await loadFiles();
          setProgressbarPercentage(progress);
        }

        dispatch({ type: ActionType.SET_LOADING, payload: false });

        setProgressbarPercentage(0);
        setProgressbarIsOpen(false);
      } catch (e) {
        dispatch({ type: ActionType.SET_LOADING, payload: false });
      }
    }
  };

  const handleOpenFile = (url: string): void => {
    window.open(url, '_blank');
  };

  const handleDeleteFile = (document: IClaimFile | undefined): void => {
    if (document?.url) {
      if (!disabled || document.cmtDocumentRole === 'Aseguradora') {
        setUrlDelete(document.url);
        setShowConfirmModal(true);
      }
    }
  };

  const confirmDeleteFile = async (): Promise<void> => {
    await deleteFile(
      urlDelete,
      claim?.info?.poNumber,
      claim?.info?.countryCode,
    );
  };

  const deleteFile = async (
    imageUrl: string,
    poNumber: string,
    countryCode,
  ): Promise<void> => {
    try {
      setShowConfirmModal(false);
      dispatch({ type: ActionType.SET_LOADING, payload: true });
      const url = imageUrl;

      const data = {
        countryCode: countryCode,
        imageUrl: url.toString(),
      };

      const response = await APIClient.delete(
        endpoints.DELETE_IMAGE.replace('{eventRecord}', poNumber),
        { data: data },
      );

      if (response?.data?.success) {
        setUrlDelete('');
        await loadFiles();
      }

      dispatch({ type: ActionType.SET_LOADING, payload: false });
    } catch (e) {
      dispatch({ type: ActionType.SET_LOADING, payload: false });
    }
  };

  const handleFileChangeJn = (event) => {
    const files = event.target.files;

    if (files && files.length > 0) {
      setSelectedFiles(Array.from(files));

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file) {
          const imageUrl = URL.createObjectURL(file);
          setSelectedImage(imageUrl);
        }
      }
    }
  };

  const handleDocTypeChange = (event: SelectChangeEvent) => {
    setDocumentTypeSelected(event.target.value as string);

    if (event.target.value !== '0') {
      setErrorVisibility(false);
    }
  };

  const getImageDocumentType = (idDocType?: string): DocumentType => {
    const DefaultDocumentType: DocumentType = {
      id: 0,
      color: '#717171',
      name: 'Indefinido',
    };

    if (idDocType) {
      const docTypeExist = documentTypes.find(
        (docType) => docType.id === parseInt(idDocType, 10),
      );

      if (docTypeExist) {
        return docTypeExist;
      }
    }

    return DefaultDocumentType;
  };

  function OpenModalDocumentUpload() {
    setSelectedImage('');
    setSelectedFiles(null);
    setDocumentTypeSelected('0');
    setDocUploadShow(true);
  }

  const handleDocTypeFilterChange = async (event) => {
    const selectedValue = event.target.value;
    setDocumentTypeFilterSelected(selectedValue);
    if (selectedValue == 0) {
      setDocuments(allDocuments);
    }
    else {
      if (documents) {
        const filteredDocuments = allDocuments.filter((item): item is IClaimFile => !!item) // Filtra los elementos undefined
          .filter((item) => item.cmtDocumentType === selectedValue);
        setDocuments(filteredDocuments);
      }
      else {
        setDocuments(allDocuments);
      }
    }
  };

  return (
    <>
      {loadData ? (
        <Stack spacing={1}>
          <Skeleton variant="rounded" style={{ width: '100%' }} height={60} />
          <Skeleton variant="rounded" style={{ width: '100%' }} height={60} />
          <Skeleton variant="rounded" style={{ width: '100%' }} height={60} />
        </Stack>
      ) : (
        <Box className="documents-section-container">
          <div className='documents_head_menu'>
            <div className='documents_left_head'>
              <div>
                <div>
                  <label>Documentos</label>
                </div>
                <div className="caption">Caso reclamante: {claim.info?.case}</div>
              </div>

              <div className='documents_left_head_selectfilter'>
                <div className='labelClasification'>Tipo de Documento: </div>
                <div>
                  <select name="noteTypes" id="noteTypes"
                    className='selectClasification'
                    value={documentTypeFilterSelected}
                    onChange={handleDocTypeFilterChange}
                  >
                    <option key={0} value={0}>Todas</option>
                    {documentTypes?.map(documentType =>
                      <option key={documentType.id} value={documentType.id}>{documentType.name}</option>
                    )}
                  </select>
                </div>
              </div>
            </div>

            <Button
              variant="contained"
              size="small"
              className="btnAdd"
              onClick={OpenModalDocumentUpload}
            >
              Agregar Documentos
            </Button>
          </div>
          <Grid container spacing={2} className="PhotosContent">
            {documents?.length > 0 ? (
              documents.map(
                (document: IClaimFile | undefined, index: number) =>
                  document && (
                    <Grid
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                      item
                      key={index}
                      xs={12}
                      sm={2}
                      md={2}
                    >
                      <div
                        className="gallerySelector renderImage"
                        onDoubleClick={() => {
                          handleOpenFile(document?.url);
                        }}
                      >
                        <CardMedia
                          title={document?.name}
                          component="img"
                          image={
                            document?.type === 'pdf'
                              ? PdfIcon
                              : document?.type === 'video'
                                ? VideoIcon
                                : document?.type === 'image'
                                  ? document?.url
                                  : DocumentIcon
                          }
                          alt={document?.name}
                        />
                        <Grid
                          container
                          justifyContent="flex-end"
                          className="removeItem"
                        >
                          <img
                            src={RemoveSelected}
                            onClick={() => {
                              handleDeleteFile(document ?? undefined);
                            }}
                            onKeyDown={() => {
                              handleDeleteFile(document ?? undefined);
                            }}
                          ></img>
                        </Grid>

                        {document.cmtDocumentRole === 'Aseguradora' ? (
                          <Grid
                            container
                            justifyContent="center"
                            className="insuranceItem"
                          >
                            A
                          </Grid>
                        ) : null}
                      </div>
                      <div
                        className="docTypeText"
                        style={{
                          color: getImageDocumentType(document.cmtDocumentType)
                            .color,
                        }}
                      >
                        {getImageDocumentType(document.cmtDocumentType).name}
                      </div>
                      <Typography
                        variant="subtitle1"
                        className="text-description tdstyle"
                      >
                        {document?.name?.replaceAll('_', ' ')}
                      </Typography>
                    </Grid>
                  ),
              )
            ) : (
              <Stack spacing={2}>
                <Alert severity="warning">
                  <AlertTitle>
                    <strong>Pendiente</strong>
                  </AlertTitle>
                  Reclamación aún no detecta documentos para visualizar.
                </Alert>
              </Stack>
            )}
          </Grid>

          <Modal
            className="confirm-modal"
            open={showConfirmModal}
            onClose={() => setShowConfirmModal(false)}
          >
            <div className="container">
              <img src={deleteIcon} alt="" />
              <h3>¿Desea eliminar la imagen?</h3>

              <div className="buttons-container">
                <Button variant="contained" onClick={confirmDeleteFile}>
                  Eliminar
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setShowConfirmModal(false);
                  }}
                >
                  Cancelar
                </Button>
              </div>
            </div>
          </Modal>

          <Modal
            open={docUploadShow}
            onClose={() => setDocUploadShow(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={{ p: 5 }} className="claim-doc-upload">
              <Grid container spacing={2} className="container">
                <Grid xs={12} md={7}>
                  <span className="titleUploadDoc">AGREGAR DOCUMENTOS</span>
                </Grid>
                <Grid xs={12} md={5}>
                  <Button
                    variant="contained"
                    size="large"
                    onClick={() => setDocUploadShow(false)}
                    className="btnCancelarUD"
                  >
                    CANCELAR
                  </Button>
                  <Button
                    variant="contained"
                    size="large"
                    onClick={handleFileChangeUD}
                  >
                    AGREGAR
                  </Button>
                </Grid>

                <Grid xs={12} style={{ height: '20px' }}></Grid>
                <Grid xs={12} md={4} lg={3} className="fileUpControl">
                  <div>
                    <div
                      id="imageLoaded"
                      className="gallerySelector renderImage imageLoaded"
                    >
                      {selectedImage && (
                        <img src={selectedImage} alt="Selected" />
                      )}
                    </div>
                    <label htmlFor="file-input-du">
                      <div className="btnCargarDoc">Cargar Documentos</div>
                    </label>
                    <input
                      id="file-input-du"
                      type="file"
                      accept="image/*"
                      style={{ display: 'none' }}
                      onChange={handleFileChangeJn}
                      multiple
                    />
                  </div>
                </Grid>
                <Grid xs={12} md={5} lg={5}>
                  <div>
                    <Box sx={{ minWidth: 250 }}>
                      <FormControl fullWidth className="selectDocType">
                        <InputLabel id="demo-simple-select-label">
                          Tipo de documento *
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={documentTypeSelected}
                          label="Tipo de documento *"
                          onChange={handleDocTypeChange}
                        >
                          <MenuItem key={0} value={0}>
                            Tipo de documento...
                          </MenuItem>
                          {documentTypes?.map((documentType) => (
                            <MenuItem
                              key={documentType.id}
                              value={documentType.id}
                            >
                              {documentType.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <br></br>
                      {errorVisibility ? (
                        <span style={{ color: 'red' }}>
                          Por favor seleccionar un tipo de documento
                        </span>
                      ) : null}
                    </Box>
                  </div>
                </Grid>
                <Grid xs={0} md={3} lg={4}></Grid>
              </Grid>
            </Box>
          </Modal>


        </Box>
      )}

      <Modal
        className="confirm-modal"
        open={progressbarIsOpen}
        onClose={() => setProgressbarIsOpen(false)}
      >
        <ProgressBarModal progress={progressbarPercentage} />
      </Modal>
    </>
  );
};

export default Documents;
