import { CountryCode } from 'types/common';

export interface IinputsEstimated {
  icon: string;
  name: string;
  className: string;
  label: string;
  value: string;
  readonly: boolean;
  type: string;
  required: boolean;
}

export interface IRowsEstimated {
  gridClassName: string;
  inputsFields: IinputsEstimated[];
}

export interface IColumnsEstimated {
  rows: IRowsEstimated[];
}

type TypeInputs = { [key in CountryCode]: IColumnsEstimated[] };

export const InputsEstimated: TypeInputs = {
  [CountryCode.PRI]: [
    {
      rows: [
        {
          gridClassName: 'data--row',
          inputsFields: [
            {
              icon: '+',
              name: 'partsCost',
              className: 'data--row--input',
              label: 'Costo Piezas Nuevas *',
              value: 'partsCost',
              readonly: false,
              type: 'text',
              required: true,
            },
          ],
        },
        {
          gridClassName: 'data--desc',
          inputsFields: [
            {
              icon: '-',
              name: 'porcDesc',
              className: 'data--desc--porc',
              label: '% Desc *',
              value: 'porcDesc',
              readonly: false,
              type: 'text',
              required: true,
            },
            {
              icon: '',
              name: 'discountAmount',
              className: 'data--desc--amount',
              label: 'Descuento',
              value: 'discountAmount',
              readonly: true,
              type: 'text',
              required: false,
            },
          ],
        },
        {
          gridClassName: 'data--row',
          inputsFields: [
            {
              icon: '=',
              name: 'subtotalParts',
              className: 'data--row--input',
              label: 'Subtotal (Costo piezas - descuento)',
              value: 'subtotalParts',
              readonly: true,
              type: 'text',
              required: false,
            },
          ],
        },
        {
          gridClassName: 'data--row',
          inputsFields: [
            {
              icon: '+',
              name: 'partsCostWithoutDiscount',
              className: 'data--row--input',
              label: 'Costo piezas sin descuento *',
              value: 'partsCostWithoutDiscount',
              readonly: false,
              type: 'text',
              required: true,
            },
          ],
        },
        {
          gridClassName: 'data--row',
          inputsFields: [
            {
              icon: '=',
              name: 'partsCostTotal',
              className: 'data--row--input',
              label: 'Costo total de piezas',
              value: 'partsCostTotal',
              readonly: true,
              type: 'text',
              required: false,
            },
          ],
        },
        {
          gridClassName: 'data--row',
          inputsFields: [
            {
              icon: '',
              name: '',
              className: '',
              label: '',
              value: '',
              readonly: false,
              type: 'file',
              required: false,
            },
          ],
        },
      ],
    },
    {
      rows: [
        {
          gridClassName: 'data--row',
          inputsFields: [
            {
              icon: '+',
              name: 'expenseAmount',
              className: 'data--row--input',
              label: 'Gastos',
              value: 'expenseAmount',
              readonly: false,
              type: 'text',
              required: false,
            },
          ],
        },
        {
          gridClassName: 'data--row',
          inputsFields: [
            {
              icon: '+',
              name: 'materialsCost',
              className: 'data--row--input',
              label: 'Costo de Materiales *',
              value: 'materialsCost',
              readonly: false,
              type: 'text',
              required: true,
            },
          ],
        },
        {
          gridClassName: 'data--row',
          inputsFields: [
            {
              icon: '+',
              name: 'laborCost',
              className: 'data--row--input',
              label: 'Costo de Labor *',
              value: 'laborCost',
              readonly: false,
              type: 'text',
              required: true,
            },
          ],
        },
        {
          gridClassName: 'data--row',
          inputsFields: [
            {
              icon: '+',
              name: 'ivuLaborAmount',
              className: 'data--row--input',
              label: 'IVU Labor *',
              value: 'ivuLaborAmount',
              readonly: false,
              type: 'text',
              required: true,
            },
          ],
        },
        {
          gridClassName: 'data--row',
          inputsFields: [
            {
              icon: '+',
              name: 'ivuPartsAmount',
              className: 'data--row--input',
              label: 'IVU Piezas *',
              value: 'ivuPartsAmount',
              readonly: false,
              type: 'text',
              required: true,
            },
          ],
        },
        {
          gridClassName: 'data--row',
          inputsFields: [
            {
              icon: '+',
              name: 'estimatedTotalAmount',
              className: 'data--row--input',
              label: 'Total Estimado',
              value: 'estimatedTotalAmount',
              readonly: true,
              type: 'text',
              required: false,
            },
          ],
        },
      ],
    },
  ],
  [CountryCode.COL]: [
    {
      rows: [
        {
          gridClassName: 'data--row',
          inputsFields: [
            {
              icon: '+',
              name: 'laborCost',
              className: 'data--row--input',
              label: 'Mano de obra *',
              value: 'laborCost',
              readonly: false,
              type: 'text',
              required: true,
            },
          ],
        },
        {
          gridClassName: 'data--row',
          inputsFields: [
            {
              icon: '+',
              name: 'partsCost',
              className: 'data--row--input',
              label: 'Repuestos',
              value: 'partsCost',
              readonly: false,
              type: 'text',
              required: false,
            },
          ],
        },
        {
          gridClassName: 'data--row',
          inputsFields: [
            {
              icon: '+',
              name: 'expenseAmount',
              className: 'data--row--input',
              label: 'Otros',
              value: 'expenseAmount',
              readonly: false,
              type: 'text',
              required: false,
            },
          ],
        },
        {
          gridClassName: 'data--row',
          inputsFields: [
            {
              icon: '+',
              name: 'porcIVA',
              className: 'data--row--input',
              label: '% IVA *',
              value: 'porcIVA',
              readonly: false,
              type: 'text',
              required: true,
            },
          ],
        },
        {
          gridClassName: 'data--row',
          inputsFields: [
            {
              icon: '+',
              name: 'ivaAmount',
              className: 'data--row--input',
              label: 'Valor IVA *',
              value: 'ivaAmount',
              readonly: false,
              type: 'text',
              required: true,
            },
          ],
        },
        {
          gridClassName: 'data--row',
          inputsFields: [
            {
              icon: '+',
              name: 'estimatedTotalAmount',
              className: 'data--row--input',
              label: 'Toltal',
              value: 'estimatedTotalAmount',
              readonly: false,
              type: 'text',
              required: false,
            },
          ],
        },
        {
          gridClassName: 'data--row',
          inputsFields: [
            {
              icon: '',
              name: '',
              className: '',
              label: '',
              value: '',
              readonly: false,
              type: 'file',
              required: false,
            },
          ],
        },
      ],
    },
  ],
  [CountryCode.CRC]: [],
  [CountryCode.PAN]: [],
};
