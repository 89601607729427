import { Box, Stack } from '@mui/material';
import Button from './../../components/claims/button'
import Loader from 'components/common/loader/Loader';
import CreateDoc from 'components/componentDocuments/create'
import React, { useState } from 'react';

export default function CreateDocument(): JSX.Element {
  const [loader, setLoader] = useState(false);
  const [optionAction, setOptionAction] = useState(1);
  const [value, setValue] = useState(0);

  const handleChange = (id) => {
    setOptionAction(id);
  };

  return (
    <>
      <Loader isActive={loader} />
      <CreateDoc setLoader={setLoader} />
    </>
  );
}
