import React, { useEffect, useState } from 'react';
import { FunctionComponent } from 'react';
import { Field } from 'formik';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { formatNumber, maskValue } from 'shared/utils';
import { IClaimForm } from 'types/claimsTracker';
import CATALOGS from 'static/constants/catalogs';
import Button from '../button';
import './index.scss';
import ClaimsTrackerClient from 'shared/clients/ClaimsTrackerClient';
import Depreciation from '../depreciation';
import { useTotalLossAdjust } from 'context/adjust/totalLoss-adjust-context';
import { TotalLossAdjustDTO } from 'context/adjust/adjust';

interface Props {
  claimUId: string;
  claimForm?: IClaimForm;
  disabled: boolean;
  visibleDepreciation: any;
}

const TotalLoss: FunctionComponent<Props> = ({
  claimUId,
  claimForm,
  disabled,
  visibleDepreciation
}): JSX.Element => {
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const [confirmApplyTotalLoss, setConfirmApplyTotalLoss] = useState<boolean>(
    false,
  );
  const [totalLossIsApply, setTotalLossIsApply] = useState<boolean>(false);
  const [totalLossRecoveryAmount, setTotalLossRecoveryAmount] = useState<
    number
  >(0);
  const [updatedReserveAmount, setUpdatedReserveAmount] = useState<number>(0);
  const [totalLossTxtDate, setTotalLossTxtDate] = useState<string>('');
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const {tladjust, settingTladjust} = useTotalLossAdjust();

  const handleRadioChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    setTotalLossIsApply(value === 'true');
    visibleDepreciation(value === 'true');
    if (firstRender) {
      setFirstRender(false);
    }    
  };

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const numberValue = Number(value.replace(/[^0-9.-]+/g, ''));
    switch (event.target.name) {
      case 'recovery-amount':
        setTotalLossRecoveryAmount(numberValue);
        break;
      case 'reserve-amount':
        setUpdatedReserveAmount(numberValue);
        break;
    }
  };

  const submitTotalLoss = async (confirmSave = false): Promise<void> => {
    const recoveryAmount = totalLossIsApply ? totalLossRecoveryAmount : null;
    const totalLossDate =
      confirmSave && dayjs(totalLossTxtDate).isValid()
        ? totalLossTxtDate
        : null;
    setConfirmApplyTotalLoss(confirmSave && dayjs(totalLossTxtDate).isValid());

    await new ClaimsTrackerClient().applyTotalLoss(
      claimUId,
      totalLossIsApply,
      recoveryAmount,
      updatedReserveAmount,
      totalLossDate,
      Number(localStorage.getItem('userId')),
    );
  };

  const confirmTotalLoss = (confirmSave = false): void => {
    (async (): Promise<void> => {
      await submitTotalLoss(confirmSave);
      setOpenConfirm(false);
      
      const actualCost = tladjust?.ActualCost;

      const newTotalLossAdjust : TotalLossAdjustDTO = {       
        ActualCost : actualCost,
        IsTotalLoss : totalLossIsApply
      }

      settingTladjust(newTotalLossAdjust);
    })();
  };

  const handleUpdateReserveAmount = async (): Promise<void> => {
    await new ClaimsTrackerClient().applyTotalLoss(
      claimUId,
      totalLossIsApply,
      null,
      updatedReserveAmount,
      null,
      Number(localStorage.getItem('userId')),
    );
  };

  const loadData = () => {
    if (claimForm?.info?.totalLoss?.isApply) {
      setTotalLossIsApply(claimForm?.info?.totalLoss?.isApply);
      visibleDepreciation(claimForm?.info?.totalLoss?.isApply);
    }

    if (claimForm?.info?.totalLoss?.totalLossDate) {
      setTotalLossTxtDate(claimForm?.info?.totalLoss?.totalLossDate);
      setConfirmApplyTotalLoss(true);
    }

    if (claimForm?.info?.totalLoss?.recoveryAmount) {
      setTotalLossRecoveryAmount(claimForm?.info?.totalLoss?.recoveryAmount);
    }

    if (claimForm?.info?.totalLoss?.updatedReserveAmount) {
      setUpdatedReserveAmount(claimForm?.info?.totalLoss?.updatedReserveAmount);
    }
  };
  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    loadData();
  }, [claimForm?.info?.case]);

  useEffect(() => {
    if (!firstRender) {
      confirmTotalLoss();
    }
  }, [totalLossIsApply]);

  return (
    <>
      <Box className="total-loss">
        {confirmApplyTotalLoss && dayjs(totalLossTxtDate).isValid() ? (
          <Box>
            <p className="total-loss__data--title-result">
              Valor de la pérdida:{' '}
              {maskValue(totalLossRecoveryAmount, '###,###.00', '#', false)}
            </p>
            <p className="total-loss__data--subtitle-result">
              Fecha de Pago: {dayjs(totalLossTxtDate).format('YYYY-MM-DD')}
            </p>
          </Box>
        ) : (
          <>
            <Box className="total-loss__data">
              <Box>
                <label>Aplicar perdida total</label>
                <br />
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="apply-total-loss"
                    name="position"
                    defaultValue={claimForm?.info?.totalLoss?.isApply ?? false}
                    onChange={handleRadioChange}
                  >
                    <FormControlLabel
                      disabled={disabled}
                      value={true}
                      control={<Radio />}
                      label="Sí"
                      labelPlacement="start"
                    />
                    <FormControlLabel
                      disabled={disabled}
                      value={false}
                      control={<Radio />}
                      label="No"
                      labelPlacement="start"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
            {totalLossIsApply && (
              <>
                <Box className="total-loss__data">
                  <Box className="total-loss__amount">
                    <br />
                    <Field
                      key="reserve-amount"
                      as={TextField}
                      type="text"
                      label="Reserva Actualizada"
                      name="reserve-amount"
                      value={formatNumber(updatedReserveAmount.toString())}
                      onChange={handleAmountChange}
                      onBlur={handleUpdateReserveAmount}
                    />
                  </Box>
                  <Box className="total-loss__amount">
                    <br />
                    <Field
                      key="recovery-amount"
                      as={TextField}
                      type="text"
                      label="Valor de recuperación"
                      name="recovery-amount"
                      value={formatNumber(totalLossRecoveryAmount.toString())}
                      onChange={handleAmountChange}
                    />
                  </Box>
                  <Box>
                    <br />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="Fecha"
                        format={CATALOGS.getCountryFormat().dateTimeFormat}
                        value={dayjs(totalLossTxtDate)}
                        slotProps={{
                          textField: {
                            variant: 'outlined',
                            margin: 'none',
                            'aria-label': 'change date',
                          },
                        }}
                        onChange={(date: any) => {
                          if (date) {
                            const dayjsDate = dayjs(date);
                            if (dayjsDate.isValid()) {
                              setTotalLossTxtDate(dayjsDate.format('YYYY-MM-DD'));
                            }
                          }
                        }}
                      />
                    </LocalizationProvider>
                  </Box>
                  <Box>
                    <br />
                    <Button
                      text="Aplicar Perdida Total"
                      type="button"
                      onClick={() => setOpenConfirm(true)}
                    />
                  </Box>
                </Box>
                <Depreciation
                  claimUId={claimUId}
                  claimNumber={claimForm?.info?.case}
                  poNumber={claimForm?.info?.poNumber}
                  totalIsured={claimForm?.info?.policy.totalInsured}
                  claimantType={claimForm?.info?.type}
                />
              </>
            )}
          </>
        )}
      </Box>

      <Dialog open={openConfirm}>
        <DialogTitle id="confirm-restore-title">Confirmar</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="confirm-payment-modal"
            style={{ width: '400px', height: '120px' }}
          >
            <br />
            ¿Está seguro que desea aplicar los cambios de perdida total?
            <br />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            text="Cancelar"
            variant="outlined"
            color="secondary"
            onClick={() => setOpenConfirm(false)}
          />
          <Button
            text="Sí, confirmar"
            variant="contained"
            color="primary"
            onClick={confirmTotalLoss}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TotalLoss;
