import {
  Button,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import ButtonClaims from './../../claims/button'
import React, { FunctionComponent, useEffect, useState, useRef } from 'react';
import DocumentTemplatesClient from 'shared/clients/DocumentTemplatesClient';

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));
interface ChipData {
  label: string;
}

const CreateDocument: FunctionComponent<any> = ({setLoader}): JSX.Element => {
  const effectRan = useRef(false);
  const [documents, setDocuments] = useState<any>([]);
  const [emails, setEmails] = useState<ChipData[]>([]);
  const [email, setEmail] = useState('');
  const [copyDoc, setCopyDoc] = useState('');
  const [name, setName] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [disabledMail, setDisabledMail] = useState(true);

  useEffect(() => {
    if (effectRan.current === false) {
      const getDataInitial = async () => {
        setLoader(true);
        const docs = await new DocumentTemplatesClient().getAllTemplates();
        setDocuments(docs);
        setLoader(false);
      };

      getDataInitial();
      effectRan.current = true;
    }
  }, []);
  
  useEffect(() => {
    setDisabled(!(emails.length>0 && name));
  },[emails,name]);

  useEffect(() => {
    setDisabledMail(!(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email) || /^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+\.[A-Za-z]+$/.test(email)));
  },[email]);
  

  const handleAddEmail = () => {
    const data = email;
    const emailsList = emails;
    if (emailsList.indexOf({ label: data }) === -1) {
      setEmails((prevEmails) => [...prevEmails, ...[{ label: data }]]);
      setEmail('');
    }
  };

  const handleDelete = (chipToDelete) => () => {
    setEmails((chips) => chips.filter((chip) => chip.label !== chipToDelete));
  };

  const changeEmail = (event) => {
    setEmail(event?.target.value);
  };
  const changeDoc = (event) => {
    setCopyDoc(event?.target.value);
  };
  const onReturn =()=>{
    window.location.replace('/documentTemplates');
  }
  const onChangeName =(event:any) =>{
    setName(event?.target.value)
  }

  const onCreateTemplate = async()=>{
    const mails = emails.map(({ label }) => label)
    const data ={
      id: null,
      name: name,
      documentTypeId: 0,
      permissionEmails: mails,
      copyId: copyDoc? copyDoc === '0' ? null: copyDoc :null
    }
    
    const result = await new DocumentTemplatesClient().createTemplate(data)
    window.location.replace('/editTemplate/'+result);
  }

  return (
    <>
    
    <Button onClick={onReturn}>Volver</Button>
      <Typography className={'gridCard-claim__title'}>Crear Plantilla</Typography>
      <TextField value={name} onChange={onChangeName} label="Nombre del documento" />
      <FormControl sx={{ m: 1, minWidth: 280 }}>
        <InputLabel id="demo-simple-select-label">
          Crear basado en un documento
        </InputLabel>
        <Select
          label="Crear basado en un documento"
          value={copyDoc}
          autoWidth
          onChange={changeDoc}
        >
          <MenuItem value='0'>N/A</MenuItem>
          {documents.map((item, index) => {
            return(
            <MenuItem key={index} value={item.id}>
              {item.name}
            </MenuItem>
            )
          })}
        </Select>
      </FormControl>
      <TextField label="Nuevo correo" value={email} onChange={changeEmail} />
      <ButtonClaims onClick={handleAddEmail} disabled={disabledMail} text="Agregar correo" />
      
      <Typography>Listado de correos con acceso</Typography>
      {emails ? (
        <Paper
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
            listStyle: 'none',
            p: 0.5,
            m: 0,
          }}
          component="ul"
        >
          {emails.map((data, index) => {
            return (
              <ListItem key={index}>
                <Chip label={data.label} onDelete={handleDelete(data.label)} />
              </ListItem>
            );
          })}
        </Paper>
      ) : null}
      <br/>
      <ButtonClaims disabled={disabled} onClick={onCreateTemplate} text="Crear plantilla" />
    </>
  );
};

export default CreateDocument;
