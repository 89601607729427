import React, { useContext, useState } from 'react';
import { Field, useFormikContext } from 'formik';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
  IconButton,
  Button,
  Chip,
} from '@mui/material';
import dayjs from 'dayjs';
import clsx from 'clsx';
import INSPECTORS from 'static/constants/inspectors';
import CATALOGS, { availableCoverages } from 'static/constants/catalogs';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import DoneIcon from '@mui/icons-material/Done';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Signature from 'components/common/signature';
import AudioField from 'components/inspection/audio/audioRecorder';
import Sketch from 'components/inspection/section/Sketch';
import { FieldTypes, IEventData } from 'types/claimsTracker';
import { CheckBoxTypes, CountryCode, DropDownTypes, Rol } from 'types/common';
import './index.scss';
import Reinspection from 'components/claims/reinspection/reinspection';
import {
  getDamages,
  getDamagesString,
} from 'components/inspection/section/utils';
import { VehicleDamages } from '@connect-technology/connect-react-components-lib';
import CheckboxList from 'components/common/checkbox-list/CheckboxList';
import { ICoverage } from 'types/inspection';
import { sinisterClaims } from 'context/inspections/actions/actions';
import { IInspectionContext, InspectionContext } from 'context/inspections';

interface ClaimFormProps {
  section: any;
  errors: any;
  touched: any;
  claim: any;
  event?: IEventData;
  disabled?: boolean;
  setErrors?: any;
}

const FormFieldsComponent: React.FC<ClaimFormProps> = ({
  section,
  errors,
  touched,
  claim,
  event,
  disabled,
  setErrors,
}) => {
  const { state, dispatch } = useContext<IInspectionContext>(InspectionContext);
  const [fieldValues, setFieldValues] = useState<any>(null);
  const [fieldValuesRadio, setFieldValuesRadio] = useState({});
  const formik = useFormikContext<any>();
  const noInspector = Number(localStorage.getItem('rolId')) !== Rol.INSPECTOR;

  const handleChange = (fieldName: string, value: any, field: any) => {
    setFieldValues((prevValues) => ({
      ...prevValues,
      [fieldName]: value,
    }));
    formik.setFieldValue(fieldName, value);
  };

  const handleChangeDynamic = (fieldName: string, value: any) => {
    setFieldValues((prevValues) => ({
      ...prevValues,
      [fieldName]: value,
    }));

    formik.setFieldValue(fieldName, value);
  };

  const handleChangeValue = (fieldName: string, value: any) => {
    setFieldValuesRadio((prevValues) => ({
      ...prevValues,
      [fieldName]: value,
    }));
    formik.setFieldValue(fieldName, value);
  };

  const handleInputChange = (signature: any, key: string) => {
    formik.setFieldValue(key, signature);
  };

  const timeFormat = (defaultValue: any) => {
    const [hour, mins] = defaultValue
      ?.replace(/AM|PM|\s/gi, '')
      ?.split(':') || ['00', '00'];
    const value = hour && mins ? new Date().setHours(hour, mins) : null;

    return value;
  };

  const onCustomChange = (event: any) => {
    const fecha = new Date(event);
    const horas = fecha.getHours();
    const minutos = fecha.getMinutes();

    return horas + ':' + minutos;
  };

  const changeDynamicValues = (fieldName: string, selectedValue: string) => {
    if (fieldName === 'IndInspeccionLugarAccidente') {
      if (selectedValue === '1') {
        formik.setFieldValue(
          'EventProvinceId',
          formik.values['AccidentProvinceId'],
        );
        formik.setFieldValue(
          'EventCantonId',
          formik.values['AccidentCantonId'],
        );
        formik.setFieldValue(
          'EventDistrictId',
          formik.values['AccidentDistrictId'],
        );
      } else if (selectedValue === '2') {
        formik.setFieldValue('EventProvinceId', '-1');
        formik.setFieldValue('EventCantonId', '-1');
        formik.setFieldValue('EventDistrictId', '-1');
        formik.setFieldValue('EventProvinceId', '');
        formik.setFieldValue('EventCantonId', '');
        formik.setFieldValue('EventDistrictId', '');
        formik.setFieldValue('AccidentAddress', '');
      }
    }
  };

  const activeDirectChange = (fieldName: string, selectedValue: string) => {
    changeDynamicValues(fieldName, selectedValue);
    formik.setFieldValue(fieldName, selectedValue);
  };

  const handleErrors = (field) => {
    if (!field.modelBinding || !errors[field.modelBinding]) {
      return;
    }

    const { [field.modelBinding]: _, ...updatedErrors } = errors;
    setErrors(updatedErrors);
  };

  const checkFieldDependency = (field, fields) => {
    if (!field.dependencyBinding) {
      return true;
    }

    const dependencyBinding = field.dependencyBinding.replace('.', '&');
    const dependencyValues = field.dependencyValues;
    const dependencyFieldValue = formik.values[dependencyBinding];

    const nestedFieldDependency = fields.find(
      (f) =>
        (f.keyName === dependencyBinding ||
          f.modelBinding === dependencyBinding) &&
        f.dependencyBinding,
    );

    const shouldShow = dependencyValues.includes(dependencyFieldValue);

    if (shouldShow && nestedFieldDependency) {
      handleErrors(nestedFieldDependency);

      return checkFieldDependency(nestedFieldDependency, fields);
    }

    if (!shouldShow) {
      handleErrors(field);
    }

    return shouldShow;
  };

  function setOptions(data, field) {
    const getCatalogOptions = (type, provinceId, cantonId) => {
      if (type === 'CANTON') {
        return CATALOGS.getCantons('', Number(provinceId));
      } else if (type === 'DISTRICT') {
        return CATALOGS.getDistricts('', Number(cantonId));
      }

      return '';
    };

    const fieldMappings = {
      Ciudad: {
        ACCIDENTCANTONID: 'AccidentProvinceId',
        EVENTCANTONID: 'EventProvinceId',
        CANTONDIRECTIONINSPECT: 'provinceDirectionInspect',
        CANTONDIRECTIONSINISTER: 'provinceDirectionSinister',
      },
      Distrito: {
        ACCIDENTCANTONID: 'AccidentProvinceId',
        EVENTCANTONID: 'EventProvinceId',
        CANTONDIRECTIONINSPECT: 'provinceDirectionInspect',
        CANTONDIRECTIONSINISTER: 'provinceDirectionSinister',
        EVENTDISTRICTID: 'EventCantonId',
        ACCIDENTDISTRICTID: 'AccidentCantonId',
      },
      Corregimiento: {
        ACCIDENTDISTRICTID: 'AccidentCantonId',
        EVENTDISTRICTID: 'EventCantonId',
        DISTRICTDIRECTIONINSPECT: 'cantonDirectionInspect',
        DISTRICTDIRECTIONSINISTER: 'cantonDirectionSinister',
      },
      Cantón: {
        ACCIDENTCANTONID: 'AccidentProvinceId',
        EVENTCANTONID: 'EventProvinceId',
      },
    };

    let option = '';
    const labelMappings = fieldMappings[field.label];

    if (labelMappings) {
      const modelBinding = labelMappings[field.modelBinding.toUpperCase()];

      if (modelBinding && formik.values[modelBinding]) {
        if (
          (field.label === 'Distrito' &&
            [CountryCode.PAN].includes(
              localStorage.getItem('countryCode') as CountryCode,
            )) ||
          field.label === 'Ciudad' ||
          field.label === 'Cantón'
        ) {
          option = getCatalogOptions(
            'CANTON',
            formik.values[modelBinding],
            null,
          );
        } else if (
          field.label === 'Corregimiento' ||
          field.label === 'Distrito'
        ) {
          option = getCatalogOptions(
            'DISTRICT',
            null,
            formik.values[modelBinding],
          );
        }
      }
    }

    data = option || data;

    if (data.length > 0) {
      field.constraints.options = data.split('¬').map((option) => {
        const [id, value] = option.split('|');

        return { id, value };
      });
    }
  }

  const actionButton = async (actionName: string) => {
    switch (actionName) {
      case 'Siniestrar':
        await sinisterClaims(event, dispatch);
        break;
    }
  };

  const InputField = (type: any, field: any, fields: any) => {
    let fieldName = field.modelBinding ? field.modelBinding : field.keyName;
    if (field.modelBinding && field.modelBinding.includes('.')) {
      fieldName = field.modelBinding.replace('.', '&');
    }
    const isDisabled = disabled ? disabled : field.constraints.isReadOnly;
    let defaultValue = field.defaultValue;
    let letterText: any;
    let value: any;
    let isVisible = true;
    let error = false;

    // Validate formik Session Data
    const storedValues = JSON.parse(
      sessionStorage.getItem('formikValues') || '{}',
    );

    if (formik.values[fieldName]) {
      defaultValue = formik.values[fieldName];
    } else if (storedValues[fieldName]) {
      defaultValue = storedValues[fieldName];
    }

    isVisible = checkFieldDependency(field, fields);

    switch (type?.toLowerCase()) {
      case FieldTypes.TEXT:
      case FieldTypes.FILLED_TEXT:
        return (
          isVisible && (
            <Field
              key={field.key}
              as={TextField}
              type="text"
              label={field.label}
              fullWidth
              className={field.constraints.isAuditable ? 'auditable-field' : ''}
              name={fieldName}
              onChange={(event) => {
                handleChange(fieldName, event.target.value, field);
              }}
              defaultValue={defaultValue}
              disabled={isDisabled}
              error={!!errors[fieldName]}
            />
          )
        );
      case FieldTypes.NUMBER:
        return (
          isVisible && (
            <Field
              key={field.key}
              as={TextField}
              type="number"
              label={field.label}
              fullWidth
              className={field.constraints.isAuditable ? 'auditable-field' : ''}
              name={fieldName}
              defaultValue={defaultValue}
              disabled={isDisabled}
              error={!!errors[fieldName]}
            />
          )
        );
      case FieldTypes.TEXTAREA:
        return (
          isVisible && (
            <Field
              key={field.key}
              as={TextField}
              type="text"
              label={field.label}
              fullWidth
              className={clsx('inspectionForm__input', {
                'auditable-field': field.constraints.isAuditable,
              })}
              multiline={true}
              variant="outlined"
              name={fieldName}
              defaultValue={defaultValue}
              disabled={isDisabled}
              error={!!errors[fieldName]}
            />
          )
        );
      case FieldTypes.REINSPECTION:
        return <Reinspection eventId={claim.sicId} />;
      case FieldTypes.CHECKBOX_LIST: {
        let coverages: any;
        let selectValues: any = [];
        let optionsCoverages: any = [];

        if (field.constraints?.options.length > 0) {
          switch (field.constraints?.options[0].value) {
            case CheckBoxTypes.COVERAGES:
              coverages = Array.from(availableCoverages);
              if (coverages.length > 0) {
                optionsCoverages = coverages;
              }

              if (defaultValue?.length > 0) {
                const selectCoverages = JSON.parse(defaultValue);
                const values: any = [];
                for (const coverage of selectCoverages) {
                  let model = {
                    id: +coverage.coverageId,
                    name: coverage.coverageName,
                    state: true,
                  };

                  if (
                    (!model.name || String(model.name).length === 0) &&
                    field.constraints.options
                  ) {
                    const searchCoverage: any = Array.from(
                      field.constraints.options,
                    ).find((s: any) => s.id === model.id);
                    if (searchCoverage) {
                      model = { ...model, name: searchCoverage.name };
                    }
                  }

                  values.push(model);
                }

                selectValues = values;
              }
              break;
            case CheckBoxTypes.COVERAGES_QUALITAS:
              coverages = event?.qualitasCoverages ?? [];
              if (coverages.length > 0) {
                const options: any = [];
                for (const coverage of coverages) {
                  options.push({
                    id: coverage.coverageId,
                    name: coverage.coverageName,
                    state: true,
                  });
                }

                const selectedOptions = Array.from(
                  coverages as ICoverage[],
                ).filter(
                  (x: ICoverage) => x.Selected !== null && x.Selected === true,
                );

                const values: any = [];
                for (const coverage of selectedOptions) {
                  values.push({
                    id: coverage.coverageId,
                    name: coverage.coverageName,
                    state: true,
                  });
                }

                selectValues = values;
                optionsCoverages = options;
              }
              break;
            default:
              coverages = [];
              break;
          }

          return (
            <CheckboxList
              name={field.name}
              checkList={JSON.stringify(optionsCoverages)}
              checkData={JSON.stringify(selectValues)}
              onChange={(event) => {
                const list = JSON.parse(event.target.value);
                const coverages: any = [];
                for (const coverage of list) {
                  coverages.push({
                    eventId: claim.sicId,
                    coverageId: String(coverage.id),
                  });
                }
                handleChangeValue(fieldName, JSON.stringify(coverages));
              }}
              onBlur={() => {
                console.warn('onBlur');
              }}
              isDisabled={isDisabled}
              error={!!errors[fieldName]}
            />
          );
        } else {
          return <></>;
        }
      }
      case FieldTypes.DROPDOWN:
        return (
          isVisible && (
            <FormControl
              variant="outlined"
              className={clsx('inspectionForm__input')}
            >
              <InputLabel
                key={field.key}
                style={{
                  backgroundColor: '#F9FCFF',
                  paddingRight: 5,
                  paddingLeft: 5,
                }}
              >
                {field.label}
              </InputLabel>
              <Select
                key={field.name}
                name={fieldName}
                sx={{ backgroundColor: 'white' }}
                value={fieldValues?.[fieldName] ?? defaultValue}
                error={!!errors[fieldName]}
                onChange={(event) =>
                  handleChange(fieldName, event.target.value, field)
                }
              >
                {field.constraints.options.map((option, index) => (
                  <MenuItem
                    key={`${index}-${option.id}`}
                    value={option.id}
                    disabled={option.id === '-1' || isDisabled}
                    style={option.id === '-1' ? { fontStyle: 'italic' } : {}}
                  >
                    {option.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )
        );
      case FieldTypes.DYNAMIC_DROPDOWN: {
        let options = '';

        switch (field.constraints?.options[0].value) {
          case DropDownTypes.PROVINCES:
            options = CATALOGS.getProvinces();
            break;
          case DropDownTypes.INSPECTORS:
            options = INSPECTORS.getDataToField();
            break;
          default:
            break;
        }

        setOptions(options, field);

        return (
          isVisible && (
            <FormControl
              variant="outlined"
              className={clsx('inspectionForm__input')}
            >
              <InputLabel
                key={field.key}
                style={{
                  backgroundColor: '#F9FCFF',
                  paddingRight: 5,
                  paddingLeft: 5,
                }}
              >
                {field.label}
              </InputLabel>
              <Select
                key={field.name}
                name={fieldName}
                sx={{ backgroundColor: 'white' }}
                value={fieldValues?.[fieldName] ?? defaultValue}
                error={!!errors[fieldName]}
                onChange={(event) => {
                  handleChangeDynamic(fieldName, event.target.value);
                }}
              >
                {field.constraints.options.map((option, index) => (
                  <MenuItem
                    key={`${index}-${option.id}`}
                    value={option.id}
                    disabled={option.id === '-1' || isDisabled}
                    style={option.id === '-1' ? { fontStyle: 'italic' } : {}}
                  >
                    {option.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )
        );
      }
      case FieldTypes.DATE: {
        let maxDate;
        if (field.keyName === 'eventDateSinister') {
          maxDate = event?.event?.eventDateInspection
            ? new Date(
                event?.event?.eventDateInspection.split('T')[0] + ' 00:00:00',
              )
            : undefined;
        }

        return (
          isVisible && (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                maxDate={maxDate && dayjs(maxDate)}
                label={field.label}
                value={
                  defaultValue !== null && defaultValue !== ''
                    ? dayjs(defaultValue)
                    : null
                }
                className={
                  field.constraints.isAuditable ? 'auditable-field' : ''
                }
                format={CATALOGS.getCountryFormat().dateTimeFormat}
                disabled={isDisabled}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    variant: 'outlined',
                    margin: 'none',
                    'aria-label': 'change date',
                    error: !!errors[fieldName],
                  },
                }}
                onChange={(date) => {
                  if (date) {
                    const dayjsDate = dayjs(date);
                    if (dayjsDate.isValid()) {
                      formik.setFieldValue(
                        fieldName,
                        dayjsDate.format('YYYY-MM-DD'),
                      );
                    }
                  }
                }}
              />
            </LocalizationProvider>
          )
        );
      }
      case FieldTypes.TIME: {
        value = timeFormat(defaultValue);

        return (
          isVisible && (
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              localeText={{
                okButtonLabel: 'GUARDAR HORA',
                cancelButtonLabel: 'CANCELAR',
              }}
            >
              <MobileTimePicker
                className={
                  field.constraints.isAuditable ? 'auditable-field' : ''
                }
                label={field.label}
                defaultValue={value !== null ? dayjs(value) : null}
                disabled={isDisabled}
                onChange={(date) => {
                  const time = onCustomChange(date);
                  setTimeout(() => {
                    formik.setFieldValue(fieldName, time);
                  }, 1000);
                }}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    variant: 'outlined',
                    margin: 'none',
                    'aria-label': 'change time',
                    error: !!errors[fieldName],
                    InputProps: {
                      name: fieldName,
                      endAdornment: (
                        <IconButton
                          sx={{ marginRight: '-12px' }}
                          disabled={field.constraints.isReadOnly}
                        >
                          <AccessTimeIcon />
                        </IconButton>
                      ),
                    },
                  },
                }}
              />
            </LocalizationProvider>
          )
        );
      }
      case FieldTypes.RADIOBUTTON:
        error = !!errors[fieldName];

        return (
          isVisible && (
            <>
              <InputLabel key={field.name}>{field.label}</InputLabel>
              <RadioGroup
                aria-label={field.label}
                name={field.keyName}
                value={fieldValuesRadio[field.keyName] || defaultValue}
                onChange={(event) =>
                  handleChangeValue(fieldName, event.target.value)
                }
                row
              >
                {field.constraints.options.map((option, index) => (
                  <FormControlLabel
                    key={`${index}-${option.id}`}
                    value={option.id}
                    control={
                      <Radio
                        sx={{
                          color: error ? '#ff0000' : 'default',
                        }}
                      />
                    }
                    label={option.value}
                    disabled={isDisabled}
                  />
                ))}
              </RadioGroup>
            </>
          )
        );
      case FieldTypes.DIAGRAM:
        return (
          isVisible && (
            <Sketch
              isVirtualInspector={false}
              saveFn={(): void => {
                console.warn('click here');
              }}
              value={fieldValues?.[fieldName] ?? defaultValue}
              onChange={(): void => {
                console.warn('click here onchange');
              }}
              disabled={false} //confirmar el uso del inspector
              name={`${field.keyName}|${field.key}`}
            />
          )
        );
      case FieldTypes.SIGNATURE:
        return (
          isVisible && (
            <Signature
              signature={fieldValues?.[fieldName] ?? defaultValue}
              onSignatureChange={(signature) => {
                handleInputChange(signature, fieldName);
              }}
              onConfirm={() => {
                setFieldValues((prevValues) => ({
                  ...prevValues,
                  [fieldName]: formik.values[fieldName],
                }));
              }}
              isInspectorForm={false}
              hasError={field.hasError}
              isDisabled={isDisabled}
              noEditable={noInspector}
            />
          )
        );
      case FieldTypes.AUDIO:
        return (
          isVisible && (
            <AudioField
              Field={field}
              save={(event) => {
                console.warn(event);
              }}
              onChange={() => {
                console.warn('click here');
              }}
              poNumber={claim.poNumber}
              hidden={noInspector}
            />
          )
        );

      case FieldTypes.BUTTON:
        return (
          <Button
            key="btnEdit"
            name="btnEdit"
            variant="outlined"
            className={clsx('settingsForm__toggle-button')}
            onClick={() => actionButton(field.label)}
          >
            {field.label}
          </Button>
        );

      case FieldTypes.HTMLTEXT:
        letterText = String(defaultValue);

        return (
          isVisible && (
            <div
              className="content"
              dangerouslySetInnerHTML={{ __html: letterText }}
            ></div>
          )
        );
      case FieldTypes.STYLIZED_RADIOBUTTONS:
        error = !!errors[fieldName];

        return (
          isVisible && (
            <Grid
              item
              container
              justifyContent="flex-start"
              direction="row"
              spacing={2}
              style={{ marginTop: 5, padding: 8 }}
            >
              <Grid item style={{ minWidth: 149, padding: 8 }}>
                <InputLabel key={field.keyName} style={{ marginRight: 15 }}>
                  {field.label}
                </InputLabel>
              </Grid>
              <Grid item>
                {field.constraints.options.map((option: any, key: number) => {
                  return (
                    <Button
                      key={`${key}-${option.value}`}
                      name={fieldName}
                      value={option.id}
                      variant="outlined"
                      disableElevation
                      onClick={() => activeDirectChange(fieldName, option.id)}
                      className={clsx(
                        error
                          ? 'inspectionForm__toggle-button-error'
                          : 'inspectionForm__toggle-button',
                        defaultValue === option.id
                          ? 'inspectionForm__toggle-button--selected'
                          : '',
                      )}
                      disabled={isDisabled}
                    >
                      {option.value}
                    </Button>
                  );
                })}
              </Grid>
            </Grid>
          )
        );
      case FieldTypes.DISCLAIMER:
        return isVisible && <p>{defaultValue}</p>;
      case FieldTypes.DAMAGES_DIAGRAM: {
        const damages = getDamages(defaultValue);

        return (
          <div className="damages-container">
            <div className="diagram">
              <VehicleDamages
                damages={damages.forDiagram}
                onChangeDamages={(newDamages) => {
                  handleChange(
                    fieldName,
                    getDamagesString(newDamages, defaultValue),
                    field,
                  );
                }}
                readonly={field?.constants?.isReadOnly}
              />
            </div>
            <div className="damage-list">
              <ul>
                {damages.list.length ? (
                  damages.list.map((d, index) => (
                    <li key={`${index}-${d.key}`}>
                      {d.title} #{d.key} - <b>{d.description.toUpperCase()}</b>
                    </li>
                  ))
                ) : (
                  <li>No hay daños para mostrar</li>
                )}
              </ul>
            </div>
          </div>
        );
      }
      case FieldTypes.CHIPSTATUS:
        return (
          <div className="MuiGrid-root MuiGrid-container MuiGrid-spacing-xs-2 chip-container">
            <div className="MuiGrid-root MuiGrid-item chipLabel">
              <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-animated">
                {field.label}
              </label>
            </div>
            <Chip
              className={clsx('chipTop')}
              icon={
                defaultValue === 'Validada' ? (
                  <DoneIcon />
                ) : (
                  <ErrorOutlineIcon />
                )
              }
              label={defaultValue ?? ''}
              clickable
              color={defaultValue === 'Validada' ? 'primary' : 'default'}
            />
          </div>
        );
      default:
        break;
    }
  };

  return (
    <Grid item container direction="row" spacing={2}>
      {/* {JSON.stringify(section)} */}
      {section.containers.length > 0 ? (
        section.containers.map((item: any) =>
          item.fields.map((field: any) => {
            const inputField = InputField(
              field.targetField,
              field,
              item.fields,
            );
            if (inputField) {
              return (
                <Grid
                  item
                  key={field.key}
                  xs={12}
                  sm={6}
                  md={Number(field.constraints.flex)}
                >
                  {inputField}
                </Grid>
              );
            }

            return null;
          }),
        )
      ) : (
        <Grid item sm={4}>
          <label>Sin información</label>
        </Grid>
      )}
    </Grid>
  );
};

export default FormFieldsComponent;
