import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Snackbar,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Alert, AlertColor } from '@mui/lab';
import {
  IInspectionContext,
  InspectionContext,
  getClaimById,
  getClaimTrackerById,
  getFraud,
  getFraudScan,
} from 'context/inspections';
import { Skeleton } from '@mui/material';
import ClaimSections from 'components/claims/sections';
import ClaimSteps from 'components/claims/steps';
import Steps from 'components/claims/information';
import General from 'components/claims/information/general';
import Fraud from 'components/claims/fraud';
import { IClaim, IClaimForm } from 'types/claimsTracker';
import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from 'react';
import { useParams } from 'react-router-dom';
import { SelectOption } from 'types/select-option-inspection';
import './index.scss';
import Loader from 'components/common/loader/Loader';
import { ActionType } from 'types/action';
import FooterContext from 'context/footer/footer-context';
import HeaderContext from 'context/header/header-context';
import Divider from '@mui/material/Divider';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PATH_ROUTES from 'static/constants/path-routes';
import { useHistory } from 'react-router-dom';
import Alerts from '../../assets/icons/alertas.svg';
import ConnectServicesClient from 'shared/clients/ConnectServiceClient';
import { TotalLossAdjustProvider } from 'context/adjust/totalLoss-adjust-context';
import FraudScan from 'components/claims/fraud-scan';

export default function Claim(): JSX.Element {
  const { state: inspectionState, dispatch } = useContext<IInspectionContext>(
    InspectionContext,
  );
  const { id } = useParams<any>();
  const [insuredSufix, setInsuredSufix] = useState<undefined | any>(undefined);
  const [selectedSection, setSelectedSection] = useState<number>(0);
  const [steps, setSteps] = useState<SelectOption[]>([]);
  const [loading, setLoading] = useState(true);
  const [currentClaimForm, setCurrentClaimForm] = useState<
    IClaimForm | undefined
  >(undefined);
  const [legacyCurrentClaim, setLegacyCurrentClaim] = useState<
    IClaim | undefined
  >(undefined);
  const { propFromChildren, setPropFromChildren } = useContext(FooterContext);
  const propFromChildrenRef = useRef(setPropFromChildren);
  const { propContentTitle, setPropContentTitle } = useContext(HeaderContext);
  const history = useHistory();
  const captionStyle = 'caption';
  const informationStyle = 'information';
  const [expanded, setExpanded] = useState(
    localStorage.getItem('expanded') === 'true' || false,
  );
  const [fraudData, setFraudData] = useState<any>();
  const [fraudScanData, setFraudScanData] = useState<any>();
  const [showFraud, setShowFraud] = useState<boolean>(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const details = [
    { id: '2', name: 'documents', title: 'Documentos', icon: 'FILE' },
    { id: '3', name: 'observations', title: 'Observaciones', icon: 'CHAT' },
    { id: '4', name: 'history', title: 'Historial del caso', icon: 'CLOCK' },
    { id: '5', name: 'reminders', title: 'Recordatorios', icon: 'CALENDAR' },
    //{ id: '6', name: 'sendDocument', title: 'Enviar Documento', icon: 'CLOCK' },
  ];

  const handleChangeSufix = (event: any) => {
    setInsuredSufix(event.target.value);
    history.replace(PATH_ROUTES.CLAIMS.replace(':id', event.target.value.uId));
  };

  const handleSectionSelected = (sectionId: number) => {
    setSelectedSection(sectionId);
  };

  const filterSections = (data: any) => {
    const filter = data.filter(
      (section: any) => section.id === selectedSection,
    );
    const response = filter[0];

    return response;
  };
  const getLegacyCurrentClaim = async (ClaimUId: string) => {
    const _legacyCurrentClaim = await getClaimById(ClaimUId);
    if (_legacyCurrentClaim.accountReference) {
      const workshops = await new ConnectServicesClient().getWorkshopByCompany(
        _legacyCurrentClaim.accountReference,
      );
      dispatch({
        type: ActionType.SET_WORKSHOPS_DATA,
        payload: workshops?.data,
      });
    }
    setLegacyCurrentClaim(_legacyCurrentClaim);
    dispatch({
      type: ActionType.SET_CLAIMS_INFO_DATA,
      payload: _legacyCurrentClaim,
    });
  };
  const getClaimForm = useCallback((ClaimUId: string) => {
    if (ClaimUId) {
      (async (): Promise<void> => {
        const { claimForm } = await getClaimTrackerById(ClaimUId);
        getLegacyCurrentClaim(ClaimUId);
        const stages = claimForm.steps.map((stage: any) => stage);

        sessionStorage.removeItem('formikValues');
        setCurrentClaimForm(claimForm);
        setSteps(stages);

        setLoading(false);
        dispatch({ type: ActionType.SET_LOADING, payload: false });
      })();
    }
  }, []);

  const getFraudData = useCallback(async (EventId: string) => {
    if (EventId) {
      const data = await getFraud(String(EventId));
      setShowFraud(!!data);
      setFraudData(data);
    }
  }, []);

  const getFraudScanData = useCallback(async (claimNumber: string) => {
    const data = await getFraudScan(claimNumber);
    setFraudScanData(data);
  }, []);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded);
    localStorage.setItem('expanded', isExpanded);
  };

  const skeletonLoader = (): JSX.Element => {
    return (
      <Stack spacing={1}>
        <Skeleton variant="rectangular" height={100} />
        <Skeleton variant="rounded" height={450} />
      </Stack>
    );
  };

  useEffect(() => {
    if (currentClaimForm) {
      propFromChildrenRef.current(
        <Steps steps={details} claim={currentClaimForm} uId={id} />,
      );

      const caseWithId = currentClaimForm.info.cases.find(
        (caseItem: any) => caseItem.uId.toLowerCase() === id.toLowerCase(),
      );
      setInsuredSufix(caseWithId);
    }
  }, [currentClaimForm, id]);

  useEffect(() => {
    if (currentClaimForm) {
      getFraudData(currentClaimForm.info.sicId);
      getFraudScanData(currentClaimForm.info.case);
    }
  }, [currentClaimForm]);

  useEffect(() => {
    dispatch({ type: ActionType.SET_LOADING, payload: true });
    setPropContentTitle(
      <Stack direction="row" spacing={2} alignItems="center">
        <Box>
          Reclamación: <br></br>#{currentClaimForm?.info.claim}
        </Box>
        <Divider orientation="vertical" flexItem />
        <Box>
          <FormControl className="claim__select">
            <InputLabel
              htmlFor="demo-select-small"
              id="demo-select-small-label"
              className="claim__select-label"
            >
              Caso: {insuredSufix?.claimNumberCase}
            </InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={insuredSufix || ''}
              onChange={handleChangeSufix}
              IconComponent={ExpandMoreIcon}
              className="claim__select-input"
            >
              {currentClaimForm?.info.cases.map((caseItem: any) => (
                <MenuItem key={caseItem.uId} value={caseItem}>
                  <b>
                    {caseItem.claimNumberCase + ' ' + caseItem.claimantType}
                  </b>
                  <span className="claim__select-input--textList">
                    {caseItem.ownerName ?? 'N/D'}
                  </span>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Stack>,
    );
  }, [insuredSufix]);

  useEffect(() => {
    return () => {
      propFromChildrenRef.current(null);
      setPropContentTitle('Dashboard Reclamaciones');
      sessionStorage.removeItem('formikValues');
    };
  }, []);

  useEffect(() => {
    if (steps && steps.length > 0) {
      let currentStep = sessionStorage.getItem('currentStep') ?? '';
      currentStep =
        currentStep.length > 0
          ? currentStep
          : currentClaimForm?.info.currentStep;
      let found = steps.find((elem) => elem.stageName === currentStep);
      found = found ?? steps.sort((a: any, b: any) => a.order - b.order)[0];
      if (found?.id) {
        setSelectedSection(found.id);
      }
    }
  }, [steps]);

  useEffect(() => {
    getClaimForm(String(id));
  }, [id, getClaimForm]);

  return (
    <Box className="claim">
      <Box className="claim__row">
        <Loader isActive={inspectionState.loading} />
      </Box>
      {loading ? (
        skeletonLoader()
      ) : (
        <>
          <Accordion
            expanded={expanded}
            onChange={handleChange('panel1')}
            className="claim__general custom-accordion"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Box
                display="flex"
                alignItems="center"
                className="box-content"
                flexDirection={isSmallScreen ? 'column' : 'row'}
                justifyContent="space-between"
              >
                <div
                  style={{
                    flex: 1.5,
                    textAlign: 'left',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <img
                    src={Alerts}
                    alt="Alerts Icon"
                    className="claim__general--icon"
                  />
                  <Typography variant="body1" className="claim__general--label">
                    Datos generales de la reclamación
                  </Typography>
                </div>
                <div style={{ flex: 1.5, textAlign: 'center' }}>
                  {!expanded && (
                    <div className="claim__general--fraud">
                      {showFraud && (
                        <Fraud data={fraudData} expanded={expanded} claim={currentClaimForm?.info.claim} />
                      )}
                    </div>
                  )}
                </div>
                <div style={{ flex: 1 }}>
                  {fraudScanData && !expanded && (
                    <FraudScan
                      data={fraudScanData}
                      expanded={expanded}
                    ></FraudScan>
                  )}
                </div>
                <div style={{ flex: 1 }}>
                  <Typography className="claim__general--details">
                    {expanded ? 'Ver menos' : 'Ver detalles'}
                  </Typography>
                </div>
              </Box>
            </AccordionSummary>
            <AccordionDetails className="accordionDetails">
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={2}
              >
                <General
                  claim={currentClaimForm?.info}
                  caption={captionStyle}
                  information={informationStyle}
                />
                {expanded && (
                  <div className="claim__general--fraud-expanded">
                    {fraudScanData && (
                      <FraudScan
                        data={fraudScanData}
                        expanded={expanded}
                      ></FraudScan>
                    )}
                    {showFraud && (
                      <Fraud data={fraudData} expanded={expanded} claim={currentClaimForm?.info.claim} />
                    )}
                  </div>
                )}
              </Stack>
            </AccordionDetails>
          </Accordion>
          <Box className="claim__content">
            <Box>
              <ClaimSteps
                steps={steps}
                currentClaimForm={currentClaimForm}
                onChangeTab={handleSectionSelected}
                defaultStep={selectedSection?.toString()}
              ></ClaimSteps>
            </Box>
            <Box className="claim__detail">
              <TotalLossAdjustProvider>
                <ClaimSections
                  claimUId={id}
                  legacyCurrentClaim={legacyCurrentClaim}
                  setLegacyCurrentClaim={setLegacyCurrentClaim}
                  currentClaimForm={currentClaimForm}
                  sections={filterSections(steps)}
                  steps={steps}
                ></ClaimSections>
              </TotalLossAdjustProvider>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
}
