// ! Needs Authorization
export const POST_SUBMIT_USER = '/v1/users';
export const GET_USER = '/v1/users/{guid}';
export const GET_USER_PERMISSIONS = '/v1/users/permissions';
export const GET_ALL_USERS = '/v1/users/all/{type}';
export const GET_AGENTS = '/v1/users/agents';
export const GET_INSPECTIONS = '/v1/events/search';
export const GET_INSPECTIONS_CLAIMS = '/v2/events/search';
export const GET_INSPECTIONS_CLAIMS_PAGINATOR = '/v2/events/paginator';
export const GET_INSPECTION_NOTES = '/v1/events/{id}/comment';
export const GET_INSPECTION_NOTES_TYPE = '/v1/events/{id}/commentByType';
export const POST_INSPECTION_NOTES = '/v1/events/{id}/comment';
export const PUT_INSPECTION_NOTES = '/v1/events/{id}/comment/{commentId}';
export const GET_INSPECTION_REMINDERS = '/v1/events/{id}/reminder';
export const POST_INSPECTION_REMINDERS = '/v1/events/{id}/reminder';
export const PUT_INSPECTION_REMINDERS = '/v1/events/{id}/reminder/{reminderId}';
export const GET_INSPECTORS = '/v1/inspectors';
export const GET_EVENT_URL = '/v1/events/url/{id}';
export const GET_DOWNLOAD_EVENT = '/v1/downloads/event/{roleId}/{eventRecord}';
export const POST_DOWNLOAD_EVENT = '/v1/downloads/event';
export const POST_DOWNLOAD_PREINSPECTION = '/v1/downloads/preinspection';
export const POST_SEND_PDF = '/v1/downloads/send/{id}';
export const POST_SEND_PDF_V2 = '/v2/downloads/send/{id}';
export const POST_DOWNLOAD_FILE = '/v1/downloads/consolidate';
export const POST_INTEGRATION_RETRY = '/v1/integrations/retry/{eventId}';
export const GET_TRANSITIONS = '/v1/transitions/retrieve';
export const GET_AUDIT_LOGS = '/v1/events/audit-logs';
export const POST_ADD_AUDITLOGS = '/v1/events/auditLogAdd';
export const POST_TRANSITIONS = '/v1/transitions/add';
export const POST_TRANSITIONS_RESTORE = '/v1/transitions/restore';
export const POST_DOWNLOAD_REPORT = '/v1/downloads';
export const POST_DOWNLOAD_REPORT_CLAIMS = '/v1/downloads/claim';
export const POST_DOWNLOAD_AE_REPORT = '/v1/downloads/express';
export const GET_COMPANIES = '/v1/catalogs/{countryCode}/companies';
export const GET_COMPANIES_COUNTRY =
  '/v1/catalogs/{countryCode}/companiesByCountry';
export const PATCH_EXPRESS_ADJUST =
  '/v1/events/{eventId}/express-adjust/{expressAdjust}';
export const GET_LOCATIONS = '/v1/catalogs/provinces?code={countryCode}';
export const DELETE_COUNTERPART = '/v1/events/counterparts/{counterpartId}';
export const DELETE_INJURED_PERSON = '/v1/events/injuredperson/{injuredId}';
export const GET_NON_ATTENDED_TOTAL = '/v1/events/comments/non-attended';
export const PATCH_COMMENT_ATTENDED =
  '/v1/events/comments/{commentId}/attended/{attended}';
export const PATCH_EVENT_AGENT = '/v1/events/agent/{agentId}';
export const GET_RESPOSABLE_AAP = '/v1/aap/responsable';
export const GET_GRID_COLUMNS =
  '/v1/events/grid/{companyId}/{roleId}/?formTypeId={formTypeId}';
export const POST_EVENT_TAG = '/v1/events/{eventId}/tag/{tagId}';
export const GET_COMPANY_TAGS = '/v1/events/tags/{companyId}';
export const POST_EVENT_THIRD = '/v1/events/{eventId}/third';
export const POST_EVENT_INJURED = '/v1/events/{eventId}/injured';
export const PATCH_INSPECTION_ACTIVE_LINK =
  '/v1/events/{id}/active-link/{userId}';
export const GET_USER_DRIVERS = '/v1/users/{userId}/drivers';
export const DELETE_USER_DRIVER = '/v1/users/drivers/{driverId}';
export const POST_USER_DRIVER = '/v1/users/{userId}/drivers';
export const GET_RUNNEROWNERS = '/v1/catalogs/runnerOwners';
export const POST_EVENT_AUDIT = '/v1/events/{eventId}/audit';
export const POST_EVENT_LOG = '/v1/transitions/eventlog';
export const POST_EVENT_TRANSCRIPT = '/v1/events/updateTranscriptInd/{id}';
export const GET_WORKSHOPS = '/v2/workshops/company/{companyId}';
export const GET_DOCUMENT_TYPES = '/v1/catalogs/documentTypes';
export const GET_NOTE_TYPES = '/v1/catalogs/noteTypes';

// Qualitas
export const GET_SINIESTRAR = '/v1/integrations/siniestrar/{id}';
export const POST_ALTA_REPORTE = '/v1/integrations/altareporte';
export const POST_ALTA_SINIESTRO = '/v1/integrations/altasiniestro';
export const POST_UPDATE_COVERAGES_QUALITAS = '/v1/integrations/coverages';
export const POST_UPDATE_QUALITAS = '/v1/events/updatequalitas';

// Pre-Inspections
export const GET_PRE_INSPECTIONS = '/v1/preinspections/search';
export const POST_PRE_INSPECTION = '/v1/preinspections';
export const GET_PRE_INSPECT_TRANSITIONS =
  '/v1/transitions/preinspect/retrieve';
export const POST_PRE_INSPECT_TRANSITIONS = '/v1/transitions/preinspect/add';
export const POST_PRE_INSPECTION_ALERT_UPDATE = '/v1/alerts/update';
export const GET_PRE_INSPECTION_ALERTS = '/v1/alerts/retrieve';
export const GET_PRE_INSPECTION_DAMAGES = '/v1/damages';
export const POST_PRE_INSPECTION_SHARED = '/v1/preinspections/shared';
export const GET_AGENCIES = '/v1/catalogs/agencies';
export const PUT_PRE_INSPECTION_REMOVE_EXPIRE =
  '/v1/preinspections/remove-expiration/{id}';
export const GET_DAMAGE_CONFIG = '/v1/preinspections/damage-analysis/{id}';
export const POST_DAMAGE_CONFIG = '/v1/preinspections/damage-analysis';
export const REQUEST_ANALYSIS_DAMAGE = '/v1/integrations/analysis-damages/{id}';

// Media
export const POST_MEDIA_UPLOAD = '/v1/media';
export const GET_MEDIA_FILES = '/v1/media/resources';
export const DELETE_MEDIA_FILES = '/v1/media/remove';
export const POST_DOCUMENT_OBSERVATION =
  '/v1/media/{ClaimUId}/documentObservations';
export const GET_DOCUMENT_OBSERVATION =
  '/v1/media/{ClaimUId}/documentObservations';
export const GET_DOCUMENT_OBSERVATION_BYNAME =
  '/v1/media/{ClaimUId}/documentObservation';

// ClaimsTracker
export const POST_AUTHORIZATION = '/v1/auths';
export const GET_CLAIMS = '/v1/claims/{id}';
export const UPDATE_CLAIMS_STATUS = '/v1/claims/{id}/status';
export const POST_PAYMENT_AMOUNT = '/v1/claims/amount';
export const GET_HISTORIC_CLAIM_PAYMENT_AMOUNT = '/v1/claims/{id}/amounts';
export const POST_PUT_CLAIMS_REQUEST = '/v1/requests';
export const DELETE_CLAIMS_REQUEST = '/v1/requests/{id}';
export const NOTIFY_CLAIMS_REQUEST = '/v1/requests/{claimId}/notify';
export const POST_PUT_CLAIMS = '/v1/claims';
export const GET_CLAIMS_FORM = '/v2/forms/{claimId}';
export const POST_CLAIMS_ESTIMATED = '/v1/estimated';
export const GET_CLAIMS_ESTIMATED = '/v1/estimated/{id}/amounts';
export const DELETE_CLAIMS_ESTIMATED = '/v1/estimated/{id}';
export const POST_CLAIMS_ADJUST = '/v1/adjust';
export const GET_CLAIMS_ADJUST = '/v1/adjust/{id}/amounts/{isTotalLoss}';
export const DELETE_CLAIMS_ADJUST = '/v1/adjust/{id}';
export const POST_CLAIMS_EXPENSES = '/v1/expense';
export const GET_CLAIMS_EXPENSES = '/v1/expense/{id}/amounts';
export const DELETE_CLAIMS_EXPENSES = '/v1/expense/{id}';
export const PUT_FINALIZE_AGREEMENT = '/v1/claims/{id}/finish-payment';
export const PUT_UPDATE_AGREEMENT = '/v1/claims/{id}/update-payment';
export const POST_TOTAL_LOSS = '/v1/claims/{id}/total-loss';
export const POST_SUBROGATION = '/v1/claims/{id}/subrogation';
export const GET_DEPRECIATIONS = '/v1/totalLoss/{id}/depreciations';
export const GET_DEPRECIATION_CALCULATE =
  '/v1/totalLoss/depreciation/{id}/calculate';
export const POST_DEPRECIATION = '/v1/totalLoss/depreciation';
export const POST_DEPRECIATION_CALCULATE =
  '/v1/totalLoss/depreciation/calculate';
export const DELETE_CLAIMS_DEPRECIATION = '/v1/totalLoss/depreciation/{id}';
export const POST_APPROVAL = '/v1/approvals/{id}';
export const GET_APPROVALS_USER = '/v1/approvals/user/{user}';
export const GET_APPROVALS_CLAIM = '/v1/approvals/{id}/user';
export const PUT_APPROVAL = 'v1/approvals/approvalStep/{approvalStepId}';

// ServiceTypeByInspection
export const GET_SERVICETYPE = '/v2/ServiceTypeByInspections';
export const POST_SERVICETYPE = '/v2/ServiceTypeByInspections';

// ! Are Anonymous
export const GET_AUTHORIZE = '/v1/accounts/auth/login';
export const GET_INSPECTION = '/v1/events/{id}';
export const POST_INSPECTION_SAVE = '/v1/events/save';
export const POST_PRE_INSPECTIONS = '/v1/preinspections/preinspection';
export const GET_FORM_CONFIG = '/v1/forms/form';
export const PHOTO_IMAGE = '/v1/images';
export const POST_PHOTO = '/v1/images/{eventId}/bucket';
export const GET_IMAGES = '/v1/images/{countryCode}/all/{eventRecord}';
export const PUT_IMAGE_SECTION = '/v1/images/section';
export const DELETE_IMAGE = '/v1/images/{eventRecord}/bucket';
export const ROTATE_IMAGE = '/v1/images/rotate';
export const GET_AAP = '/v1/aap/retrieve';
export const GET_VALIDATE_AAP = '/v1/aap/validate/{id}';
export const GET_FORM_DATA_CONFIG = '/v1/forms';
export const GET_PRE_INSPECTION = '/v1/preinspections/{id}';
export const GET_SERVICE_STATUS = '/v1/status';
export const POST_AUDIO = '/v1/uploads/file/{countryCode}/{eventRecord}';
export const PRESIGNED_AUDIO_URL = '/v1/media/presignedUrl';
export const TRANSCRIPT_AUDIO_URL = '/v1/google/audio/{countryCode}/transcript';
export const POST_PROCESS_CSM = '/v1/integrations/ProcessCsm';
export const POST_UPDATE_DATA_EVENT =
  '/v1/events/{countryCode}/{eventRecord}/save';
export const POST_CLAIM_SAVE = 'v2/events/save';
export const PUT_CLAIM_STATUS = 'v2/claims/{guid}/status';
export const GET_PERCENTAGE_CLAIMANT_TYPE =
  'v1/charts/percentage-claimant-type';
export const GET_PERCENTAGE_STAGE = 'v1/charts/percentage-stage';
export const GET_AVERAGE_STAGE = 'v1/charts/average-stage';
export const GET_CLAIM_BY_COMPANY = 'v1/charts/claim-by-company';
export const GET_FRAUD = 'v2/alerts/{eventId}/fraud';
export const POST_ACCOUNTS_ONEACCESS_VERIFY = '/v1/accounts/one-access/verify';
export const GET_CLAIMS_PARTS = 'v1/parts/{claimUId}';
export const POST_CLAIMS_PARTS = 'v1/parts';
export const DELETE_CLAIMS_PARTS = 'v1/parts/{id}';
export const GET_FRAUD_SCAN = 'v1/alerts/{claimNumber}/fraud-scan';

// ###### endpoints administracion de plantillas #######
export const GET_TEMPLATES = 'templates/GetDocuments';
export const GET_VARIABLES = 'templates/GetVariables/{id}';
export const POST_CREATE_TEMPLATE = 'templates/Create';
export const PUT_UPDATE_TEMPLATE = 'templates/Update';
export const DELETE_REMOVE_PERMISSIONS = 'templates/RemovePermissions/{id}';
export const POST_ENABLED_PERMISSIONS = 'templates/EnablePermissions';
export const POST_GENERATE_PDF = 'templates/GeneratePDF';

export const GET_FIELDS_TEMPLATES =
  'v1/documentTemplates/fields?formType={formTypeId}&companyId={companyId}';
export const GET_VARIABLES_TEMPLATE =
  'v1/documentTemplates/variables?formType={formTypeId}&templateId={templateId}';
export const POST_VARIABLES_TEMPLATE = 'v1/documentTemplates/variable';
export const DELETE_VARIABLES_TEMPLATE = 'v1/documentTemplates/variable/{id}';
export const POST_SEND_DOCUMENT_TEMPLATE = '/v1/public/document-template';
export const POST_EVENT_FINISH = '/v1/events/finish';

// ###### endpoints administracion de plantillas #######

export const PATCH_USERS_CURRENT_COUNTRY =
  '/v1/users/{userId}/current-country/{countryCode}';
