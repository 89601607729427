import React from 'react';
import './index.scss';
import { ArrowForward } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { Rol } from 'types/common';

interface FraudScanProps {
  data: any;
  expanded: boolean;
}

const FraudScan: React.FC<FraudScanProps> = ({ data, expanded }) => {

  const getBackgroundColor = (color) => {
    switch (color) {
      case 'RED':
        return '#F44336';
      case 'GREEN':
        return '#43A047';
      case 'ORANGE':
        return '#FFB41F';
      default:
        return '#43A047'
    }
  };

  const roleID = Number(localStorage.getItem('rolId'));

  const alertColor = getBackgroundColor(data.color);
  const textColor = data.color === 'ORANGE' ? '#001D3D' : '#FFFFFF';

  const isAllowedRole = [Rol.ADMIN, Rol.ADJUSTER, Rol.AUDIT].includes(roleID);  
  const canViewScore = [Rol.ADMIN, Rol.ADJUSTER].includes(roleID);
  
  if (!isAllowedRole) {
    return null;
  }

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" className="fraud-scan" sx={{ backgroundColor: alertColor, borderRadius: expanded ? 0 : 100, color: textColor }}>
      <Box display="flex" alignItems="center" className="fraud-scan__title">
        <label className="fraud-scan__title--label">(FRISS)</label>
        <span className="fraud-scan__title--span">
          &nbsp;Índice de Fraude
          {canViewScore ? `: ${data.score}%` : ''}
        </span>
      </Box>
      <ArrowForward fontSize='small' />
    </Box >

  );
};

export default FraudScan;
