import React, { useEffect, useState } from 'react';
import style from 'override-mui-styles';
import clsx from 'clsx';
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import {withStyles, makeStyles} from '@mui/styles';
import StatusChip from 'components/common/status/chip';
import { IClaim, IClaimStage } from 'types/claimsTracker';
import { updateClaimStatusById } from 'context/inspections';
import { getDateByCountry } from 'shared/utils';

interface props {
  currentClaim?: IClaim;
  onRefresh: () => void;
  onLoad: (value: boolean) => void;
  onNotify: (msg: string, warn: boolean) => void;
}

const useTableStyles = makeStyles({
  table: {
    minWidth: 100,
  },
});

export default function ClaimStatus({
  currentClaim,
  onRefresh,
  onLoad,
  onNotify,
}: props): JSX.Element {
  const classes = style();
  const classesTbl = useTableStyles();

  const [showNextStatusConfirm, setShowNextStatusConfirm] = useState<boolean>(
    false,
  );
  const [labelNextStatus, setLabelNextStatus] = useState<string>('');

  const StyledTableCell = withStyles(() =>
    createStyles({
      head: {
        backgroundColor: 'white',
        color: '#6d7b91',
      },
      body: {
        fontSize: 14,
      },
    }),
  )(TableCell);

  const StyledTableRow = withStyles(() =>
    createStyles({
      root: {
        backgroundColor: '#F0F4F7',
        borderBottom: '10px solid white',
      },
    }),
  )(TableRow);

  const getStatus = (status: string) => {
    switch (status) {
      case 'COMPLETE':
        return 'Claim_Complete';
      case 'PENDING':
        return 'Claim_Pending';
      case 'REQUIRE_INFO':
        return 'Claim_Require_Info';
      default:
        return 'Claim_In_Process';
    }
  };

  const sendNextStatus = () => {
    (async (): Promise<void> => {
      if (currentClaim) {
        setShowNextStatusConfirm(false);
        onLoad(true);

        const resultRequest = await updateClaimStatusById(
          currentClaim.claimUId,
        );

        if (resultRequest === 'success') {
          onRefresh();
        } else if (resultRequest === 'ClaimStatus.NotAgreementAmount') {
          onNotify(
            'Debes definir un monto para el acuerdo con el reclamante.',
            true,
          );
          onLoad(false);
        }
      }
    })();
  };

  useEffect(() => {
    if (currentClaim?.stages && labelNextStatus.length === 0) {
      const lastStage = currentClaim.stages.filter((f) => f.id > 0).at(-1);
      setLabelNextStatus(lastStage?.status === 'COMPLETE' ? 'Paso' : 'Estado');
    }
  }, [currentClaim, labelNextStatus]);

  return (
    <>
      <div className="title-container">
        <h2 className="title">Estado del Proceso de Reclamación</h2>
      </div>
      <Divider />
      <div className="status-log">
        <TableContainer>
          <Table className={classesTbl.table}>
            <TableHead>
              <TableRow>
                <StyledTableCell>Etapa</StyledTableCell>
                <StyledTableCell>Fecha de Transición</StyledTableCell>
                <StyledTableCell>Estado</StyledTableCell>
                <StyledTableCell>Descripción</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.from(currentClaim?.stages ?? []).map(
                (row: IClaimStage, index: number) => (
                  <StyledTableRow key={index}>
                    <StyledTableCell>{row.stageTitle}</StyledTableCell>
                    <StyledTableCell>
                      {row.id === 0
                        ? '--/--/----'
                        : getDateByCountry(
                            row.createdDate,
                            localStorage.getItem('countryCode') ?? '',
                          )}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <StatusChip eventStatus={getStatus(row.status)} />
                    </StyledTableCell>
                    <StyledTableCell>{row.description}</StyledTableCell>
                  </StyledTableRow>
                ),
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="footer-actions">
        <Button
          id="next-status-btn"
          variant="contained"
          className={clsx(classes.button, 'claimsForm__button')}
          size="small"
          onClick={() => setShowNextStatusConfirm(true)}
        >
          Ir al Siguiente {labelNextStatus}
        </Button>
      </div>
      <Dialog
        id="modal-claim-remove-request"
        open={showNextStatusConfirm}
        onClose={() => setShowNextStatusConfirm(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Cambiar de {labelNextStatus}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            ¿Deseas pasar al siguiente {labelNextStatus}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowNextStatusConfirm(false)}
            color="secondary"
            size="small"
          >
            Cancelar
          </Button>
          <Button variant="contained" size="small" onClick={sendNextStatus}>
            Sí, confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
