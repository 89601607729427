import React, { createContext, useState } from 'react';

interface ContextProps {
  propFromChildren: string;
  setPropFromChildren: React.Dispatch<React.SetStateAction<any>>;
}

const FooterContext = createContext<ContextProps>({
  propFromChildren: '',
  setPropFromChildren: () => {
    throw new Error('setPropFromChildren is not implemented');
  }
});

export default FooterContext;
