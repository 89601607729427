
import { useParams } from 'react-router-dom';
import Loader from 'components/common/loader/Loader';
import SetVariables from 'components/componentDocuments/setVariables'
import React, { useState } from 'react';

export default function SetVariablesDocument(): JSX.Element {
  const { id } = useParams<any>();
  const [loader, setLoader] = useState(false);


  return (
    <>
      <Loader isActive={loader} />
      <SetVariables idTemplate={id} />
    </>
  );
}
