export const roles = [
  {
    id: 1,
    name: 'Administrador',
  },
  {
    id: 2,
    name: 'Inspector',
  },
  {
    id: 3,
    name: 'Aseguradora',
  },
  {
    id: 4,
    name: 'Corredor',
  },
  {
    id: 5,
    name: 'Supervisor',
  },
  {
    id: 9,
    name: 'Auditor',
  },
  {
    id: 10,
    name: 'Cliente',
  },
  {
    id: 11,
    name: 'Ajustador',
  },
  {
    id: 6,
    name: 'Pre-Inspección Admin',
  },
  {
    id: 8,
    name: 'Pre-Inspección Aseguradora',
  },
  {
    id: 7,
    name: 'Pre-Inspección Corredor',
  },
  {
    id: 12,
    name: 'Pre-Inspección Consultor',
  },
];

export enum roleNames {
  ADMIN = 'Administrador',
  INSPECTOR = 'Inspector',
  INSURANCE = 'Aseguradora',
  AUDIT = 'Audit',
  CUSTOMER = 'Customer',
}

export default {
  roles,
  roleNames,
};
