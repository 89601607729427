import React, { useCallback, useEffect, useState } from 'react';
import { Grid, TextField } from '@mui/material';
import { Autocomplete } from '@mui/material';
import DatePicker from 'components/common/datepicker/DatePicker';
import COMPANIES from 'static/constants/companies';
import INSPECTORS from 'static/constants/inspectors';
import getTags from 'static/constants/tags';
import { Option } from 'types/option';
import styles from './override-mui-styles';
import './styles.scss';
import { CountryCode, ResourceAction, Rol } from 'types/common';
import Switch from '@mui/material/Switch/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import { userActionsPermissions } from 'shared/utils';
import useDevice from 'shared/hooks/useDevice';
import { companyFilterPermission } from 'shared/utils/company-filter-permission';
import { IUserModel } from 'types/user';
interface Props {
  showOnlyDatePicker?: boolean;
  company: Option | null;
  inspector: Option | null;
  tag: Option | null;
  expressAdjust?: boolean | null;
  aapIndicator?: boolean | null;
  startDate: Date | null;
  endDate: Date | null;
  showMyServices?: boolean;
  currentUser: IUserModel;
  onChangeCompany?: (option: Option | null) => void;
  onChangeInspector?: (option: Option | null) => void;
  onChangeTag?: (option: Option | null) => void;
  onChangeExpressAdjust?: (value: boolean | null) => void;
  onChangeAapIndicator?: (value: boolean | null) => void;
  onChangeStartDate: (date: Date | null) => void;
  onChangeEndDate: (date: Date | null) => void;
  onChangeDateRange: (dates: Date[]) => void;
  setCurrentInspector?: (option: Option[]) => void;
  setCurrentCompany?: (option: Option[]) => void;
  setCurrentTag?: (option: Option[]) => void;
  onServicesCheckChange?: (myServices: boolean) => void;
}

const GridFilters = (props: Props): JSX.Element => {
  const classes = styles();
  const [listCompanies, setListCompanies] = useState<any[]>([]);
  const [listInspectors, setListInspectors] = useState<any[]>([]);
  const [tagList, setTagList] = useState<any[]>([]);
  const { isDesktop } = useDevice();

  const { setCurrentTag, setCurrentCompany, setCurrentInspector } = props;
  const userRolId = localStorage.getItem('rolId');
  const getTagList = useCallback(() => {
    const tagOptions = Array.from(getTags());
    if (setCurrentTag) {
      setCurrentTag(tagOptions);
    }

    return tagOptions;
  }, [setCurrentTag]);

  const getInspectors = useCallback(() => {
    const formattedInspectors = Array.from(INSPECTORS.getDataInspectors());
    if (setCurrentInspector) {
      setCurrentInspector(formattedInspectors);
    }

    return formattedInspectors;
  }, [setCurrentInspector]);

  const getCompanies = useCallback(() => {
    const filteredCompanies = COMPANIES(true, true).filter(
      (f) =>
        (f.type === 'INSURANCE' || f.type === 'INTERNAL') &&
        f.code === localStorage.getItem('countryCode'),
    );

    if (setCurrentCompany) {
      setCurrentCompany(filteredCompanies);
    }

    return filteredCompanies;
  }, [setCurrentCompany]);

  const getCompaniesByRol = useCallback(() => {
    return COMPANIES().filter(
      (f) =>
        (f.type === 'INSURANCE' || f.type === 'INTERNAL') &&
        f.code === localStorage.getItem('countryCode') &&
        f.id === Number(localStorage.getItem('companyId')),
    );
  }, []);

  const myPermissionIsValid = (action: ResourceAction): boolean => {
    return Boolean(userActionsPermissions(action));
  };

  const aapFilterPermission = (): boolean => {
    return (
      localStorage.getItem('countryCode')?.toUpperCase() === CountryCode.CRC &&
      props.aapIndicator !== undefined &&
      userRolId !== Rol.CUSTOMER.toString() &&
      isDesktop
    );
  };

  useEffect(() => {
    if (listInspectors.length === 0) {
      setListInspectors(getInspectors());
    }

    if (listCompanies.length === 0 || props.currentUser.isMultiCountry) {      
      if (userRolId === Rol.INSURER.toString()) {
        setListCompanies(getCompaniesByRol());
      } else {
        setListCompanies(getCompanies());
      }
    }

    if (tagList.length === 0) {
      setTagList(getTagList());
    }
  }, [
    listInspectors.length,    
    listCompanies.length,            
    tagList.length,
    props.currentUser
  ]);

  return (
    <div className="filters">
      <DatePicker
        startDate={props.startDate}
        endDate={props.endDate}
        onChangeStartDate={props.onChangeStartDate}
        onChangeEndDate={props.onChangeEndDate}
        onChangeDateRange={props.onChangeDateRange}
      />

      {!props.showOnlyDatePicker && (
        <>
          {companyFilterPermission(userRolId, props.currentUser.isMultiCountry, myPermissionIsValid) && (
            <Autocomplete
              options={listCompanies}
              getOptionLabel={(option: Option) => option.name}
              sx={{ width: 300 }}
              onChange={(ev, option: Option | null) => {
                if (props.onChangeCompany) {
                  props.onChangeCompany(option);
                }
              }}
              value={props.company}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Compañía"
                  classes={{ root: classes.inputRoot }}
                  sx={{
                    '& fieldset': { border: 'none' },
                  }}
                />
              )}
            />
          )}
          {myPermissionIsValid(ResourceAction.USE_FILTER_INSPECTORS) &&
            isDesktop && (
              <Autocomplete
                options={listInspectors}
                getOptionLabel={(option: Option) => option.name}
                sx={{ width: 300 }}
                onChange={(ev, option: Option | null) => {
                  if (props.onChangeInspector) {
                    props.onChangeInspector(option);
                  }
                }}
                value={props.inspector}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Inspector"
                    classes={{ root: classes.inputRoot }}
                    sx={{
                      '& fieldset': { border: 'none' },
                    }}
                  />
                )}
              />
            )}

          {myPermissionIsValid(ResourceAction.VIEW_TAGS) && isDesktop && (
            <Autocomplete
              options={tagList}
              getOptionLabel={(option: Option) => option.name}
              sx={{ width: 300 }}
              onChange={(ev, option: Option | null) => {
                if (props.onChangeTag) {
                  props.onChangeTag(option);
                }
              }}
              value={props.tag}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Tag"
                  classes={{ root: classes.inputRoot }}
                  sx={{
                    '& fieldset': { border: 'none' },
                  }}
                />
              )}
            />
          )}

          {myPermissionIsValid(ResourceAction.USE_FILTER_MY_SERVICES) &&
            isDesktop && (
              <Grid
                component="label"
                container
                alignItems="center"
                spacing={1}
                style={{ width: 'max-content', margin: '0 10px' }}
              >
                <Grid item>Todos</Grid>
                <Grid item>
                  <Switch
                    checked={props.showMyServices}
                    onChange={(_, checked) => {
                      if (props.onServicesCheckChange) {
                        props.onServicesCheckChange(checked);
                      }
                    }}
                    name="show services"
                  />
                </Grid>
                <Grid item>Mis Servicios</Grid>
              </Grid>
            )}

          {aapFilterPermission() && (
            <FormControlLabel
              value="start"
              control={
                <Switch
                  checked={Boolean(props.aapIndicator)}
                  onChange={() => {
                    if (props.onChangeAapIndicator) {
                      props.onChangeAapIndicator(!props.aapIndicator);
                    }
                  }}
                  color="primary"
                  name="AapFilter"
                />
              }
              label="AAP"
              labelPlacement="start"
            />
          )}
        </>
      )}
    </div>
  );
};

export default GridFilters;
