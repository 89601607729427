import axios from 'axios';
import { API_URL_DOCUMENT_TEMPLATES } from 'shared/api/config';

const APIDocumentTemplates = axios.create({
  baseURL: `${API_URL_DOCUMENT_TEMPLATES}/api`,
  headers: {
    Accept: 'application/json',
  },
  timeout: 120000,
});

APIDocumentTemplates.interceptors.request.use((config) => {
  return config;
});

APIDocumentTemplates.interceptors.response.use(
  (response) => response,
  (error) => {
    // When there is user management logout the user in this point.

    // eslint-disable-next-line no-console
    console.log(error);

    throw error;
  },
);

export default APIDocumentTemplates;
