import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import Loader from 'components/common/loader/Loader';
import FormSteps from 'components/inspection/form-steps';
import { FieldSection } from 'types/section-fields';
import './styles.scss';
import CompaniesTab from './Tabs/CompaniesTab';
import ProvidersTab from './Tabs/ProvidersTab';
import UsersTab from './Tabs/UsersTab';
import { SettingsTab } from 'static/constants/settings-tabs';


const Settings = (): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState(SettingsTab.COMPANIES);

  const elementToScroll = useRef<null | HTMLDivElement>(null);

  const executeScroll = () => {
    if (elementToScroll !== null && elementToScroll.current !== null) {
      elementToScroll.current.scrollIntoView();
    }
  };

  const tabSteps = (): FieldSection[] => {
    const tabs: FieldSection[] = [
      {
        title: 'Aseguradora',
        formSectionId: SettingsTab.COMPANIES,
        idName: 'setting-users',
        idOrder: 0,
        fields: [],
      },
      {
        title: 'Proveedor/Conductor',
        formSectionId: SettingsTab.PROVIDERS,
        idName: 'setting-users',
        idOrder: 1,
        fields: [],
      },
      {
        title: 'SIC/Connect',
        formSectionId: SettingsTab.USERS,
        idName: 'setting-users',
        idOrder: 2,
        fields: [],
      },
    ];

    return tabs;
  };

  const tabClick = (tab: any) => {
    setCurrentTab(tab.formSectionId);
    setLoading(true);
  };

  const renderTab = (tabData: number) => {
    setTimeout(() => setLoading(false), 1500);
    switch (tabData) {
      case SettingsTab.USERS:
        return <UsersTab scrollTrigger={executeScroll} />;
      case SettingsTab.PROVIDERS:
        return <ProvidersTab scrollTrigger={executeScroll} />;
      case SettingsTab.COMPANIES:
        return <CompaniesTab scrollTrigger={executeScroll} />;
      default:
        break;
    }
  };

  return (
    <>
      <div className={clsx('settings')}>
        <h1 className="settings__title">Ajustes</h1>
        <Loader isActive={loading} />
        <>
          <FormSteps
            steps={tabSteps()}
            activeStep={{
              formSectionId: currentTab,
              title: '',
            }}
            onSelectedTab={tabClick}
          />
          <div ref={elementToScroll}></div>
          {renderTab(currentTab)}
        </>
      </div>
    </>
  );
};

export default Settings;
