import { Box, Stack } from '@mui/material';
import Button from '../../components/claims/button'
import { useParams } from 'react-router-dom';
import Loader from 'components/common/loader/Loader';
import EditDoc from 'components/componentDocuments/edit';
import React, { useState } from 'react';

export default function EditDocument(): JSX.Element {
  const { id } = useParams<any>();
  const [loader, setLoader] = useState(false);

  return (
    <>
      <Loader isActive={loader} />
      <EditDoc idTemplate={id} setLoader={setLoader} />
    </>
  );
}
