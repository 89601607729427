import React, { FC } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Grid,
} from '@mui/material';

interface MyDialogProps {
  messageStyle?: React.CSSProperties;
  message: string;
  open: boolean;
  handleClose: () => void;
  handleConfirm?: () => void;
}

const DialogComponent: FC<MyDialogProps> = ({
  messageStyle,
  message,
  open,
  handleClose,
  handleConfirm,
}) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Alerta</DialogTitle>
      <DialogContent>
        <DialogContentText style={messageStyle}>{message}</DialogContentText>
      </DialogContent>
      <Grid style={{ display: 'flex', justifyContent: 'center' }}>
        {handleConfirm && (
          <DialogActions>
            <Button onClick={handleConfirm}>Si</Button>
          </DialogActions>
        )}
        <DialogActions>
          <Button onClick={handleClose}>Cerrar</Button>
        </DialogActions>
      </Grid>
    </Dialog>
  );
};

export default DialogComponent;
