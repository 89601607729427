import React, { useContext, useState } from 'react';
import { TotalLossAdjustDTO } from './adjust';

const initialState: TotalLossAdjustDTO = {
    IsTotalLoss:false,
    ActualCost:'0'
}

interface TotalLossAdjustContextProps {
    tladjust: TotalLossAdjustDTO | null;
    settingTladjust: (newTlAdjust: TotalLossAdjustDTO) => void;
}

const TotalLossAdjustContext = React.createContext<TotalLossAdjustContextProps | undefined>(undefined);

const TotalLossAdjustProvider: React.FC<any> = ({ children }) => {
    const [tladjust, setTladjust] = useState<TotalLossAdjustDTO>(initialState);

    const settingTladjust = (newTlAdjust : TotalLossAdjustDTO) =>{
        setTladjust(newTlAdjust);
    }


    return (
        <TotalLossAdjustContext.Provider value={{ tladjust, settingTladjust }}>
            {children}
        </TotalLossAdjustContext.Provider>
    );
};

const useTotalLossAdjust = (): TotalLossAdjustContextProps => {
    const context = useContext(TotalLossAdjustContext);
    if (!context) {
      throw new Error('useTotalLossAdjust must be used within a UserProvider');
    }

    return context;
  };

export { TotalLossAdjustProvider, useTotalLossAdjust };