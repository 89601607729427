import {
  Box,
  Tab,
  Stack,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { SelectOption } from 'types/select-option-inspection';
import CompleteStage from '../../../assets/icons/check-greem.svg';
import InProcessStage from '../../../assets/icons/circle-step-inprogress.svg';
import DefaultStage from '../../../assets/icons/circle_step.svg';
import ErrorStage from '../../../assets/icons/circle-step-error.svg';
import PendingStage from '../../../assets/icons/circle-step-pending.svg';
import {
  IClaimForm,
  CurrentStatusText,
  isInsurerCR,
  StepStatus,
} from 'types/claimsTracker';
import './index.scss';
import ClaimsTrackerClient from 'shared/clients/ClaimsTrackerClient';
import { IUserContext, UserContext } from 'context/user';
import { useParams } from 'react-router';
import { ApprovalDashboard, ApprovalResponse } from 'views/Approval/approval';
import ButtonClaims from '../button';
import { IInspectionContext, InspectionContext } from 'context/inspections';
import { ActionType } from 'types/action';
import { CountryCode } from 'types/common';

interface ClaimStepsProps {
  steps: SelectOption[];
  currentClaimForm?: IClaimForm;
  onChangeTab: (sectionId: number) => void;
  defaultStep: string;
}

const ClaimSteps: FunctionComponent<ClaimStepsProps> = ({
  steps,
  currentClaimForm,
  onChangeTab,
  defaultStep,
}): JSX.Element => {
  const [value, setValue] = useState<string>('');
  const {
    state: { currentUser },
  } = useContext(UserContext) as IUserContext;
  const { id } = useParams<any>();
  const [approvals, setApprovals] = useState<ApprovalDashboard[]>([]);
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [approvalResponse, setAprovalResponse] = useState<ApprovalResponse>();
  const { dispatch } = useContext<IInspectionContext>(InspectionContext);
  const [rejectReason, setRejectReason] = useState<string>('');
  const [stepId, setStepId] = useState<number | null>(null);
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    getApprovalsByClaimAndUser(String(id), currentUser.userName ?? '');

    const currentStep = sessionStorage.getItem('currentStep') ?? '';
    if (currentStep.length > 0) {
      sessionStorage.setItem('currentStep', '');
    }
    setValue(defaultStep);
  }, [defaultStep]);

  const getApprovalsByClaimAndUser = async (
    claimUId: string,
    user: string,
  ): Promise<any> => {
    try {
      const result = await new ClaimsTrackerClient().getApprovalDashboardByClaimAndUser(
        claimUId,
        user,
      );

      setApprovals(result);

      return result;
    } catch (e) {
      console.error(e);
    }
  };

  const getIconState = (status: string | undefined): JSX.Element => {
    switch (status?.toUpperCase()) {
      case 'COMPLETED':
        return <img src={CompleteStage} alt="Complete Stage" />;
      case 'IN_PROCESS':
        return <img src={InProcessStage} alt="In-Process Stage" />;
      case 'CANCELLED':
        return <img src={ErrorStage} alt="Error Stage" />;
      case 'PENDING':
        return <img src={PendingStage} alt="Pending Stage" />;
      default:
        return <img src={DefaultStage} alt="Default Stage" />;
    }
  };

  const handleApprovation = async (): Promise<any> => {
    try {
      setOpenConfirmation(false);
      dispatch({ type: ActionType.SET_LOADING, payload: true });

      if (approvalResponse) {
        const result = await new ClaimsTrackerClient().respondApprovalRequest(
          approvalResponse.approvalStepId,
          approvalResponse.IsApproved,
          rejectReason,
        );

        setRejectReason('');
        dispatch({ type: ActionType.SET_LOADING, payload: false });

        setApprovals([]);

        return result;
      }
    } catch (e) {
      dispatch({ type: ActionType.SET_LOADING, payload: false });
      console.error(e);
    }
  };

  useEffect(() => {
    if (steps.some((step) => step.isInvalid)) {
      dispatch({
        type: ActionType.SET_GENERAL_ALERT,
        payload: {
          showAlert: true,
          alertMessage: 'Debes completar los campos requeridos del formulario.',
          alertType: 'error',
        },
      });
    }
  }, [stepId, steps]);

  const validateForm = () => {
    steps.forEach((step) => {
      let stepRequired = false;
      step.sections.forEach((section) => {
        section.containers.forEach((container) => {
          container.fields.some((field) => {
            const { constraints, defaultValue } = field;
            if (
              constraints &&
              constraints.isRequired &&
              (!defaultValue || defaultValue === '')
            ) {
              return (stepRequired = true);
            }

            return false;
          });
        });
        section.required = stepRequired;
      });
      step.required = stepRequired;
    });
  };

  const isFinishedInsurerCR = () => {
    return Boolean(
      isInsurerCR() &&
        currentClaimForm?.info.currentStepStatus === StepStatus.PENDING_AUDIT,
    );
  };

  return (
    <Box className="claim-steps">
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent="space-between"
        spacing={2}
        alignItems={{ xs: 'flex-start', sm: 'center' }}
      >
        <div className="claim-steps__boxlabel">
          <label>Pasos</label>
        </div>
        {approvals.length > 0 ? (
          <div className="claim-steps__approvalbuttons">
            <Button
              onClick={() => {
                setAprovalResponse({
                  approvalStepId: approvals[0].id,
                  IsApproved: true,
                });
                setOpenConfirmation(true);
              }}
            >
              Aprobar
            </Button>
            <Button
              onClick={() => {
                setAprovalResponse({
                  approvalStepId: approvals[0].id,
                  IsApproved: false,
                });
                setOpenConfirmation(true);
              }}
            >
              Rechazar
            </Button>
          </div>
        ) : null}
        <div className="claim-steps__current-state">
          <label>
            Estado actual:{' '}
            {isFinishedInsurerCR()
              ? 'Caso Finalizado'
              : CurrentStatusText[currentClaimForm?.info.currentStepStatus]}
          </label>
        </div>
      </Stack>
      {(currentClaimForm?.info?.siseReport ||
        currentClaimForm?.info?.siseSiniestro) &&
        [CountryCode.CRC].includes(currentClaimForm?.info?.countryCode) && (
          <Box display={'flex'} justifyContent={'end'}>
            <div>
              <p>
                SISE Reporte:{' '}
                {currentClaimForm?.info?.siseReport
                  ? currentClaimForm?.info?.siseReport
                  : 'No definido'}
              </p>
              <p>
                SISE Siniestro:{' '}
                {currentClaimForm?.info?.siseSiniestro
                  ? currentClaimForm?.info?.siseSiniestro
                  : 'No definido'}
              </p>
            </div>
          </Box>
        )}
      <Box className="claim-steps__container">
        {value !== '' && (
          <TabContext value={value}>
            <TabList
              variant="scrollable"
              scrollButtons="auto"
              onChange={handleChange}
              TabIndicatorProps={{ style: { display: 'none' } }}
            >
              {steps?.map((step: any) => {
                const { status, constraints, id, isInvalid } = step;
                const stepClasses = `claim-steps__step ${
                  isInvalid ? 'claim-steps__step--required' : ''
                }`;

                return (
                  constraints.isVisible && (
                    <Tab
                      key={id}
                      iconPosition="start"
                      icon={getIconState(status)}
                      className={stepClasses}
                      onClick={() => {
                        onChangeTab(step.id);
                        setStepId(step.id);
                      }}
                      label={step.title}
                      disabled={step.status === 'DISABLED'}
                      value={step?.id.toString()}
                    />
                  )
                );
              })}
            </TabList>
            {steps?.map((step) => {
              return (
                <TabPanel
                  key={step.id}
                  value={JSON.stringify(step.id)}
                ></TabPanel>
              );
            })}
          </TabContext>
        )}
      </Box>

      <Dialog open={openConfirmation}>
        <DialogTitle id="confirm-restore-title">Confirmar</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="confirm-payment-modal"
            style={{ width: '400px', height: '120px' }}
          >
            <br />
            ¿Quiere {approvalResponse?.IsApproved ? 'aprobar' : 'rechazar'} el
            valor de ajuste del caso?
            <br />
            {!approvalResponse?.IsApproved ? (
              <TextField
                style={{ marginTop: '20px', width: '100%' }}
                name="customerResponsabilityAmount"
                className="claim-adjust__data--row--input"
                label="Describa la razón"
                onChange={(e) => {
                  setRejectReason(e.target.value);
                }}
              ></TextField>
            ) : null}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ButtonClaims
            text="Cancelar"
            variant="outlined"
            color="secondary"
            onClick={() => setOpenConfirmation(false)}
          />
          <ButtonClaims
            text="Sí, confirmar"
            variant="contained"
            color="primary"
            onClick={handleApprovation}
          />
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ClaimSteps;
