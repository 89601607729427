import './styles.scss';
import React, { useContext, useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Card, CardContent } from '@mui/material';
import { NotificationsSharp } from '@mui/icons-material';
import { addDays } from 'date-fns';
import {
  IFiltersContext,
  FiltersContext,
} from 'context/filters/FiltersContext';
import {
  IPreInspectionContext,
  PreInspectionContext,
  getPreInspections,
  setLoading,
} from 'context/preinspections';
import { IUserContext, UserContext } from 'context/user';
import Loader from 'components/common/loader/Loader';
import StatusCard from 'components/events/status-card';
import GridFilters from 'components/pre-inspection/filters/GridFilters';
import EventsTable from 'components/pre-inspection/table/EventsTable';
import { EventStatus } from 'types/event-status';
import { EVENT_STATUSES } from 'static/constants/event-status';
import { ActionType } from 'types/action';
import { Option } from 'types/option';
import { ResourceAction } from 'types/common';
import { userActionsPermissions } from 'shared/utils';
import { IAppFormType } from 'types/user';
import useDevice from 'shared/hooks/useDevice';
import SearchError from 'components/common/search-error/SearchError';
import HeaderContext from 'context/header/header-context';

interface StatusIndicator {
  statusName: string;
  currentRows: number;
}

export default function PreInspections(): JSX.Element {
  const history = useHistory();
  //Context
  const { state: preInspectionState, dispatch } = useContext<
    IPreInspectionContext
  >(PreInspectionContext);
  const { queryFilters, searchQuery, setQueryFilters } = useContext<
    IFiltersContext
  >(FiltersContext);
  const {
    state: { currentUser, loading: userLoading},
    dispatch: userDispatch,
  } = useContext(UserContext) as IUserContext;
  const { setPropContentTitle } = useContext(HeaderContext);
  // States for filters
  const [currentStatus, setCurrentStatus] = useState<number | undefined>(
    queryFilters.statusId,
  );
  const [alertFilter, setAlertFilter] = React.useState<
    boolean | null | undefined
  >(Boolean(queryFilters?.filterByAlert));
  const [
    selectedStatusFilter,
    setSelectedStatusFilter,
  ] = useState<EventStatus | null>(null);
  const [company, setCompany] = React.useState<Option | null>(null);
  const [agency, setAgency] = React.useState<Option | null>(null);
  const { isMobile } = useDevice();

  const countryCode = localStorage.getItem('countryCode'); //To use as a trigger to fetch pre-inspections whenever the value changes.
  const runnerCode = localStorage.getItem('runnerCode');
  
  useEffect((): void => {
    (async (): Promise<void> => { 
      dispatch({ type: ActionType.SET_LOADING, payload: true });
      await getPreInspections({ ...queryFilters }, dispatch);
      dispatch({
        type: ActionType.SET_GRID_REQUEST_PARAMS,
        payload: {
          ...queryFilters,
        },
      });
    })();
  }, [queryFilters, dispatch, countryCode,  runnerCode]);

  useEffect(() => {
    dispatch({ type: ActionType.SET_LOADING, payload: true });
    userDispatch({
      type: ActionType.SET_APP_FORM_TYPE,
      payload: IAppFormType.PRE_INSPECTION,
    });
  }, [dispatch, userDispatch]);

  useEffect(() => {    
      setPropContentTitle('DASHBOARD DE INSPECCIONES')    
  }, [])

  if (searchQuery || searchQuery === null) {
    return (
      <Redirect
        to={{ pathname: '/preinspections', search: searchQuery || '' }}
      />
    );
  }

  const handleEventView = (row: any, partnerKey: string, preId: number) => {
    row.stopPropagation();
    if (
      !row.target.classList.contains('MuiButtonBase-root') &&
      !row.target.classList.contains('MuiSvgIcon-root') &&
      !row.target.classList.contains('MuiBackdrop-root') &&
      row.target.classList?.length > 0
    ) {
      history.push(`/preinspections/${partnerKey}/${preId}`);
    }
  };

  const getAvailableFilterStatus = () => {
    const filterPermission = userActionsPermissions(
      ResourceAction.USE_PREINSPECT_FILTERS,
    );
    if (filterPermission) {
      const newStatus: any = [];
      Array.from(filterPermission).forEach((p: any) => {
        const status = Object.values(EVENT_STATUSES).filter(
          (f) => f.id === +p,
        )[0];
        newStatus.push(status);
      });

      return newStatus;
    } else {
      return Object.values(EVENT_STATUSES);
    }
  };

  const getStatusIndicatorAmount = (
    id?: number,
    statusName?: string,
  ): number => {
    const status =
      statusName ||
      getAvailableFilterStatus().find((e: any) => e.id === id)?.value;
    if (id || statusName) {
      const _currentStatus = preInspectionState.statusesIndicators.find(
        (indicator: StatusIndicator) => indicator.statusName === status,
      );

      return _currentStatus ? _currentStatus.currentRows : 0;
    }

    return 0;
  };

  const title = `Hola ${currentUser.name}`;
  const indicators =
    preInspectionState.statusesIndicators === undefined
      ? []
      : preInspectionState.statusesIndicators.filter((i) =>
          ['Creado', 'Completado'].includes(i.statusName),
        );
  let assignedEvents = 0;
  for (const indicator of indicators) {
    assignedEvents += Number(indicator.currentRows);
  }

  return (
    <>
      <Loader isActive={preInspectionState.loading || userLoading} />

      <div className="eventStatuses">
        {!isMobile && (
          <StatusCard
            className="eventStatuses__card--notif eventStatuses__card--md"
            subtitle="Reciente"
            title={title}
            description={`Tienes ${assignedEvents} pendientes`}
          />
        )}

        <StatusCard
          className="eventStatuses__card eventStatuses__card--small statusCard--alerts"
          status={
            {
              label: 'Alertas',
              icon: <NotificationsSharp />,
            } as any
          }
          amount={getStatusIndicatorAmount(undefined, 'Alertas')}
          selected={Boolean(alertFilter)}
          onSelectStatus={() => {
            setLoading(true, dispatch);
            setQueryFilters({
              page: 0,
              filterByAlert: !alertFilter,
              statusId: undefined,
            });
            setAlertFilter(!alertFilter);
          }}
        />

        {getAvailableFilterStatus().map((status: any) => (
          <StatusCard
            className="eventStatuses__card eventStatuses__card--small"
            key={status.value}
            status={status}
            amount={getStatusIndicatorAmount(status.id)}
            selected={status.id === currentStatus}
            onSelectStatus={(value) => {
              setLoading(true, dispatch);
              const newValue =
                value === selectedStatusFilter ? undefined : value;
              setCurrentStatus(newValue?.id);
              setQueryFilters({
                statusId: newValue?.id,
                page: 0,
                filterByExpressAdjust: undefined,
              });
              setSelectedStatusFilter(newValue || null);
            }}
          />
        ))}
      </div>

      <Card className="gridCard">
        <CardContent className="card-content">
          <h2 className={'gridCard__title'}>Actividad</h2>
          <GridFilters
            company={company}
            agency={agency}
            startDate={
              queryFilters?.initialDate
                ? new Date(queryFilters?.initialDate)
                : null
            }
            endDate={
              queryFilters?.finalDate ? new Date(queryFilters?.finalDate) : null
            }
            onChangeStartDate={(date) => {
              setLoading(true, dispatch);
              setQueryFilters({ page: 0, initialDate: date || undefined });
            }}
            onChangeEndDate={(date) => {
              setLoading(true, dispatch);
              setQueryFilters({
                page: 0,
                finalDate: !date ? undefined : addDays(date, 1),
              });
            }}
            onChangeDateRange={([start, end]) => {
              setLoading(true, dispatch);
              setQueryFilters({
                page: 0,
                initialDate: start,
                finalDate: end,
              });
            }}
            onChangeCompany={(option) => {
              setLoading(true, dispatch);
              setQueryFilters({ page: 0, companyId: option?.id });
              setCompany(option);
            }}
            setCurrentCompany={(companies) => {
              const option =
                companies.find((c) => c.id === queryFilters.companyId) || null;
              setCompany(option);
            }}
            onChangeAgency={(option) => {
              setLoading(true, dispatch);
              setQueryFilters({ page: 0, agencyId: option?.id });
              setAgency(option);
            }}
            setCurrentAgency={(agencies) => {
              const option =
                agencies.find((c) => c.id === queryFilters.agencyId) || null;
              setAgency(option);
            }}
            currentUser={currentUser}
          />
          {preInspectionState.preInspections &&
            preInspectionState.preInspections.length > 0 && (
              <EventsTable
                preInspections={preInspectionState.preInspections}
                totalElements={getStatusIndicatorAmount(
                  currentStatus,
                  undefined,
                )}
                page={queryFilters.page || 0}
                onClickEditView={handleEventView}
                //onClickNote={handleNoteClicked}
                onChangePage={(event: unknown, newPage: number) =>
                  setQueryFilters({ page: newPage })
                }
                reloadEvents={() =>
                  getPreInspections({ ...queryFilters }, dispatch)
                }
              />
            )}
          {!preInspectionState.loading &&
            preInspectionState.preInspections &&
            preInspectionState.preInspections.length === 0 && <SearchError />}
        </CardContent>
      </Card>
    </>
  );
}
