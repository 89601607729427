import { generateConsolidate, InspectionContext } from 'context/inspections';
import { Grid, Button, TextField } from '@mui/material';
import DatePicker from 'components/common/datepicker/DatePicker';
import React, { useContext, useState } from 'react';
import { ActionType } from 'types/action';
import { format } from 'date-fns';
import style from 'override-mui-styles';
import clsx from 'clsx';
import './styles.scss';

export default function DownloadFile(): JSX.Element {
    const classes = style();
    const [startDate, setStartDate] = useState<Date | null>(
    new Date(format(new Date(), 'MM/dd/yyyy')),
    );
    const [endDate, setEndDate] = useState<Date | null>(
    new Date(format(new Date(), 'MM/dd/yyyy')),
    );
    const [email, setEmail] = useState('')

    const { dispatch } = useContext(InspectionContext);

    const onChangeStartDate = (date: Date | null) => {
        setStartDate(date);
    };

    const onChangeEndDate = (date: Date | null) => {
        setEndDate(date);
    };

    const generateReport = () => {
        if (startDate && endDate && email) {
          const companyId = localStorage.getItem('companyId') ?? '-1';
          (async (): Promise<void> => {
            const request = {
              countryCode: localStorage.getItem('countryCode'),
              beginDate: format(startDate, 'yyyy-MM-dd') + ' 00:00:00',
              endDate: format(endDate, 'yyyy-MM-dd') + ' 23:59:59',
              email: email,
              companyId: Number(companyId),
              eventStateId: 3,
            };
            const response = await generateConsolidate(request, dispatch);
            if (response === 'fail')
            {
              dispatch({
                type: ActionType.SET_GENERAL_ALERT,
                payload: {
                  showAlert: true,
                  alertMessage: 'No hay datos que cumplan con el criterio de búsqueda.',
                  alertType: 'error',
                },
              });
            }
            else
            {
              dispatch({
                type: ActionType.SET_GENERAL_ALERT,
                payload: {
                  showAlert: true,
                  alertMessage: 'Se ha enviado un correo con las instrucciones para la descarga del archivo.',
                  alertType: 'success',
                },
              });
            }
          })();
        }
      };

    return (
      <>
            <div className="reportForm">
              <Grid container spacing={2}>
              <Grid item xs={12}>
              </Grid>
                <Grid item xs={12} sm={5} md={3} lg={3}>
                  <label className="reportForm__label">Fecha Desde:</label>
                  <DatePicker
                    startDate={startDate}
                    onChangeStartDate={(date) => onChangeStartDate(date)}
                  />
                </Grid>
                <Grid item xs={12} sm={5} md={3} lg={3}>
                  <label className="reportForm__label">Fecha Hasta:</label>
                  <DatePicker
                    startDate={endDate}
                    onChangeStartDate={(date) => onChangeEndDate(date)}
                  />
                </Grid>
                <Grid item xs={12} sm={5} md={3} lg={4}>
                  <label className="reportForm__label">Correo Electrónico:</label>
                  <TextField
                    key='email'
                    className={clsx('inspectionForm__input')}
                    name='email'
                    defaultValue=''
                    variant="outlined"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    />
                </Grid>
                <Grid
                  className="generateSection"
                  item
                  xs={12}
                  sm={1}
                  md={3}
                  lg={2}
                >
                    <Button
                  id="continue-btn"
                  variant="contained"
                  className={clsx(classes.button)}
                  size="large"
                  onClick={() => generateReport()}
                >
                  Generar Archivo
                </Button>
                </Grid>
              </Grid>
            </div>
      </>
    );
  }