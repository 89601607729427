import { Box, Divider, Stack } from '@mui/material';
import { IInspectionContext, InspectionContext } from 'context/inspections';
import dayjs from 'dayjs';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { maskValue } from 'shared/utils';
import CATALOGS from 'static/constants/catalogs';
import { BrandsId, CountryCode, Rol } from 'types/common';
import './index.scss';

interface ClaimStepsProps {
  claim: any;
  caption?: string;
  information?: string;
}

const URL_HELIOS = process.env.REACT_APP_FE_HELIOS_URL ?? '';

const General: FunctionComponent<ClaimStepsProps> = ({
  claim,
  caption,
  information,
}): JSX.Element => {
  const heliosURIRef = URL_HELIOS + '/#/service/' + claim.poNumber;
  const {
    state: { claimsManagementInfo },
  } = useContext<IInspectionContext>(InspectionContext);
  const [isLoading, setIsLoading] = useState(true);

  const formatDate = (date: string): string => {
    const formattedDate = dayjs(date)
      .locale('es')
      .format(CATALOGS.getCountryFormat().dateTimeFormat + ' HH:mm:ss')
      .toUpperCase();

    return formattedDate;
  };

  useEffect(() => {
    if (claimsManagementInfo) {
      setIsLoading(false);
    }
  }, [claimsManagementInfo]);

  if (isLoading) {
    return <div>Loading...</div>; // Puedes reemplazar esto con un spinner o cualquier indicador de carga
  }

  return (
    <Box>
      <div className={caption}>Caso reclamante: {claim.case}</div>
      <Box sx={{ paddingTop: '1rem' }}>
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          spacing={{ xs: 1, sm: 2, md: 4 }}
        >
          <Box className={information}>
            <div>
              <label>Tipo Reclamación:</label>
              <span>{claim.type}</span>
            </div>
            {claim.sinisterType && (
              <div>
                <label>Tipo Siniestro:</label>
                <span>{claim.sinisterType}</span>
              </div>
            )}
            <div>
              <label>PO number:</label>
              <span>#{claim.poNumber}</span>
            </div>
            <div>
              <label>Enlaces:</label>
              <span>
                {![Rol.CUSTOMER, Rol.INSURER, Rol.INSPECTOR].includes(
                  Number(localStorage.getItem('rolId')),
                ) && (
                  <a href={heliosURIRef} rel="noreferrer" target="_blank">
                    Helios
                  </a>
                )}

                {claim?.useCTFA &&
                  ![Rol.CUSTOMER, Rol.INSPECTOR].includes(
                    Number(localStorage.getItem('rolId')),
                  ) && (
                    <>
                      {' '}
                      |{' '}
                      <a
                        href={claim.urlClaimTracker}
                        rel="noreferrer"
                        target="_blank"
                      >
                        Claim Tracker
                      </a>
                    </>
                  )}
              </span>
            </div>
          </Box>
          <Divider orientation="vertical" flexItem></Divider>
          {![CountryCode.PAN, CountryCode.CRC].includes(
            localStorage.getItem('countryCode') as CountryCode,
          ) &&
            (BrandsId.OPTIMA_SEGUROS !==
              claimsManagementInfo?.jsonFieldData?.event.CompanyId ??
              0) && (
              <Box className={information}>
                <div>
                  <label>Póliza:</label>
                  <ul>
                    {BrandsId.CSM ===
                      claimsManagementInfo?.jsonFieldData?.event.CompanyId ??
                    0 ? (
                      <li>Tipo de Póliza: {claim.policyTypeEvent}</li>
                    ) : (
                      <>
                        <li>
                          Clasificación de Póliza:{' '}
                          {claim.policy?.policyClassification}
                        </li>
                        <li>Nombre completo: {claim.policy?.fullName}</li>
                        <li>Teléfono: {claim.policy?.customerPhone}</li>
                        <li>Email: {claim.policy?.customerEmail}</li>
                        <li>Banco: {claim.policy?.bank}</li>
                        <li>Endoso: {claim.policy?.endorsement}</li>
                        <li>
                          Suma asegurada: $
                          {maskValue(
                            parseFloat(claim.policy?.totalInsured),
                            '###,###.00',
                            '#',
                            false,
                          )}
                        </li>
                        <li>Agente/Broker: {claim.policy?.hasBroker}</li>
                        <li>Funded: {claim.policy?.hasFunded}</li>
                        <li>Gap: {claim.policy?.hasGap}</li>
                        {claim.policyTypeEvent && (
                          <li>Tipo de Póliza: {claim.policyTypeEvent}</li>
                        )}
                        {claim.policy?.dealerName && (
                          <li>Dealer Name: {claim.policy?.dealerName}</li>
                        )}
                        <li>
                          Fecha efectiva:{' '}
                          {formatDate(claim.policy?.effectiveDate).substring(
                            0,
                            10,
                          )}
                        </li>
                        <li>
                          Fecha expiración:{' '}
                          {formatDate(claim.policy?.expirationDate).substring(
                            0,
                            10,
                          )}
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </Box>
            )}
        </Stack>
      </Box>
    </Box>
  );
};

export default General;
