import './index.scss';
import React, { useContext } from 'react';
import { FunctionComponent, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Field } from 'formik';
import { set } from 'lodash';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Skeleton from '@mui/material/Skeleton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import {
  AgreementTypes,
  IClaim,
  IClaimForm,
  IWorkshopInfo,
  Payments,
  ReceiveCheckType,
} from 'types/claimsTracker';
import { IInspectionContext, InspectionContext } from 'context/inspections';
import Signature from 'components/common/signature';
import ClaimsTrackerClient from 'shared/clients/ClaimsTrackerClient';
import CATALOGS from 'static/constants/catalogs';
import Button from '../button';
import { ActionType } from 'types/action';
import { formatNumberPoint } from 'shared/utils/';

interface Props {
  claimUId: string;
  claimForm?: IClaimForm;
  disabled: boolean;
  disableFinishActionState: (status: boolean) => void;
  legacyCurrentClaim?: IClaim;
}

const AgreementDetail: FunctionComponent<Props> = ({
  claimUId,
  claimForm,
  disabled,
  disableFinishActionState,
  legacyCurrentClaim,
}): JSX.Element => {
  const { state: claimState, dispatch } = useContext<IInspectionContext>(
    InspectionContext,
  );
  const history = useHistory();

  const [editByAnalyst, setEditByAnalyst] = useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [openConfirmEdit, setOpenConfirmEdit] = useState<boolean>(false);
  const [openConfirmBySupport, setOpenConfirmBySupport] = useState<boolean>(
    false,
  );
  const [agreementType, setAgreementType] = useState<string>('');
  const [selectedDate, setSelectedDate] = useState<string>('');
  const [paymentDate, setPaymentDate] = useState<string>('');
  const [repairDate, setRepairDate] = useState<string>('');
  const [selectWorkshop, setSelectWorkshop] = useState<string>('');
  const [selectAccountType, setSelectAccountType] = useState<string>(
    claimForm?.info?.paymentDetail?.accountType ?? '',
  );
  const [agreementFields, setAgreementFields] = useState<any>({});
  const [totalInsuranceAmount, setTotalInsuranceAmount] = useState<string>('');

  const getAgreementType = (claimForm: IClaimForm): void => {
    if (!claimForm || !claimForm?.info?.paymentDetail) {
      setAgreementType('');
    } else {
      localStorage.setItem(
        'agreementType',
        claimForm.info?.paymentDetail?.agreementType ?? '',
      );
      if (
        claimForm.info?.paymentDetail.agreementType ===
        AgreementTypes.REPAIR_CONTINUE
      ) {
        setAgreementType(AgreementTypes.REPAIR_CONTINUE);
      }

      if (
        claimForm.info?.paymentDetail?.agreementType ===
        AgreementTypes.RECEIVE_PAYMENT
      ) {
        if (claimForm.info?.paymentDetail?.paymentType === Payments.CHECK) {
          if (
            claimForm.info?.paymentDetail?.checkType ===
            ReceiveCheckType.POST_MAIL
          ) {
            setAgreementType(ReceiveCheckType.POST_MAIL);
          }

          if (
            claimForm.info?.paymentDetail?.checkType ===
            ReceiveCheckType.PICKUP_OFFICE
          ) {
            setAgreementType(ReceiveCheckType.PICKUP_OFFICE);
          }
        } else if (
          claimForm.info?.paymentDetail?.paymentType === Payments.ACH
        ) {
          setAgreementType(Payments.ACH);
        }
      }
    }
  };

  const handleDates = (claimForm: IClaimForm): void => {
    if (claimForm.info?.paymentDetail?.paymentDate) {
      setPaymentDate(
        dayjs(claimForm.info?.paymentDetail?.paymentDate).format('DD/MM/YYYY'),
      );
      disableFinishActionState(true);
    }

    if (claimForm.info?.paymentDetail?.repairDate) {
      setRepairDate(
        dayjs(claimForm.info?.paymentDetail?.repairDate).format('DD/MM/YYYY'),
      );
    }

    if (
      !claimForm.info?.paymentDetail?.paymentDate ||
      !claimForm.info?.paymentDetail?.repairDate
    ) {
      disableFinishActionState(true);
    }
  };

  const handleAgreementTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setAgreementType(event.target.value);
    setAgreementFields({});
    set(agreementFields, event.target.name, event.target.value);
    setAgreementFields(agreementFields);
  };

  const handleWorkShopChange = (event: SelectChangeEvent) => {
    if (event.target.value) {
      set(agreementFields, 'custom_workshop', event.target.value);
      setAgreementFields(agreementFields);
      setSelectWorkshop(event.target.value);
    }
  };

  const handleInputChangeBySupport = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    set(agreementFields, event.target.name, event.target.value);
    setAgreementFields(agreementFields);
  };

  const handleAccountTypeChange = (event: SelectChangeEvent) => {
    if (event.target.value) {
      set(agreementFields, 'custom_accountType', event.target.value);
      setAgreementFields(agreementFields);
      setSelectAccountType(event.target.value);
    }
  };

  const confirmAgreement = (): void => {
    (async (): Promise<void> => {
      setOpenConfirm(false);
      dispatch({ type: ActionType.SET_LOADING, payload: true });
      await submitFinalizeAgreement(
        claimForm?.info?.paymentDetail?.agreementType as AgreementTypes,
      );
    })();
  };

  const confirmAgreementBySupport = (): void => {
    (async (): Promise<void> => {
      const agrmntType = agreementFields['custom_radio-buttons-group'];
      const payload = {
        agreementType: agrmntType,
        workshop:
          agrmntType === 'CONTINUE_REPAIR'
            ? agreementFields['custom_workshop']
            : null,
        urban:
          agrmntType === 'POST_MAIL'
            ? agreementFields['custom_urbanization']
            : null,
        town:
          agrmntType === 'POST_MAIL' ? agreementFields['custom_town'] : null,
        postalCode:
          agrmntType === 'POST_MAIL'
            ? agreementFields['custom_postalCode']
            : null,
        street:
          agrmntType === 'POST_MAIL'
            ? agreementFields['custom_detailedAddress']
            : null,
        office:
          agrmntType === 'PICKUP_OFFICE'
            ? agreementFields['custom_office']
            : null,
        claimentName:
          agrmntType === 'ACH' ? agreementFields['custom_claimentName'] : null,
        phone: agrmntType === 'ACH' ? agreementFields['custom_phone'] : null,
        routeNumber:
          agrmntType === 'ACH' ? agreementFields['custom_routeNumber'] : null,
        nameBank:
          agrmntType === 'ACH' ? agreementFields['custom_nameBank'] : null,
        accountNumber:
          agrmntType === 'ACH' ? agreementFields['custom_accountNumber'] : null,
        accountType:
          agrmntType === 'ACH'
            ? String(agreementFields['custom_accountType'])?.toUpperCase()
            : null,
        userId: Number(localStorage.getItem('userId')),
        eventId: claimForm?.info?.sicId,
      };

      setOpenConfirmBySupport(false);
      dispatch({ type: ActionType.SET_LOADING, payload: true });
      await submitUpdateAgreementBySupport(payload);
    })();
  };

  const confirmEdit = (): void => {
    setOpenConfirmEdit(false);
    setEditByAnalyst(true);
    disableFinishActionState(true);
  };

  const submitFinalizeAgreement = async (
    type: AgreementTypes,
  ): Promise<void> => {
    if (type.length > 0 && selectedDate.length > 0) {
      await new ClaimsTrackerClient().finalizeAgreement(
        claimUId,
        type,
        Number(localStorage.getItem('userId')),
        claimForm?.info?.sicId,
        selectedDate,
      );

      // Re-rendering page
      history.go(0);
    }
  };

  const submitUpdateAgreementBySupport = async (
    updateObj: any,
  ): Promise<void> => {
    if (updateObj) {
      await new ClaimsTrackerClient().updateAgreementBySupport(
        claimUId,
        updateObj,
      );

      // Re-rendering page
      history.go(0);
    }
  };
  const getAdjustAmount = async (): Promise<void> => {
    try {
      dispatch({
        type: ActionType.SET_ADJUST_AMOUNT,
        payload: undefined,
      });
      const result = await new ClaimsTrackerClient().getAllAdjustAmount(
        claimUId,
        claimForm?.info?.totalLoss?.isApply,
      );
      if (result?.length > 0) {
        const data = result.slice(-1)[0];
        setTotalInsuranceAmount(
          formatNumberPoint(
            claimForm?.info?.totalLoss?.isApply
              ? data.totalToPay.toString()
              : data.totalInsuranceAmount,
          ),
        );
      }
    } catch (e) {
      console.error(e);
    }
  };
  const skeletonLoader = (): JSX.Element => {
    return (
      <>
        <br />
        <Skeleton variant="rectangular" height={450} color="#7d92a1" />
      </>
    );
  };
  useEffect(() => {
    getAdjustAmount().catch(console.error);
  }, []);
  useEffect(() => {
    getAdjustAmount().catch(console.error);
  }, [claimForm?.info?.case]);
  useEffect(() => {
    if (claimForm) {
      getAgreementType(claimForm);
      handleDates(claimForm);
    }
  }, [claimForm]);

  return (
    <>
      <Box className="agreement-detail">
        {!claimForm
          ? skeletonLoader()
          : (editByAnalyst ||
              (agreementType?.length > 0 &&
                !legacyCurrentClaim?.closedWithoutPayment)) && (
              <Box>
                <TextField
                  name="estimatedTotalAmount"
                  className="claim-adjust__data--row--input"
                  label="Monto total asegurado"
                  value={totalInsuranceAmount}
                  disabled
                ></TextField>
                <br />
                <p className="agreement-detail__data--title">Tipo de Acuerdo</p>
                <RadioGroup
                  row
                  defaultValue={agreementType}
                  name="custom_radio-buttons-group"
                  onChange={handleAgreementTypeChange}
                >
                  <FormControlLabel
                    value={AgreementTypes.REPAIR_CONTINUE}
                    control={<Radio />}
                    label="Reparación Taller"
                    disabled={!editByAnalyst}
                  />
                  <FormControlLabel
                    value={ReceiveCheckType.POST_MAIL}
                    control={<Radio />}
                    label="Pago enviado por correo postal"
                    disabled={!editByAnalyst}
                  />
                  <FormControlLabel
                    value={ReceiveCheckType.PICKUP_OFFICE}
                    control={<Radio />}
                    label="Pago cheque oficina"
                    disabled={!editByAnalyst}
                  />
                  <FormControlLabel
                    value={Payments.ACH}
                    control={<Radio />}
                    label="Pago ACH"
                    disabled={!editByAnalyst}
                  />
                </RadioGroup>
              </Box>
            )}

        {agreementType === AgreementTypes.REPAIR_CONTINUE &&
          !legacyCurrentClaim?.closedWithoutPayment && (
            <Box>
              {editByAnalyst && (
                <Box>
                  <br />
                  <FormControl
                    sx={{ minWidth: 235, backgroundColor: '#ffffff' }}
                  >
                    <InputLabel id="workshopLbl">Seleccione Taller</InputLabel>
                    <Select
                      labelId="workshopLbl"
                      id="slct-WorkshopLbl"
                      value={selectWorkshop ?? 0}
                      onChange={handleWorkShopChange}
                      label="Seleccione Taller"
                      disabled={disabled}
                    >
                      {claimState.workshopsInfo?.map(
                        (workshop: IWorkshopInfo) => {
                          return (
                            <MenuItem
                              key={workshop.WorkshopId}
                              value={workshop.WorkshopId}
                            >
                              {workshop.Name}
                            </MenuItem>
                          );
                        },
                      )}
                    </Select>
                  </FormControl>
                </Box>
              )}

              {!editByAnalyst && (
                <>
                  <Box>
                    <br />
                    <Field
                      key="workshop"
                      as={TextField}
                      type="text"
                      label="Nombre de Taller"
                      name="workshop"
                      defaultValue={
                        claimForm?.info?.paymentDetail?.workShop?.name
                      }
                      disabled={true}
                    />
                  </Box>
                  <br />
                  <Box className="agreement-detail__data">
                    {repairDate.length > 0 ? (
                      <>
                        <Box>
                          <p className="agreement-detail__data--title-result">
                            Reparación realizada
                          </p>
                          <p className="agreement-detail__data--subtitle-result">
                            Fecha de Reparación: {repairDate}
                          </p>
                        </Box>
                      </>
                    ) : (
                      <>
                        <Box>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                              label="Fecha Finaliza Reparación"
                              format={
                                CATALOGS.getCountryFormat().dateTimeFormat
                              }
                              disabled={disabled}
                              slotProps={{
                                textField: {
                                  variant: 'outlined',
                                  margin: 'none',
                                  'aria-label': 'change date',
                                },
                              }}
                              onChange={(date: any) => {
                                if (date) {
                                  const dayjsDate = dayjs(date);
                                  if (dayjsDate.isValid()) {
                                    setSelectedDate(
                                      dayjsDate.format('YYYY-MM-DD'),
                                    );
                                  }
                                }
                              }}
                            />
                          </LocalizationProvider>
                        </Box>
                        <Box>
                          <Button
                            text="Reparación Realizada"
                            type="button"
                            disabled={disabled}
                            onClick={() => setOpenConfirm(true)}
                          />
                        </Box>
                      </>
                    )}
                  </Box>
                </>
              )}
            </Box>
          )}

        <>
          {agreementType === ReceiveCheckType.POST_MAIL &&
          !legacyCurrentClaim?.closedWithoutPayment ? (
            <Box className="agreement-detail__data">
              <Box>
                <br />
                <Field
                  key="urbanization"
                  as={TextField}
                  type="text"
                  label="Urb, Barrio, Edificio..."
                  name="custom_urbanization"
                  onChange={handleInputChangeBySupport}
                  defaultValue={
                    editByAnalyst
                      ? ''
                      : claimForm?.info?.paymentDetail?.urbanization ?? 'N/D'
                  }
                  disabled={!editByAnalyst}
                />
              </Box>
              <Box>
                <br />
                <Field
                  key="town"
                  as={TextField}
                  type="text"
                  label="Pueblo"
                  name="custom_town"
                  onChange={handleInputChangeBySupport}
                  defaultValue={
                    editByAnalyst
                      ? ''
                      : claimForm?.info?.paymentDetail?.town ?? 'N/D'
                  }
                  disabled={!editByAnalyst}
                />
              </Box>
              <Box>
                <br />
                <Field
                  key="detailedAddress"
                  as={TextField}
                  type="text"
                  label="Número de casa o Apart, Calle o Av. "
                  name="custom_detailedAddress"
                  onChange={handleInputChangeBySupport}
                  defaultValue={
                    editByAnalyst
                      ? ''
                      : claimForm?.info?.paymentDetail?.detailedAddress ?? 'N/D'
                  }
                  disabled={!editByAnalyst}
                />
              </Box>
              <Box>
                <br />
                <Field
                  key="postalCode"
                  as={TextField}
                  type="text"
                  label="Código Postal"
                  name="custom_postalCode"
                  onChange={handleInputChangeBySupport}
                  defaultValue={
                    editByAnalyst
                      ? ''
                      : claimForm?.info?.paymentDetail?.postalCode ?? 'N/D'
                  }
                  disabled={!editByAnalyst}
                />
              </Box>
            </Box>
          ) : (
            <>
              {agreementType === ReceiveCheckType.PICKUP_OFFICE &&
              !legacyCurrentClaim?.closedWithoutPayment ? (
                <Box>
                  <br />
                  <Field
                    sx={{ minWidth: 400 }}
                    key="office"
                    as={TextField}
                    type="text"
                    label="Dirección de Oficina"
                    name="custom_office"
                    onChange={handleInputChangeBySupport}
                    defaultValue={
                      claimForm?.info?.paymentDetail?.office ?? 'N/D'
                    }
                    disabled={!editByAnalyst}
                  />
                </Box>
              ) : (
                <>
                  {agreementType === Payments.ACH &&
                    !legacyCurrentClaim?.closedWithoutPayment && (
                      <>
                        <Box className="agreement-detail__data">
                          <Box>
                            <br />
                            <Field
                              key="claimentName"
                              as={TextField}
                              type="text"
                              label="Nombre dueño cuenta"
                              name="custom_claimentName"
                              onChange={handleInputChangeBySupport}
                              defaultValue={
                                claimForm?.info?.paymentDetail?.claimentName ??
                                'N/D'
                              }
                              disabled={!editByAnalyst}
                              inputProps={{ maxLength: 200 }}
                            />
                          </Box>
                          <Box>
                            <br />
                            <Field
                              key="phone"
                              as={TextField}
                              type="text"
                              label="Teléfono"
                              name="custom_phone"
                              onChange={handleInputChangeBySupport}
                              defaultValue={
                                claimForm?.info?.paymentDetail?.phone ?? 'N/D'
                              }
                              disabled={!editByAnalyst}
                              inputProps={{ maxLength: 20 }}
                            />
                          </Box>
                          <Box>
                            <br />
                            <Field
                              key="routeNumber"
                              as={TextField}
                              type="text"
                              label="Número de ruta/tránsito"
                              name="custom_routeNumber"
                              onChange={handleInputChangeBySupport}
                              defaultValue={
                                claimForm?.info?.paymentDetail?.routeNumber ??
                                'N/D'
                              }
                              disabled={!editByAnalyst}
                              inputProps={{ maxLength: 50 }}
                            />
                          </Box>
                          <Box>
                            <br />
                            <Field
                              key="nameBank"
                              as={TextField}
                              type="text"
                              label="Nombre institución financiera"
                              name="custom_nameBank"
                              onChange={handleInputChangeBySupport}
                              defaultValue={
                                claimForm?.info?.paymentDetail?.nameBank ??
                                'N/D'
                              }
                              disabled={!editByAnalyst}
                              inputProps={{ maxLength: 100 }}
                            />
                          </Box>
                          <Box>
                            <br />
                            <Field
                              key="accountNumber"
                              as={TextField}
                              type="text"
                              label="# Cuenta"
                              name="custom_accountNumber"
                              onChange={handleInputChangeBySupport}
                              defaultValue={
                                claimForm?.info?.paymentDetail?.accountNumber ??
                                'N/D'
                              }
                              disabled={!editByAnalyst}
                              inputProps={{ maxLength: 50 }}
                            />
                          </Box>
                          <Box>
                            <br />
                            <FormControl
                              sx={{ minWidth: 175, backgroundColor: '#ffffff' }}
                            >
                              <InputLabel id="custom_accountTypeLabel">
                                Tipo de Cuenta
                              </InputLabel>
                              <Select
                                labelId="custom_accountTypeLabel"
                                id="custom_accountType"
                                value={selectAccountType}
                                onChange={handleAccountTypeChange}
                                label="Tipo de Cuenta"
                                disabled={!editByAnalyst}
                              >
                                <MenuItem value={'C'}>Corriente</MenuItem>
                                <MenuItem value={'A'}>Ahorro</MenuItem>
                              </Select>
                            </FormControl>
                          </Box>
                        </Box>
                        {!editByAnalyst && (
                          <Box className="agreement-detail__signature">
                            <br />
                            <Signature
                              signature={
                                claimForm?.info?.paymentDetail
                                  ?.agreementSignature ?? ''
                              }
                              isInspectorForm={false}
                              isDisabled={true}
                              onSignatureChange={() => {
                                // eslint-disable-next-line no-console
                                console.log('Signature is read only');
                              }}
                            />
                          </Box>
                        )}
                      </>
                    )}
                </>
              )}
            </>
          )}

          {agreementType.length > 0 &&
            agreementType !== AgreementTypes.REPAIR_CONTINUE &&
            !legacyCurrentClaim?.closedWithoutPayment && (
              <Box className="agreement-detail__data">
                {paymentDate.length > 0 ? (
                  <>
                    <Box>
                      <p className="agreement-detail__data--title-result">
                        Pago realizado
                      </p>
                      <p className="agreement-detail__data--subtitle-result">
                        Fecha de Pago: {paymentDate}
                      </p>
                    </Box>
                  </>
                ) : (
                  <>
                    {!editByAnalyst && (
                      <>
                        <Box>
                          <br />
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDatePicker
                              label="Fecha de Pago"
                              disabled={disabled}
                              format={
                                CATALOGS.getCountryFormat().dateTimeFormat
                              }
                              slotProps={{
                                textField: {
                                  variant: 'outlined',
                                  margin: 'none',
                                  'aria-label': 'change date',
                                },
                              }}
                              onChange={(date: any) => {
                                if (date) {
                                  const dayjsDate = dayjs(date);
                                  if (dayjsDate.isValid()) {
                                    setSelectedDate(
                                      dayjsDate.format('YYYY-MM-DD'),
                                    );
                                  }
                                }
                              }}
                            />
                          </LocalizationProvider>
                        </Box>
                        <Box>
                          <br />
                          <Button
                            text="Pago Realizado"
                            type="button"
                            disabled={disabled}
                            onClick={() => setOpenConfirm(true)}
                          />
                        </Box>
                      </>
                    )}
                  </>
                )}
              </Box>
            )}
        </>

        {editByAnalyst && !legacyCurrentClaim?.closedWithoutPayment && (
          <Box>
            <br />
            <Button
              text="Guardar Cambios"
              type="button"
              disabled={disabled}
              onClick={() => setOpenConfirmBySupport(true)}
            />
          </Box>
        )}

        {claimForm &&
          !editByAnalyst &&
          legacyCurrentClaim?.closedWithoutPayment && (
            <>
              <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert severity="info">
                  <AlertTitle>
                    <strong>Cierre sin pago</strong>
                  </AlertTitle>
                </Alert>
              </Stack>
            </>
          )}
        {claimForm &&
          !editByAnalyst &&
          claimForm?.info?.paymentDetail?.agreementType?.length == 0 &&
          !legacyCurrentClaim?.closedWithoutPayment && (
            <>
              <Stack sx={{ width: '100%' }} spacing={2}>
                <Alert severity="warning">
                  <AlertTitle>
                    <strong>Falta de Información</strong>
                  </AlertTitle>
                  Reclamante aún no define los parámetros del acuerdo para el{' '}
                  <strong>pago / reparación</strong>.
                  <br />
                  <Button
                    className="agreement-detail__btn-alert"
                    style={{ marginTop: '1rem' }}
                    text="Actualizar Información por Solicitud"
                    type="button"
                    onClick={() => setOpenConfirmEdit(true)}
                    disabled={disabled}
                    size="small"
                  />
                </Alert>
              </Stack>
            </>
          )}
      </Box>
      <Dialog open={openConfirm}>
        <DialogTitle id="confirm-restore-title">Confirmar</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="confirm-payment-modal"
            style={{ width: '400px', height: '120px' }}
          >
            <br />
            ¿Está seguro que desea aplicar la finalización para esta fecha?
            <br />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            text="Cancelar"
            variant="outlined"
            color="secondary"
            onClick={() => setOpenConfirm(false)}
          />
          <Button
            text="Sí, confirmar"
            variant="contained"
            color="primary"
            onClick={confirmAgreement}
          />
        </DialogActions>
      </Dialog>
      <Dialog open={openConfirmEdit}>
        <DialogTitle id="confirm-restore-title">Confirmar Edición</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="confirm-payment-modal"
            style={{ width: '400px', height: '120px' }}
          >
            <br />
            ¿Está seguro que desea{' '}
            <b>actualizar la información del reclamante</b>?
            <br />
            <p>
              <strong>Nota:</strong> Esta acción quedará registrada en el
              histórico del caso.
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            text="Cancelar"
            variant="outlined"
            color="secondary"
            onClick={() => setOpenConfirmEdit(false)}
          />
          <Button
            text="Sí, confirmar"
            variant="contained"
            color="primary"
            onClick={confirmEdit}
          />
        </DialogActions>
      </Dialog>
      <Dialog open={openConfirmBySupport}>
        <DialogTitle id="confirm-restore-title2">
          Confirmar cambios vía solicitud
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="confirm-payment-modal2"
            style={{ width: '400px', height: '120px' }}
          >
            <br />
            ¿Desea aplicar los cambios actuales para este reclamante?
            <br />
            <p>
              <strong>Nota:</strong> Esta acción quedará registrada en el
              histórico del caso.
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            text="Cancelar"
            variant="outlined"
            color="secondary"
            onClick={() => setOpenConfirmBySupport(false)}
          />
          <Button
            text="Sí, confirmar"
            variant="contained"
            color="primary"
            onClick={confirmAgreementBySupport}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AgreementDetail;
