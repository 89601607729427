import { Box, Button, IconButton, Modal } from '@mui/material';
import React, { FunctionComponent, useState, useEffect } from 'react';
import General from './general';
import History from './history';
import Observations from 'components/events/event-state/Observations';
import Documents from './documents';
import SendDocument from './sendDocument';
import checkStage from '../../../assets/icons/shield-check.svg';
import checkWhite from '../../../assets/icons/check-white.svg';
import fileWhite from '../../../assets/icons/file-white.svg';
import fileStage from '../../../assets/icons/file-document.svg';
import relojStage from '../../../assets/icons/reloj.svg';
import chatStage from '../../../assets/icons/chat.svg';
import chatWhite from '../../../assets/icons/chat-white.svg';
import calendarIcon from '../../../assets/icons/calendarIcon.svg';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import './index.scss';
import { Rol } from 'types/common';
import ClaimButton from '../button';
import Reminders from 'components/events/event-state/Reminders';

interface ClaimStepsProps {
  steps: any;
  claim: any;
  uId: any;
}

const iconPaths = {
  CHECK: { selected: checkWhite, unselected: checkStage },
  FILE: { selected: fileWhite, unselected: fileStage },
  CHAT: { selected: chatWhite, unselected: chatStage },
  CLOCK: { selected: relojStage, unselected: relojStage },
  CALENDAR: { selected: calendarIcon, unselected: calendarIcon },
};

const ClaimSteps: FunctionComponent<ClaimStepsProps> = ({
  steps,
  claim,
  uId,
}): JSX.Element => {
  const [selectedStep, setSelectedStep] = useState('');
  const [isContentExpanded, setIsContentExpanded] = useState(false);

  useEffect(() => {
    setIsContentExpanded(false);
    setSelectedStep('');    
  }, [claim]);

  const handleStepClick = (name: string) => {
    if (selectedStep === name) {
      setSelectedStep('');
      setIsContentExpanded(false);
    } else {
      setSelectedStep(name);
      setIsContentExpanded(true);
    }
  };

  const handleIconClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    setSelectedStep('');
    setIsContentExpanded(false);
  };

  const getIconState = (
    icon: string | undefined,
    isSelected: boolean,
  ): JSX.Element => {
    const iconPath = iconPaths[icon?.toUpperCase() || ''] || iconPaths.CHECK;
    const { selected, unselected } = iconPath;

    return <img src={isSelected ? selected : unselected} alt={icon || ''} />;
  };

  const isDisabled = Number(localStorage.getItem('rolId')) === Rol.INSURER;

  const getStepContent = (name: string) => {
    switch (name) {
      case 'history':
        return <History claim={claim[name]} />;
      case 'observations':
        return (
          <>
            <div style={{ paddingBottom: '1rem' }}>
              <label>Observaciones</label>
            </div>
            <Observations sicId={claim['info'].sicId} />
          </>
        );
      case 'reminders':
        return (
          <>
            <div style={{ paddingBottom: '1rem' }}>
              <label>Recordatorios</label>
            </div>
            <Reminders sicId={claim['info'].sicId} />
          </>
        );
      case 'documents':
        return <Documents claim={claim} uId={uId} disabled={isDisabled} />;
      case 'sendDocument':
        return <SendDocument claim={claim} uId={uId} />;
      default:
        return <Box> Sin información </Box>;
    }
  };

  return (
    <>
    <Box className="claim-step-info">
      <Box className="claim-step-info">
        {steps?.map((step: any) => {
          const { id, title, name, icon } = step;
          const isSelected = selectedStep === name;

            return (
              <React.Fragment key={id}>
                <Button
                  key={id}
                  endIcon={getIconState(icon, isSelected)}
                  variant={isSelected ? 'contained' : 'outlined'}
                  className={`claim-step-info__step ${isSelected ? 'claim-step-info__step--selected' : ''
                    } ${isContentExpanded ? 'claim-step-info__step--expanded' : ''
                    }`}
                  onClick={() => handleStepClick(name)}
                >
                  <label>{title}</label>
                </Button>
              </React.Fragment>
            );
          })}
          {selectedStep && (
            <IconButton className="close-icon" onClick={handleIconClick}>
              <HighlightOffIcon />
            </IconButton>
          )}
          {/* <Button
            endIcon={getIconState('FILE', true)}
            variant={'contained'}
            className={
              'claim-step-info__step claim-step-info__step--selectedOrange'
            }
            onClick={handleOpenSendDocument}
          >
            <label>Generar Documento</label>
          </Button> */}
        </Box>
        <Box className="box-content scrollable-container">
          <div
            style={{ display: selectedStep === 'history' ? '' : 'none' }}
          >
            <History claim={claim['history']} />
          </div>
          <div
            style={{ display: selectedStep === 'observations' ? '' : 'none' }}
          >
            <div style={{ paddingBottom: '1rem' }}>
              <label>Observaciones</label>
            </div>
            <Observations sicId={claim['info'].sicId} />
          </div>
          <div style={{ display: selectedStep === 'reminders' ? '' : 'none' }}>
            <div style={{ paddingBottom: '1rem' }}>
              <label>Recordatorios</label>
            </div>
            <Reminders sicId={claim['info'].sicId} />
          </div>
          <div style={{ display: selectedStep === 'documents' ? '' : 'none' }}>
            <Documents claim={claim} uId={uId} disabled={isDisabled} />
          </div>
        </Box>
      </Box>
      {/* <Modal
        open={openSendDocument}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="claim-step-info__modal">
          <SendDocument
            claim={claim}
            uId={uId}
            closeModal={handleCloseSendDocument}
          />
        </Box>
      </Modal> */}
    </>
  );
};

export default ClaimSteps;
