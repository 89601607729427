import { Popper, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/HighlightOff';
import { Autocomplete } from '@mui/material';
import React, { FC, useEffect, useRef, useState } from 'react';
import getTags from 'static/constants/tags';
import { Option } from 'types/option';
import useStyles from './override-mui-styles';
import clsx from 'clsx';
import './TagPicker.scss';

interface TagPickerProps {
  anchorEl: null | HTMLElement;
  open: boolean;
  value: Option | null;
  onChange: (value: Option | null) => void;
  onClose: () => void;
}

const TagPicker: FC<TagPickerProps> = ({
  open,
  value,
  onChange,
  onClose,
  anchorEl,
}) => {
  const classes = useStyles();
  const tagList = useRef<Option[]>([]);
  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);
  const node = useRef<any>(null);
  const popper = useRef<any>(null);

  useEffect(() => {
    const tagOptions = getTags();
    tagList.current = tagOptions;
  }, []);

  useEffect(() => {
    const handleClickOutside = (el: Event) => {
      if (
        (node?.current && node.current.contains(el.target)) ||
        (popper?.current && popper.current.contains(el.target))
      ) {
        return;
      }
      onClose();
    };
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open, onClose]);

  return (
    <Popper
      ref={node}
      open={open}
      onClick={(e: any) => e.stopPropagation()}
      anchorEl={anchorEl}
      className={clsx(classes.popper, 'tag-popper')}
      placement="left"
      modifiers={[
        {
          name: 'preventOverflow',
          enabled: true,
          options: {
            rootBoundary: 'window'
          }
        },
        {
          name: 'arrow',
          enabled: true,
          options: {
            element: arrowRef
          }
        }
      ]}
    >
      <div className="tag-popper__header">
        <h5>Asignar Tag</h5>
        <CloseIcon className="close" onClick={onClose} />
      </div>
      <Autocomplete
        options={tagList.current}
        getOptionLabel={(option) => option.name || ''}
        value={value}
        size="small"
        onChange={(_, newValue: Option | null) => {
          onChange(newValue);
        }}
        renderInput={(params) => (
          <TextField {...params} label="Tag" variant="outlined" />
        )}
        PopperComponent={(props) => <Popper ref={popper} {...props} />}
      />
      <span className={classes.arrow} ref={setArrowRef} />
    </Popper>
  );
};

export default TagPicker;
