import React, { useContext } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SvgIcon from '@mui/material/SvgIcon';

import styles from './override-mui-styles';
import { DispatchFunction } from 'types/action';
import { IPreInspectionContext, PreInspectionContext } from 'context/preinspections';

export interface MenuOptionPI {
  value: string;
  label: string;
  icon: JSX.Element;
  isActive?: boolean;
   actionPI?: (
    event: any,
    dispatch: DispatchFunction,
  ) => void;
}

interface Props {
  anchorElement: null | HTMLElement;
  menuOptions: MenuOptionPI[];
  currentValue?: any;
  onClickMenu: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onCloseMenu: () => void;
  onCloseTrigger?: (type?: string, value?: any) => void;
}

const TableMenu = (props: Props): JSX.Element => {
  const classes = styles();
  const { dispatch } = useContext<IPreInspectionContext>(PreInspectionContext);

  return (
    <Menu
      anchorEl={props.anchorElement}
      open={Boolean(props.anchorElement)}
      onClose={props.onCloseMenu}
      classes={{
        paper: classes.menu,
      }}
    >
      {props.menuOptions.filter((f) => f.isActive === undefined || f.isActive === true).map((option: MenuOptionPI) => (
        <MenuItem
          key={option.value}
          className={classes.menuOption}          
          onClick={() => {
            if (option.actionPI) {
              option.actionPI(props.currentValue, dispatch);
            }
            props.onCloseMenu();
          }}
        >
          <SvgIcon fontSize="small" className={classes.menuOptionIcon}>
            {option.icon}
          </SvgIcon>
          {option.label}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default TableMenu;
