//App Routes Constants
export default {
  USER_SIGNUP: '/signup',
  USER_RENEW_PASSWORD: '/recovery-account',
  USER_REQUEST_PASSWORD: '/request-access',
  IN_PROGRESS: '/in-progress',
  USER_NEW_PASSWORD: '/new-password',
  EVENTS: '/events',
  EVENTS_CLAIMS: '/events-claims',

  INSPECTION_ID: '/inspections/:id/',
  INSPECTION_SECTION: '/inspections/:id/sections/:sectionId',
  INSPECTOR_FORM_ID: '/inspector/:countryCode/:id',
  INSPECTOR_FORM_SECTION: '/inspector/:countryCode/:id/sections/:sectionId',
  INSPECTOR_FORM_NOT_ACCESS: '/inspector/:countryCode/undefined',

  APPROVAL: '/approval',

  REPORTS: '/reports',
  DocumentTemplates: '/documentTemplates',
  CONFIG_DAMAGES: '/configdamages',

  PRE_INSPECTIONS: '/preinspections',
  PRE_INSPECTIONS_FORM_ID: '/preinspections/:partnerKey/:id',
  PRE_INSPECTIONS_FORM_SECTION:
    '/preinspections/:partnerKey/:id/sections/:sectionId',
  PRE_INSPECTION_CREATE: '/pre-inspections/create',
  PRE_INSPECTION_COMPLETE: '/pre-inspections/complete',
  PRE_INSPECTION_GENERAL_DATA: '/pre-inspections/create/general-data',
  PRE_INSPECTION_CAR_DATA: '/pre-inspections/create/car-data',
  PRE_INSPECTION_INTERIOR_DATA: '/pre-inspections/create/interior',
  PRE_INSPECTION_EXTERIOR_DATA: '/pre-inspections/create/exterior',
  PRE_INSPECTION_OBSERVATIONS: '/pre-inspections/create/observations',
  PRE_INSPECTION_PICTURES: '/pre-inspections/create/pictures',
  PRE_INSPECTION_DAMAGES: '/pre-inspections/create/damages',
  PRE_INSPECTION_SUMMARY: '/pre-inspections/create/summary',
  PRE_INSPECTION_ACTIVITY: '/pre-inspections/activity',
  PRE_INSPECTION_REPORT: '/pre-inspections/report',
  PRE_INSPECTION_CONFIG_DAMAGES: '/pre-inspections/settings-damages',

  SETTINGS: '/settings',

  CLAIMS_TRACKER_MAIN: '/claimtracker/:id',
  CLAIMS: '/claims/:id',
  ONE_ACCESS: '/one-access',
};
