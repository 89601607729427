import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import Chip from 'components/common/chip/Chip';
import Companies from 'static/constants/companies';
import { roles } from 'static/constants/roles';
import catalogs from 'static/constants/catalogs';
import { IUserModel } from 'types/user';
import UserFilters from '../Common/UserFilters';
import UserForm from '../Common/UserForm';
import UserList from '../Common/UserList';
import {
  createOrUpdateUser,
  getUserByGuid,
  getUsersByFilter,
} from 'context/user';
import { CountryList, Rol } from 'types/common';
import { SettingsTab } from 'static/constants/settings-tabs';

export const StatusChip = (eventStatus: boolean): JSX.Element => {
  if (eventStatus) {
    return <Chip label="Activo" color="green" />;
  } else {
    return <Chip label="Inactivo" color="red" />;
  }
};

interface Props {
  scrollTrigger: () => void;
}

const CompaniesTab = (props: Props): JSX.Element => {
  const defaultUserData: IUserModel = {
    name: '',
    email: '',
    rolId: 0,
    companyId: 0,
    guidID: '',
    active: true,
  };

  const [listUser, setListUser] = useState<IUserModel[]>([]);
  const [currentUser, setCurrentUser] = useState<IUserModel>(defaultUserData);
  const [cantRegisters, setCantRegisters] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeFetch, setActiveFetch] = useState(true);
  const [searchFilter, setSearchFilter] = useState('');
  const [companyFilter, setCompanyFilter] = useState(0);
  const [labelSubmit, setLabelSubmit] = useState('Crear');
  const [showForm, setShowForm] = useState(false);

  /* User info */
  const [isNewUser, setIsNewUser] = useState(false);
  const [companyUser, setCompanyUser] = useState<number | null>(null);
  const [roleUser, setRoleUser] = useState<number | null>(null);
  const [runnerCode, setRunnerCode] = useState<string | undefined>(undefined);
  const [activeUser, setActiveUser] = useState(false);
  const [changePassword, setChangePassword] = useState(false);

  const onChangeSearchFilter = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setCurrentPage(1);
    setSearchFilter(event.target.value);
  };

  const onChangeCompanyFilter = (event: any) => {
    setCurrentPage(1);
    setCompanyFilter(+event.target.value);
  };

  const onSearchUserCompanies = useCallback(() => {
    const filters: any = [];

    if (searchFilter.length > 0) {
      filters.push({
        name: 'filter',
        value: searchFilter,
      });
    }

    filters.push({
      name: 'companyId',
      value: companyFilter,
    });

    filters.push({
      name: 'page',
      value: currentPage,
    });
    filters.push({
      name: 'results',
      value: 20,
    });

    (async (): Promise<void> => {
      const resultUsers = await getUsersByFilter('INSURANCE', filters);
      if (resultUsers) {
        setListUser(resultUsers.filter((f) => getListRoles().map((r) => r.id).includes(f.rolId)));
        setCantRegisters(resultUsers[0]?.currentCount ?? 0);
      }
    })();
  }, [companyFilter, currentPage, searchFilter]);

  const onShowForm = useCallback(
    (show: boolean, isNew: boolean) => {
      if (isNew) {
        setCurrentUser(defaultUserData);
        setChangePassword(true);
      }

      setIsNewUser(isNew);
      setLabelSubmit(isNew ? 'Crear' : 'Actualizar');
      setShowForm(show);
    },
    [defaultUserData],
  );

  const onGetUser = useCallback(
    (guid: string) => {
      (async (): Promise<void> => {
        const resultUser = await getUserByGuid(guid);
        if (resultUser) {
          setChangePassword(false);
          setCompanyUser(resultUser.companyId);
          setRoleUser(resultUser.rolId);
          setActiveUser(resultUser.active);
          setRunnerCode(resultUser.runnerCode);
          setCurrentUser(resultUser);
          onShowForm(true, false);
        }
      })();
    },
    [onShowForm],
  );

  const onChangePage = (event: any, page: number) => {
    const newPage = page === 0 ? 1 : page + 1;
    setCurrentPage(newPage);
    setActiveFetch(true);
  };

  const getListRoles = (): any[] => {
    return roles.filter((r: any) =>
      [
        Rol.INSURER,
        Rol.PRE_INSURER,
        Rol.BROKER,
        Rol.PRE_BROKER,
        Rol.CUSTOMER,
        Rol.PRE_CONSULTANT,
      ].includes(r.id),
    );
  };

  const getListRunners = (): any[] => {
    return catalogs.getRunners();
  };

  useEffect(() => {
    if (activeFetch) {
      setActiveFetch(false);
      onSearchUserCompanies();
    }
  }, [activeFetch, onSearchUserCompanies]);

  return (
    <div className="settingsForm">
      {!showForm && (
        <UserFilters
          useCountries={false}
          searchFilter={searchFilter}
          companyFilter={companyFilter}
          currentCompanies={Companies().filter((f) => f.type === 'INSURANCE')}
          onEnableForm={onShowForm}
          onChangeCompanyFilter={onChangeCompanyFilter}
          onChangeSearchFilter={onChangeSearchFilter}
          onTriggerSearch={onSearchUserCompanies}
        />
      )}

      {showForm && (
        <UserForm
          labelForSubmit={labelSubmit}
          isNewUser={isNewUser}
          currentUser={currentUser}
          currentUserCompany={companyUser}
          currentUserRole={roleUser}
          currentUserActive={activeUser}
          currentRunnerOwner={runnerCode}
          listRoles={getListRoles()}
          listCompanies={Companies().filter(
            (f) => f.type === 'INSURANCE' || f.type === 'MULTICOUNTRY',
          )}
          listRunnerOwners={getListRunners()}
          enableChangePassword={changePassword}
          onActiveFetch={(value) => {
            setActiveFetch(value);
          }}
          onShowForm={(value) => {
            setShowForm(value);
          }}
          onCreateOrUpdate={createOrUpdateUser}
          listCountries={CountryList}
          currentTab={SettingsTab.COMPANIES}
        />
      )}
      <Grid container className="settingsForm__row" spacing={2}>
        <UserList
          cantRegisters={cantRegisters}
          currentPage={currentPage}
          listUsers={listUser}
          disabledModify={showForm}
          onChangePage={onChangePage}
          onModifyUser={onGetUser}
          scrollTrigger={props.scrollTrigger}
        />
      </Grid>
    </div>
  );
};

export default CompaniesTab;
