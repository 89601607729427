import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import './index.scss';
import { Grid, Link, TextField } from '@mui/material';
import DataTable from '../grid';
import Button from '../button';
import { GridColDef, GridRowId } from '@mui/x-data-grid';
import ClaimAdjust from '../adjust';
import ClaimsTrackerClient from 'shared/clients/ClaimsTrackerClient';
import {
  IInspectionContext,
  InspectionContext,
  uploadMediaFile,
} from 'context/inspections';
import { ActionType } from 'types/action';
import {
  formatNumber,
  formatNumberPoint,
  renderComponentIsEnable,
} from 'shared/utils';
import FileInput from '../inputFile';
import { PhotoSection } from 'types/images';
import {
  BUCKET_NAME_CLAIMS_TRACKER,
  BUCKET_REGION_CLAIMS_TRACKER,
} from 'shared/api/config';
import DialogComponent from 'components/common/dialog';
import { ClaimantType, IClaimForm } from 'types/claimsTracker';
import { CountryCode, MetadataTag, ResourceAction } from 'types/common';
import { InputsEstimated } from './inputs/inputs';

export interface EstimatedAmounts {
  partsCost: string;
  porcDesc: string;
  discountAmount: string;
  subtotalParts: string;
  partsCostWithoutDiscount: string;
  partsCostTotal: string;
  expenseAmount: string;
  materialsCost: string;
  laborCost: string;
  ivuLaborAmount: string;
  ivuPartsAmount: string;
  estimatedTotalAmount: string;
  porcIVA: string;
  ivaAmount: string;
}

export const calculateDiscount = (
  porcDesc: number,
  partsCost: number,
): number => {
  if (porcDesc > 0 && porcDesc <= 100) {
    return (partsCost * porcDesc) / 100;
  }

  return 0;
};

export const calculateIVAAmount = (
  porcIVA: number,
  subtotal: number,
): number => {
  if (porcIVA > 0 && porcIVA <= 100) {
    return (subtotal * porcIVA) / 100;
  }

  return 0;
};

export const calculateSubtotal = (
  partsCost: number,
  discount: number,
): number => {
  return partsCost - discount;
};

export const calculateCostTotalParts = (
  subtotal: number,
  partCostWithoutDiscount: number,
): number => {
  return subtotal + partCostWithoutDiscount;
};

export const calculateTotalEstimated = (
  costTotalParts: number,
  expenseAmount: number,
  materialsCost: number,
  laborCost: number,
  ivuLabor: number,
  ivuParts: number,
  ivaAmount: number,
): number => {
  return (
    costTotalParts +
    expenseAmount +
    materialsCost +
    laborCost +
    ivuLabor +
    ivuParts +
    ivaAmount
  );
};

interface PropsEstimated {
  claimUId?: string;
  claimNumber: string;
  poNumber: string;
  claimantType: string;
  disabled: boolean;
  accountReference?: number;
  claimForm?: IClaimForm;
}

const ClaimEstimated: FunctionComponent<PropsEstimated> = ({
  claimUId,
  claimNumber,
  poNumber,
  claimantType,
  disabled,
  accountReference,
  claimForm,
}): JSX.Element => {
  const [fileUpload, setFileUpload] = useState<File>();
  const [fileName, setFileName] = useState('');
  useEffect(() => {
    getAllEstimatedAmount().catch(console.error);
  }, []);

  const { dispatch, state } = useContext<IInspectionContext>(InspectionContext);
  const [dataHistoric, setDataHistoric] = useState<any>([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [amountsState, setAmountsState] = useState<EstimatedAmounts>({
    partsCost: '',
    porcDesc: '0',
    discountAmount: '',
    subtotalParts: '',
    partsCostWithoutDiscount: '',
    partsCostTotal: '',
    expenseAmount: '',
    materialsCost: '',
    laborCost: '',
    ivuLaborAmount: '',
    ivuPartsAmount: '',
    estimatedTotalAmount: '',
    porcIVA: '0',
    ivaAmount: '0',
  });

  const [selectionFields, setSelectionFields] = useState<GridRowId[]>([]);
  const [openDialogConfirm, setOpenDialogConfirm] = useState(false);

  const columns: GridColDef[] = [
    { field: 'estimatedTotalAmount', headerName: 'Estimado', width: 100 },
    { field: 'createdDate', headerName: 'Fecha', width: 180 },
    { field: 'userName', headerName: 'Usuario', width: 100 },
    {
      field: 'actions',
      headerName: 'Acciones',
      width: 100,
      renderCell: (params) => (
        <Link
          className="claim-estimated__table--link"
          href="#"
          onClick={() => loadEstimated(params.id)}
        >
          Ver detalle
        </Link>
      ),
    },
    {
      field: 'fileUrl',
      headerName: 'Documentos',
      width: 150,
      renderCell: (params) => (
        <Link
          className="claim-estimated__table--link"
          target="_blank"
          href={params.value}
          hidden={!params.value}
        >
          Ver documento
        </Link>
      ),
    },
  ];

  const loadEstimated = (id: any): void => {
    const register = dataHistoric.find((x) => x.id === id);
    calculateEstimatedCountry(register);
  };

  const handleCancel = (): void => {
    setSelectionFields([]);
  };

  const openConfirmDialog = (): void => {
    setOpenDialogConfirm(true);
  };

  const handleConfirm = async (): Promise<void> => {
    setOpenDialogConfirm(false);
    await handleDelete();
  };
  const handleDelete = async (): Promise<void> => {
    try {
      dispatch({ type: ActionType.SET_LOADING, payload: true });

      for (const id of selectionFields) {
        await new ClaimsTrackerClient().DeleteEstimatedAmount(id as number);
      }

      setSelectionFields([]);
      await getAllEstimatedAmount();

      dispatch({ type: ActionType.SET_LOADING, payload: false });
    } catch (e) {
      dispatch({ type: ActionType.SET_LOADING, payload: false });
    }
  };

  useEffect(() => {
    if (state.expenseAmount) {
      const data = amountsState;

      switch (localStorage.getItem('countryCode') as CountryCode) {
        case CountryCode.COL:
          data.expenseAmount = state.expenseAmount || data.expenseAmount || '0';
          break;
        default:
          data.expenseAmount = data.expenseAmount || state.expenseAmount || '0';
          break;
      }

      setAmountsState({ ...data });
      calculateEstimatedCountry(data);
    }
  }, [state.expenseAmount]);

  useEffect(() => {
    if (state.partsAmount) {
      const data = amountsState;

      switch (localStorage.getItem('countryCode') as CountryCode) {
        case CountryCode.COL:
          data.partsCost = state.partsAmount || data.partsCost || '0';
          break;
        default:
          data.partsCost = data.partsCost || state.partsAmount || '0';
          break;
      }

      setAmountsState({ ...data });
      calculateEstimatedCountry(data);
    }
  }, [state.partsAmount]);

  const getAllEstimatedAmount = async (): Promise<void> => {
    try {
      dispatch({
        type: ActionType.SET_ESTIMATED_AMOUNT,
        payload: undefined,
      });
      const result = await new ClaimsTrackerClient().getAllEstimatedAmount(
        claimUId,
      );

      for (let i = 0; i < result.length; i++) {
        result[i].estimatedTotalAmount = formatNumberPoint(
          result[i].estimatedTotalAmount,
        );
        result[i].partsCost = formatNumberPoint(result[i].partsCost);
        result[i].materialsCost = formatNumberPoint(result[i].materialsCost);
        result[i].laborCost = formatNumberPoint(result[i].laborCost);
        result[i].expenseAmount = formatNumberPoint(result[i].expenseAmount);
        result[i].ivuPartsAmount = formatNumberPoint(result[i].ivuPartsAmount);
        result[i].partsCostTotal = formatNumberPoint(result[i].partsCostTotal);
        result[i].ivuLaborAmount = formatNumberPoint(result[i].ivuLaborAmount);
      }
      if (result?.length > 0) {
        const data = result.slice(-1)[0];
        dispatch({
          type: ActionType.SET_ESTIMATED_AMOUNT,
          payload: '1',
        });
        setAmountsState({ ...data });
        calculateEstimatedCountry({ ...data });
      }
      setDataHistoric(result);
    } catch (e) {
      console.warn(e);
    }
  };

  const validateAmounts = (): boolean => {
    if (disabled) {
      return true;
    }

    const countryCode = localStorage.getItem('countryCode') as CountryCode;
    const columns = InputsEstimated[countryCode];

    for (const column of columns) {
      for (const row of column.rows) {
        for (const input of row.inputsFields) {
          if (input.required && !amountsState[input.value]) {
            return true;
          }
        }
      }
    }

    return false;
  };

  const onClickSave = async (): Promise<void> => {
    try {
      dispatch({ type: ActionType.SET_LOADING, payload: true });

      let fileUrl;

      if (fileUpload) {
        if (fileUpload.size <= 3000000) {
          const upload = await uploadMediaFile(
            fileUpload,
            `${poNumber}/${claimNumber}`,
            claimantType?.toUpperCase() === ClaimantType.CLAIM_INJURED
              ? PhotoSection.INJURED_OTHERS_DOCS
              : PhotoSection.INSURED_OTHERS_DOCS,
            BUCKET_NAME_CLAIMS_TRACKER,
            BUCKET_REGION_CLAIMS_TRACKER,
            `mitchell_${Date.now().toString()}`,
            MetadataTag.CMT_ESTIMATED_MITCHELL_DOCUMENT,
          );

          fileUrl = upload.urlUpload?.split('?')[0];
          setFileName('');
          setFileUpload(undefined);
        } else {
          setOpenDialog(true);
          dispatch({ type: ActionType.SET_LOADING, payload: false });

          return;
        }
      }

      const result = await new ClaimsTrackerClient().SaveEstimatedAmount(
        amountsState,
        claimUId,
        fileUrl,
      );

      if (result?.data?.success) {
        await getAllEstimatedAmount();
        dispatch({ type: ActionType.SET_LOADING, payload: false });
      }
    } catch (e) {
      console.warn(e);
      dispatch({ type: ActionType.SET_LOADING, payload: false });
    }
  };

  const onCalcEstimated = (event: any): void => {
    if (amountsState) {
      const data = amountsState;
      data[event?.target?.name] = event?.target?.value.replace(/[^0-9.]/g, '');
      setAmountsState({ ...data });

      calculateEstimatedCountry(data);
    }
  };

  const calculateEstimatedCountry = (data: EstimatedAmounts): void => {
    switch (localStorage.getItem('countryCode') as CountryCode) {
      case CountryCode.PRI:
        calculateEstimated(data);
        break;
      case CountryCode.COL:
        calculateEstimatedCOL(data);
        break;
    }
  };

  const calculateEstimatedCOL = (data: EstimatedAmounts): void => {
    const partsCost = Number(data.partsCost ?? 0);
    const laborCost = Number(data.laborCost ?? 0);
    const others = Number(data.expenseAmount ?? 0);
    const ivaAmount = calculateIVAAmount(
      Number(data.porcIVA ?? 0),
      laborCost + partsCost + others,
    );

    const totalEstimated = calculateTotalEstimated(
      partsCost,
      others,
      0,
      laborCost,
      0,
      0,
      ivaAmount,
    );

    data.partsCostWithoutDiscount = '0';
    data.porcDesc = '0';
    data.materialsCost = '0';
    data.ivuLaborAmount = ivaAmount.toFixed(2);
    data.ivaAmount = ivaAmount.toFixed(2);
    data.ivuPartsAmount = '0';

    data.partsCostTotal = partsCost.toFixed(2);
    data.estimatedTotalAmount = totalEstimated.toFixed(2);

    setAmountsState({ ...data });
  };

  const calculateEstimated = (data: EstimatedAmounts): void => {
    const porcDesc = Number(data.porcDesc ?? 0);
    const partsCost = Number(data.partsCost ?? 0);
    const partCostWithoutDiscount = Number(data.partsCostWithoutDiscount ?? 0);
    const expenseAmount = Number(data.expenseAmount ?? 0);
    const materialsCost = Number(data.materialsCost ?? 0);
    const laborCost = Number(data.laborCost ?? 0);
    const ivuLabor = Number(data.ivuLaborAmount ?? 0);
    const ivuParts = Number(data.ivuPartsAmount ?? 0);

    const discount = calculateDiscount(porcDesc, partsCost);
    const subtotal = calculateSubtotal(partsCost, discount);
    const costTotalParts = calculateCostTotalParts(
      subtotal,
      partCostWithoutDiscount,
    );

    data.discountAmount = discount.toFixed(2);
    data.subtotalParts = subtotal.toFixed(2);
    data.partsCostTotal = costTotalParts.toFixed(2);

    const totalEstimated = calculateTotalEstimated(
      costTotalParts,
      expenseAmount,
      materialsCost,
      laborCost,
      ivuLabor,
      ivuParts,
      0,
    );

    data.estimatedTotalAmount = totalEstimated.toFixed(2);

    setAmountsState({ ...data });
  };

  const handleUploadFile = (event: any): void => {
    if (event?.target?.files.length > 0) {
      setFileName(event.target.files[0].name);
      setFileUpload(event.target.files[0]);
    }
  };

  return (
    <>
      <div>
        <Grid item xs={12} sm={6} md={6} className="claim-estimated">
          <Grid item xs={12} sm={6} md={6} className="claim-estimated__content">
            <Grid className="claim-estimated__data">
              {InputsEstimated[
                localStorage.getItem('countryCode') as CountryCode
              ].map((column, index) => {
                return (
                  <Grid key={index}>
                    {index === 0 && (
                      <Grid className="claim-estimated__data--title">
                        <p>ESTIMADO</p>
                      </Grid>
                    )}
                    {column.rows.map((row, index2) => {
                      return (
                        <Grid
                          key={`${index}-${index2}`}
                          className={'claim-estimated__' + row.gridClassName}
                        >
                          {row.inputsFields.map((input, index3) => {
                            switch (input.type) {
                              case 'text':
                                return (
                                  <Grid
                                    key={`${index}-${index2}-${index3}`}
                                    className="claim-estimated__data"
                                  >
                                    {input.icon && (
                                      <p className="claim-estimated__data--icon">
                                        {input.icon}
                                      </p>
                                    )}

                                    <TextField
                                      name={input.name}
                                      className={
                                        'claim-estimated__' + input.className
                                      }
                                      label={input.label}
                                      value={formatNumber(
                                        amountsState[input.value],
                                      )}
                                      onChange={onCalcEstimated}
                                      disabled={input.readonly ?? disabled}
                                    ></TextField>
                                  </Grid>
                                );
                              case 'file':
                                return (
                                  <Grid
                                    key={`${index}-${index2}-${index3}`}
                                    style={{ marginLeft: '15px' }}
                                  >
                                    <FileInput
                                      fileName={fileName}
                                      style={{ width: '300px' }}
                                      handleFileChange={handleUploadFile}
                                      disabled={disabled}
                                    />
                                  </Grid>
                                );
                              default:
                                return <></>;
                            }
                          })}
                        </Grid>
                      );
                    })}
                  </Grid>
                );
              })}
            </Grid>
            {dataHistoric?.length > 0 && (
              <Grid className="claim-estimated__historic">
                <Grid>
                  <p>HISTÓRICO DE ESTIMADOS</p>
                </Grid>
                <Grid className="claim-estimated__table">
                  {selectionFields.length > 0 && (
                    <Grid className="claim-estimated__table--options">
                      <span>{selectionFields.length} items selected</span>
                      <Grid className="claim-estimated__table--options--actions">
                        <Button
                          text="Eliminar"
                          variant="text"
                          style={{ color: 'white', fontSize: 14 }}
                          onClick={openConfirmDialog}
                        ></Button>
                        <span>|</span>
                        <Button
                          text="Cancel"
                          variant="text"
                          style={{ color: 'white', fontSize: 14 }}
                          onClick={handleCancel}
                        ></Button>
                      </Grid>
                    </Grid>
                  )}
                  <DataTable
                    columns={columns}
                    data={dataHistoric}
                    showCheckBox={!disabled}
                    selectionModel={selectionFields}
                    onRowSelectionModelChange={(rows: any): void =>
                      setSelectionFields(rows)
                    }
                  ></DataTable>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} className="claim-estimated__footer">
            <Button
              disabled={validateAmounts()}
              text="Guardar Estimado"
              onClick={onClickSave}
            />
          </Grid>
        </Grid>
      </div>
      <div>
        <Grid item xs={12} sm={6} md={6}>
          {renderComponentIsEnable(
            ResourceAction.VIEW_CLAIM_ADJUST_SCREEN,
            accountReference,
          ) && (
            <ClaimAdjust
              claimUId={claimUId}
              totalEstimated={amountsState.estimatedTotalAmount}
              subtotalEstimated={amountsState.subtotalParts}
              disabled={disabled}
              claimForm={claimForm}
            />
          )}
        </Grid>
      </div>
      <DialogComponent
        open={openDialog}
        message="El archivo cargado supera el tamaño máximo de 3 MB"
        handleClose={() => setOpenDialog(false)}
      ></DialogComponent>
      <DialogComponent
        messageStyle={{ fontSize: '16px' }}
        message={`¿Desea eliminar los ${selectionFields.length} registros seleccionados?`}
        open={openDialogConfirm}
        handleClose={() => setOpenDialogConfirm(false)}
        handleConfirm={handleConfirm}
      />
    </>
  );
};

export default ClaimEstimated;
