import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Skeleton, Stack } from '@mui/material';
import BarChart from 'components/claims/charts/BarChart';
import PieChartComponent from 'components/claims/charts/PieChart';
import PercentageSteps from 'components/claims/percentageSteps';
import { IInspectionContext, InspectionContext } from 'context/inspections';
import { IUserContext, UserContext } from 'context/user';
import React, { useContext, useEffect, useState } from 'react';
import ClaimsTrackerClient from 'shared/clients/ClaimsTrackerClient';
import { CountryCode, Rol } from 'types/common';

export default function DashboardGrid(): JSX.Element {
  const { state: inspectionState, dispatch } = useContext<IInspectionContext>(
    InspectionContext,
  );
  const {
    state: { currentUser },
  } = useContext(UserContext) as IUserContext;

  const [barChart, setBarChart] = useState<any>(null);
  const [claimByCompanyChart, setClaimByCompanyChart] = useState<any>(null);
  const [percentage, setPercentage] = useState<any>(null);
  const [pieChart, setPieChart] = useState<any>(null);
  const isFilter = localStorage.getItem('rolId') === Rol.INSURER.toString();
  const companyIdString = localStorage.getItem('companyId');
  const companyId =
    typeof companyIdString === 'string' ? parseInt(companyIdString) : undefined;
  const rolId = parseInt(localStorage.getItem('rolId') || '');
  const countryCode = localStorage.getItem('countryCode') || '';
  const username = `${currentUser.name}`;
  let assignedEvents = 0;
  inspectionState.statusesIndicators
    .filter((i) => ['Creado', 'Completado'].includes(i.statusName))
    .forEach((a) => (assignedEvents += a.currentRows));

  const loadStatistics = async (): Promise<void> => {
    try {
      if (currentUser.userCompanyID && currentUser.userCompanyID > 0) {
        const result = await new ClaimsTrackerClient().getClaimStatistics(
          countryCode,
          rolId,
          companyId,
          isFilter,
        );

        setPieChart(result?.percentageByType);
        setBarChart(result?.averageByStage);
        setPercentage(result?.percentageByStage);
      }
    } catch (e) {
      console.warn(e);
    }
  };

  useEffect(() => {
    loadStatistics();
  }, [currentUser]);

  return (
    <div className="dashboardGridContainer">
      <div className="dashboardGridContainer_title">
        <div>☀️ Buenos Días - {username}</div>
        <a
          href="https://lookerstudio.google.com/reporting/5fe5ccc0-d958-444c-87fa-cb88ac72523e/page/p_8f4hp6ep8c"
          className="dashboardGridContainer_link"
          target="_blank"
          rel="noopener noreferrer"
        >
          Ver estadísticas completas <ArrowForwardIcon />
        </a>
      </div>
      <Stack
        spacing={{ xs: 1, sm: 1, md: 1, xl: 3 }}
        direction="row"
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        {barChart ? (
          <Box className="eventStatuses-claim__box">
            <label>Tiempo promedio por estado</label>
            <BarChart data={barChart} isAverageChart />
          </Box>
        ) : (
          <Stack spacing={1}>
            <Skeleton variant="rounded" height={200} width={400} />
          </Stack>
        )}
        {percentage ? (
          <Box className="eventStatuses-claim__box">
            <label>Reclamaciones por estado</label>
            <PercentageSteps data={percentage} />
          </Box>
        ) : (
          <Stack spacing={1}>
            <Skeleton variant="rounded" height={200} width={400} />
          </Stack>
        )}
        {pieChart && (countryCode as CountryCode) === CountryCode.PRI ? (
          <Box className="eventStatuses-claim__box">
            <Stack direction="row" spacing={2}>
              <Box flex={2}>
                <Stack spacing={1}>
                  <label>Reclamaciones por tipo de reclamante</label>
                  <Box className="claimantInfo">
                    <Stack direction="column" spacing={1}>
                      <Stack direction="row" spacing={2}>
                        {pieChart.map((item: any) => (
                          <Box key={item.number}>
                            <div className="claimantInfo__title">
                              <div
                                className={`circle ${
                                  item.claimantType === 'Perjudicado'
                                    ? 'circle__blue'
                                    : 'circle__orange'
                                }`}
                              ></div>
                              {item.claimantType}
                            </div>
                            <div className="claimantInfo__casesNumber">
                              {item.number}
                            </div>
                            <div className="claimantInfo__description">
                              casos
                            </div>
                          </Box>
                        ))}
                      </Stack>
                    </Stack>
                  </Box>
                </Stack>
              </Box>
              <Box flex={1}>
                <PieChartComponent data={pieChart} />
              </Box>
            </Stack>
          </Box>
        ) : (countryCode as CountryCode) !== CountryCode.PAN ? (
          <Stack spacing={1}>
            <Skeleton variant="rounded" height={200} width={400} />
          </Stack>
        ) : null}
        {countryCode === CountryCode.PAN ? (
          claimByCompanyChart ? (
            <Box className="eventStatuses-claim__box">
              <label>Casos por Compañía</label>
              <BarChart data={claimByCompanyChart} />
            </Box>
          ) : (
            <Stack spacing={1}>
              <Skeleton variant="rounded" height={200} width={400} />
            </Stack>
          )
        ) : null}
      </Stack>
    </div>
  );
}
