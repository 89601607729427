import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

interface DataItem {
  name: string;
  days: number;
}

interface BarChartProps {
  data: DataItem[];
  isAverageChart?: boolean;
}

const BarChartComponent: React.FC<BarChartProps> = ({ data, isAverageChart, }) => {
  const customTickFormatter = (name: string): string => {
    switch (name) {
      case 'Auditoría':
        return 'Aud';
      case 'Inspección':
        return 'Insp';
      case 'Ajuste de Daños':
        return 'Ajus';
      case 'Estimado de Daños':
        return 'Esti';
      case 'Pendiente de Aprobación':
        return 'Apr';
      case 'Procesado Asegurador':
        return 'Proc';
      case 'Pendiente Pago':
        return 'PenP';
      case 'Acuerdo Final':
        return 'Acf';
      case 'Caso Finalizado':
        return 'Fin';
      default:
        return name;
    }
  };

  const customTooltipFormatter = (value: number, name: string): [string, string] => {
    return [`${value}`, 'días'];
  };

  return (
    <ResponsiveContainer width={540} height={120}>
      <BarChart
        data={data}
        margin={{
          top: 10,
          left: -30,
        }}
        barSize={12}
      >
        <XAxis
          dataKey='name'
          scale='point'
          padding={{ left: 15, right: 15 }}
          axisLine={false}
          tick={{ fontSize: 12 }}
          tickFormatter={customTickFormatter}
        />
        <YAxis ticks={isAverageChart ? [1, 3, 5] : undefined}
          domain={[1, 5]} axisLine={false} tick={{ fontSize: 12 }} />
        {isAverageChart ? <Tooltip formatter={customTooltipFormatter} /> : null}
        <CartesianGrid strokeDasharray='2 3' />
        <Bar
          dataKey="days"
          fill="#F4815C"
          background={{ fill: '#CADAE8', radius: [7, 7, 0, 0] }}
          radius={[7, 7, 4, 4]}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default BarChartComponent;
