import React, { useContext } from 'react';
import {
  Typography,
  Button,
  TextField,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import Loader from 'components/common/loader/Loader';
import ValidationLabel from 'components/common/validation-label';
import { IUserContext, renewPassword, UserContext } from 'context/user';
import { ActionType } from 'types/action';
import style from './override-mui-styles';
import './styles.scss';
import { validatePassword } from 'shared/utils';

interface ModalProps {
  style: any;
  open: boolean | false;
  isError: boolean | false;
  onClick?: (requestCode: boolean) => void;
}

const ConfirmModal = (props: ModalProps) => {
  return (
    <Dialog aria-labelledby="dialog-title" open={props.open}>
      <DialogTitle id="dialog-title">SIC | Cuenta de Usuario</DialogTitle>
      <DialogContent>
        <Typography
          component="h4"
          variant="h4"
          className={clsx('description', props.style.description)}
        >
          {!props.isError && (
            <>
              El usuario ha sido actualizado satisfactoriamente. En este momento
              ya puedes hacer uso de la plataforma.
            </>
          )}

          {props.isError && (
            <>
              <p>
                No se ha podido actualizar la cuenta de usuario. Por favor
                verificar si el código de verificación ha expirado y/o si fue
                usado.
              </p>
              <p>
                Si desea puede realizar nuevamente el{' '}
                <Button
                  color="primary"
                  onClick={() => {
                    if (props.onClick) {
                      props.onClick(true);
                    }
                  }}
                >
                  proceso de solicitud
                </Button>
              </p>
            </>
          )}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          type="button"
          color="primary"
          onClick={() => {
            if (props.onClick) {
              props.onClick(false);
            }
          }}
          autoFocus
        >
          {!props.isError && <>Aceptar</>}
          {props.isError && <>Cerrar</>}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const RenewCredentials = (): JSX.Element => {
  const overrideMuiClasses = style();
  const { handleSubmit, register, errors } = useForm();
  const { state: userState, dispatch } = useContext<IUserContext>(UserContext);
  const { loading } = userState;
  const currentHistory = useHistory();

  const onSubmit = async (data: any) => {
    dispatch({
      type: ActionType.SET_LOADING,
      payload: true,
    });
    await renewPassword(data, dispatch);
    dispatch({
      type: ActionType.SET_LOADING,
      payload: false,
    });
  };

  const returnToLogin = (): void => {
    currentHistory.push('/');
  };

  const requestAccess = (): void => {
    currentHistory.push('/request-access');
  };

  const closeAlert = (requestCode: boolean): void => {
    const isError = userState.isErrorChangePasswdAlert;
    dispatch({
      type: ActionType.SET_ALERT_CHANGE_PASSWORD,
      payload: {
        show: false,
        isError: false,
      },
    });

    if (requestCode) {
      requestAccess();
    } else if (!isError) {
      returnToLogin();
    }
  };

  return (
    <>
      <Loader isActive={loading} />
      <Typography
        className={clsx('title', overrideMuiClasses.title)}
        component="h1"
        variant="h2"
      >
        Renovar Acceso
      </Typography>
      <Typography
        className={clsx('subtitle', overrideMuiClasses.subtitle)}
        component="h4"
        variant="h4"
      >
        Datos de verificación
      </Typography>
      <Typography
        className={clsx('description', overrideMuiClasses.description)}
        component="h4"
        variant="h4"
      >
        Complete la información requerida para actualizar su cuenta:
      </Typography>
      <form className={'form'} onSubmit={handleSubmit(onSubmit)} noValidate>
        <TextField
          error={errors.name !== undefined}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="verifyCode"
          label="Código de Verificación"
          name="verifyCode"
          autoFocus
          inputRef={register({ required: 'Código es requerido' })}
          InputProps={{
            classes: {
              input: overrideMuiClasses.loginTextField,
            },
          }}
        />
        {errors.verifyCode && (
          <ValidationLabel message={errors.verifyCode.message} isError={true} />
        )}

        <TextField
          error={errors.username !== undefined}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="username"
          label="Correo de usuario"
          name="username"
          autoComplete="email"
          autoFocus
          inputRef={register({ required: 'Correo es requerido' })}
          InputProps={{
            classes: {
              input: overrideMuiClasses.loginTextField,
            },
          }}
        />
        {errors.username && (
          <ValidationLabel message={errors.username.message} isError={true} />
        )}

        <TextField
          error={errors.newPassword !== undefined}
          variant="outlined"
          margin="normal"
          required
          fullWidth
          name="newPassword"
          label="Nueva Contraseña"
          type="password"
          id="newPassword"
          autoComplete="newPassword"
          inputRef={register({
            required: 'Nueva Contraseña es requerida',
            validate: (value) => validatePassword(value),
          })}
          InputProps={{
            classes: {
              input: overrideMuiClasses.loginTextField,
            },
          }}
        />
        {errors.newPassword && (
          <ValidationLabel
            message={
              errors.newPassword.type === 'validate'
                ? 'Contraseña es insegura'
                : errors.newPassword.message
            }
            isError={true}
          />
        )}

        <Grid>
          <Grid item xs={12}>
            <Grid container spacing={10}>
              <Grid item xs={4}>
                <Button
                  type="button"
                  variant="contained"
                  className={overrideMuiClasses.back}
                  onClick={() => returnToLogin()}
                >
                  Volver
                </Button>
              </Grid>
              <Grid item xs={8}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={overrideMuiClasses.submitNewPassword}
                >
                  Enviar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <ConfirmModal
        open={userState.showChangePasswdAlert}
        isError={userState.isErrorChangePasswdAlert}
        onClick={closeAlert}
        style={overrideMuiClasses}
      />
    </>
  );
};

export default RenewCredentials;
