import {
  Cancel,
  GetApp,
  OpenInNew,
  PhotoCamera,
  DeviceHub,
  Restore,
} from '@mui/icons-material';
import { MenuOption } from 'components/events/table/menu';
import { MenuOptionPI } from 'components/pre-inspection/table/menu';
import React from 'react';
import {
  downloadEventFromAction,
  getUrlByEvent,
  confirmActionIntegration,
} from '../../context/inspections';
import {
  downloadPreinspection,
  getUrlByPreInspection,
} from '../../context/preinspections/preInspectionUtils';

const GRID_COLUMNS = {
  MENU: { name: 'menu', header: 'Acciones' },
  AVATAR: { name: 'avatar', header: '' },
  STATUS: { name: 'status', header: 'Estado' },
  RECORD: { name: 'record', header: 'Expediente' },
  DATE: { name: 'date', header: 'Fecha' },
  PLATE: { name: 'plate', header: 'Placa' },
  INSURANCE: { name: 'insurance', header: 'Poliza' },
  COMPANY: { name: 'company', header: 'Compañia' },
  INSPECTOR: { name: 'inspector', header: 'Inspector' },
  PROVIDER: { name: 'provider', header: 'Proveedor' },
  AAP: { name: 'aap', header: 'AAP' },
  AJUSTE_EXPRESS: { name: 'ajuste_express', header: 'Acompañamiento' },
  QUALI_EXPRESS: { name: 'quali_express', header: 'QualiExpress' },
  ESTIMATION: { name: 'estimate', header: 'Estimación' },
  AUDITED: { name: 'audited', header: 'Auditado' },
  SISE_REPORT: { name: 'siseReport', header: 'SISE Reporte' },
  SISE_SINIESTRO: { name: 'siseSiniestro', header: 'SISE Siniestro' },
  NOTES: { name: 'notes', header: '' },
};

const GRID_USER_COLUMNS = {
  NAME: { name: 'name', header: 'Nombre' },
  EMAIL: { name: 'email', header: 'Correo' },
  COMPANY: { name: 'company', header: 'Compañia' },
  ROLE: { name: 'role', header: 'Perfil' },
  STATUS: { name: 'status', header: 'Estado' },
  ACTIONS: { name: 'actions', header: '' },
};

const GRID_USER_DRIVER_COLUMNS = {
  NAME: { name: 'driverCode', header: 'Código de Conductor' },
  STATUS: { name: 'isActive', header: 'Estado' },
  ACTIONS: { name: 'actions', header: '' },
};

const MENU_TABLE_OPTIONS: MenuOption[] = [
  {
    value: 'DOWNLOAD_INSPECTION',
    label: 'Descargar',
    icon: <GetApp />,
    action: downloadEventFromAction,
    isActive: true,
  },
  {
    value: 'SEE_IMAGES',
    label: 'Ver imágenes',
    icon: <PhotoCamera />,
    isActive: false,
  },
  {
    value: 'OPEN_FORM',
    label: 'Abrir formulario',
    icon: <OpenInNew />,
    action: getUrlByEvent,
    isActive: true,
  },
  {
    value: 'CANCEL',
    label: 'Cancelar',
    icon: <Cancel />,
    isActive: true,
  },
  {
    value: 'INTEGRATION',
    label: 'Enviar Integración',
    icon: <DeviceHub />,
    action: confirmActionIntegration,
    isActive: true,
  },
];

const MENU_LIGHT: MenuOption[] = [
  {
    value: 'SEE_IMAGES',
    label: 'Ver imágenes',
    icon: <PhotoCamera />,
    isActive: false,
  },
  {
    value: 'OPEN_FORM',
    label: 'Abrir formulario',
    icon: <OpenInNew />,
    action: getUrlByEvent,
    isActive: true,
  },
  {
    value: 'CANCEL',
    label: 'Cancelar',
    icon: <Cancel />,
    isActive: true,
  },
  {
    value: 'RESTORE',
    label: 'Restablecer',
    icon: <Restore />,
    isActive: true,
  },
];

const MENU_PREINSPECTION: MenuOptionPI[] = [
  {
    value: 'DOWNLOAD_PREINSPECTION',
    label: 'Descargar',
    icon: <GetApp />,
    actionPI: downloadPreinspection,
    isActive: true,
  },
  {
    value: 'OPEN_FORM',
    label: 'Abrir formulario',
    icon: <OpenInNew />,
    actionPI: getUrlByPreInspection,
    isActive: true,
  },
  {
    value: 'SEE_IMAGES',
    label: 'Ver imágenes',
    icon: <PhotoCamera />,
    isActive: false,
  },
];

export {
  GRID_COLUMNS,
  MENU_TABLE_OPTIONS,
  MENU_LIGHT,
  MENU_PREINSPECTION,
  GRID_USER_COLUMNS,
  GRID_USER_DRIVER_COLUMNS,
};
