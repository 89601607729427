import React, { useContext } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SvgIcon from '@mui/material/SvgIcon';

import styles from './override-mui-styles';
import { DispatchFunction } from 'types/action';
import { IInspectionState } from 'types/inspection';
import { IInspectionContext, InspectionContext } from 'context/inspections';

export interface MenuOption {
  value: string;
  label: string;
  icon: JSX.Element;
  isActive?: boolean;
  action?: (
    event: any,
    state: IInspectionState,
    dispatch: DispatchFunction,
  ) => void;
}

interface Props {
  anchorElement: null | HTMLElement;
  menuOptions: MenuOption[];
  currentValue?: any;
  onClickMenu: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onCloseMenu: () => void;
  onCloseTrigger?: (type?: string, value?: any) => void;
}

const TableMenu = (props: Props): JSX.Element => {
  const classes = styles();
  const { state, dispatch } = useContext<IInspectionContext>(InspectionContext);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  return (
    <Menu
      anchorEl={props.anchorElement}
      open={Boolean(props.anchorElement)}
      onClose={props.onCloseMenu}
      classes={{
        paper: classes.menu,
      }}
      onClick={handleClick}
    >
      {props.menuOptions.filter((f) => f.isActive === undefined || f.isActive === true).map((option: MenuOption) => (
        <MenuItem
          key={option.value}
          className={classes.menuOption}          
          onClick={() => {
            if (option.action) {
              option.action(props.currentValue, state, dispatch);
            }
            props.onCloseMenu();
          }}
        >
          <SvgIcon fontSize="small" className={classes.menuOptionIcon}>
            {option.icon}
          </SvgIcon>
          {option.label}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default TableMenu;
