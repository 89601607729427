import { Box } from '@mui/material';
import React, { FunctionComponent } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import './index.scss';

interface ClaimStepsProps {
  claim: any;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'white',
    color: '#6D788B',
    fontStyle: 'normal',
    fontSize: '14px',
    letterSpacing: '0.5px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontWeight: '600',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const History: FunctionComponent<ClaimStepsProps> = ({
  claim
}): JSX.Element => {
  const formatDate = (dateString: string) => {
    dayjs.extend(utc);
    const date = dayjs(dateString);
    const formattedDate = date
      .utc(true)
      .local()
      .format('D MMMM YYYY, h:mm:ss A');

    return formattedDate;
  };

  return (
    <Box  sx={{ paddingTop: '1rem' }}>
      <label>Historial del caso</label>
      <TableContainer component={Paper} className="TableContainer">
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Nombre</StyledTableCell>
              <StyledTableCell align="left">Email</StyledTableCell>
              <StyledTableCell align="left">Fecha</StyledTableCell>
              <StyledTableCell align="left">Descripción</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {claim.length > 0 ? (
              claim.map((row: any, index: any) => (
                <StyledTableRow key={`${index}-${row.name}`}>
                  <StyledTableCell component="th" scope="row">
                    {row.name}
                  </StyledTableCell>
                  <StyledTableCell align="left">{row.email}</StyledTableCell>
                  <StyledTableCell align="left">
                    {formatDate(row.createdDate)}
                  </StyledTableCell>
                  <StyledTableCell align="left">{row.message}</StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <p>Sin información</p>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default History;
