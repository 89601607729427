import React from 'react';
import clsx from 'clsx';
import { Divider, Grid, TextField } from '@mui/material';
import { IClaim } from 'types/claimsTracker';

interface props {
  currentClaim?: IClaim;
}

export default function ClaimInfo({ currentClaim }: props): JSX.Element {
  return (
    <>
      <div className="title-container">
        <h2 className="title">Datos del Reclamante</h2>
      </div>
      <Divider />
      <Grid container className="claimsForm__row" spacing={2}>
        <Grid key={'form-field-0'} item md={3} xs={12}>
          <TextField
            id="claim-owner-firstName"
            label="Nombre Dueño"
            className={clsx('claimsForm__input')}
            value={currentClaim?.owner?.firstName}
            variant="outlined"
            
          />
        </Grid>
        <Grid key={'form-field-1'} item md={3} xs={12}>
          <TextField
            id="claim-owner-lastName"
            label="Apellido Dueño"
            className={clsx('claimsForm__input')}
            value={currentClaim?.owner?.lastName}
            variant="outlined"
            disabled
          />
        </Grid>
        <Grid key={'form-field-2'} item md={3} xs={12}>
          <TextField
            id="claim-owner-phone"
            label="Teléfono"
            className={clsx('claimsForm__input')}
            value={currentClaim?.owner?.phone}
            variant="outlined"
            disabled
          />
        </Grid>
        <Grid key={'form-field-3'} item md={3} xs={12}>
          <TextField
            id="claim-owner-policy"
            label="Póliza"
            className={clsx('claimsForm__input')}
            value={currentClaim?.owner?.policyNumber}
            variant="outlined"
            disabled
          />
        </Grid>
      </Grid>
      <Grid container className="claimsForm__row" spacing={2}>
        <Grid key={'form-field-4'} item md={3} xs={12}>
          <TextField
            id="claim-driver-firstName"
            label="Nombre Conductor"
            className={clsx('claimsForm__input')}
            value={currentClaim?.driver?.firstName}
            variant="outlined"
            disabled
          />
        </Grid>
        <Grid key={'form-field-5'} item md={3} xs={12}>
          <TextField
            id="claim-driver-lastName"
            label="Apellido Conductor"
            className={clsx('claimsForm__input')}
            value={currentClaim?.driver?.lastName}
            variant="outlined"
            disabled
          />
        </Grid>
      </Grid>
      <div className="title-container">
        <h2 className="title">Datos del Vehículo</h2>
      </div>
      <Divider />
      <Grid container className="claimsForm__row" spacing={2}>
        <Grid key={'form-field-6'} item md={3} xs={12}>
          <TextField
            id="claim-vehicle-make"
            label="Marca"
            className={clsx('claimsForm__input')}
            value={currentClaim?.vehicle?.make}
            variant="outlined"
            disabled
          />
        </Grid>
        <Grid key={'form-field-7'} item md={3} xs={12}>
          <TextField
            id="claim-vehicle-model"
            label="Modelo"
            className={clsx('claimsForm__input')}
            value={currentClaim?.vehicle?.model}
            variant="outlined"
            disabled
          />
        </Grid>
        <Grid key={'form-field-8'} item md={3} xs={12}>
          <TextField
            id="claim-vehicle-year"
            label="Año"
            className={clsx('claimsForm__input')}
            value={currentClaim?.vehicle?.year}
            variant="outlined"
            disabled
          />
        </Grid>
        <Grid key={'form-field-9'} item md={3} xs={12}>
          <TextField
            id="claim-vehicle-color"
            label="Color"
            className={clsx('claimsForm__input')}
            value={currentClaim?.vehicle?.color}
            variant="outlined"
            disabled
          />
        </Grid>
        <Grid key={'form-field-10'} item md={3} xs={12}>
          <TextField
            id="claim-vehicle-plate"
            label="Tablilla"
            className={clsx('claimsForm__input')}
            value={currentClaim?.vehicle?.plate}
            variant="outlined"
            disabled
          />
        </Grid>
        <Grid key={'form-field-11'} item md={3} xs={12}>
          <TextField
            id="claim-vehicle-vin"
            label="VIN"
            className={clsx('claimsForm__input')}
            value={currentClaim?.vehicle?.vin}
            variant="outlined"
            disabled
          />
        </Grid>
      </Grid>
    </>
  );
}
