import './index.scss';
import React, { useEffect, useState } from 'react';
import { FunctionComponent } from 'react';
import { Field } from 'formik';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { formatNumber, maskValue } from 'shared/utils';
import { IClaimForm } from 'types/claimsTracker';
import ClaimsTrackerClient from 'shared/clients/ClaimsTrackerClient';
import CATALOGS from 'static/constants/catalogs';
import Button from '../button';
import APIClient, { endpoints } from 'shared/api';

interface Props {
  claimUId: string;
  claimForm?: IClaimForm;
  notifySubrogation?: boolean;
  disabled: boolean;
}

const Subrogation: FunctionComponent<Props> = ({
  claimUId,
  claimForm,
  notifySubrogation,
  disabled,
}): JSX.Element => {
  const [firstRender, setFirstRender] = useState<boolean>(true);
  const [confirmApplySubroga, setConfirmApplySubroga] = useState<boolean>(
    false,
  );
  const [subrogIsApply, setSubrogIsApply] = useState<boolean>(false);
  const [subrogCheckValue, setSubrogCheckValue] = useState<boolean>(false);
  const [subrogAmountRecovered, setSubrogAmountRecovered] = useState<boolean>(
    false,
  );
  const [subrogRecoveryAmount, setSubrogRecoveryAmount] = useState<number>(0);
  const [subrogNonRecoveryCause, setSubrogNonRecoveryCause] = useState<string>(
    '',
  );
  const [subrogTxtDate, setSubrogTxtDate] = useState<string>('');
  const [insuranceRequired, setInsuranceRequired] = useState<string>('subrogation__data__large');
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [openSendMail, setOpenSendMail] = useState<boolean>(false);
  const [sendMail, setSendMail] = useState<boolean>(false);

  const [companies, setCompanies] = useState([]);
  const [insuranceValue, setInsuranceValue] = useState('');
  const [insurance, setInsurance] = useState('');
  const [insuranceFinal, setInsuranceFinal] = useState('');
  const [insuranceOther, setInsuranceOther] = useState<boolean>(false);

  const handleIsApplyChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    setSubrogCheckValue(value === 'true');
    if (value === 'true' && notifySubrogation) {      
        setOpenSendMail(true);

        return;      
    }

    setSendMail(value === 'true');
    setSubrogIsApply(value === 'true');
    if (firstRender) {
      setFirstRender(false);
    }
  };

  const handleSendEmailChange = (value: boolean) => {
    setSendMail(value);
    setSubrogIsApply(subrogCheckValue);
    if (firstRender) {
      setFirstRender(false);
    }
    setOpenSendMail(false);
  }

  const handleIsRecoveredChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    setSubrogAmountRecovered(value === 'true');
    if (firstRender) {
      setFirstRender(false);
    }
  };

  const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const numberValue = Number(value.replace(/[^0-9.-]+/g, ''));
    setSubrogRecoveryAmount(numberValue);
  };

  const handleNonRecoveryCauseChange = (event: SelectChangeEvent) => {
    setSubrogNonRecoveryCause(event.target.value);
  };

  const handleCompanyListChange = (event: SelectChangeEvent) => {
    setInsuranceValue(event.target.value);
    if (event.target.value === 'Otro') {
      setInsurance('');
      setInsuranceFinal('')
      setInsuranceOther(true);
      setInsuranceRequired('subrogation__data__large subrogation__required');
    } else {
      if (firstRender) {
        setFirstRender(false);
      }
      setInsuranceOther(false);
      setInsurance(event.target.value);
      setInsuranceFinal(event.target.value)
    }
  };

  const handleInsuranceChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (firstRender) {
      setFirstRender(false);
    }
    setInsurance(event.target.value);
    setInsuranceRequired('subrogation__data__large');
    if(event.target.value.length===0){     
      setInsuranceRequired('subrogation__data__large subrogation__required'); 
    }
  };

  const submitSubrogation = async (confirmSave = false): Promise<void> => {
    const amountRecovered = subrogIsApply ? subrogAmountRecovered : false;
    const insuranceData = subrogIsApply ? insuranceFinal : null;
    const recoveryAmount =
      subrogIsApply && subrogAmountRecovered ? subrogRecoveryAmount : null;
    const nonRecoveryCause =
      subrogIsApply && !subrogAmountRecovered ? subrogNonRecoveryCause : null;
    const subrogationDate =
      subrogIsApply && confirmSave && dayjs(subrogTxtDate).isValid()
        ? subrogTxtDate
        : null;
    setConfirmApplySubroga(
      subrogIsApply &&
        confirmSave &&
        dayjs(subrogTxtDate).isValid() &&
        insuranceFinal.length > 0,
    );

    await new ClaimsTrackerClient().applySubrogation(
      claimUId,
      subrogIsApply,
      sendMail,
      amountRecovered,
      recoveryAmount,
      nonRecoveryCause,
      subrogationDate,
      insuranceData,
      Number(localStorage.getItem('userId')),
    );
  };

  const confirmSubrogation = (confirmSave = false): void => {
    (async (): Promise<void> => {
      await submitSubrogation(confirmSave);
      setOpenConfirm(false);
    })();
  };

  const blurInsurance = (event) =>{
    setInsuranceFinal(event?.target.value);
  }

  const getCompanies = async () => {
    const countryCode = localStorage.getItem('countryCode');
    const response = await APIClient.get(
      endpoints.GET_COMPANIES_COUNTRY.replace('{countryCode}', String(countryCode)),
    );
    if (response && response.data?.data) {
      setCompanies(response.data?.data);
      if (claimForm?.info?.subrogation?.insurance) {
        setInsuranceOther(false);
        setInsurance(claimForm?.info?.subrogation?.insurance);
        setInsuranceFinal(claimForm?.info?.subrogation?.insurance);
        setInsuranceValue(claimForm?.info?.subrogation?.insurance);
        if (
          response?.data?.data.find(
            (x) => x.name === claimForm?.info?.subrogation?.insurance,
          ) === undefined
        ) {
          setInsuranceOther(true);
          setInsuranceValue('Otro');
        }
      }
    }
  };

  const loadData = () => {
    getCompanies();
    if (claimForm?.info?.subrogation?.isApply) {
      setSubrogIsApply(claimForm?.info?.subrogation?.isApply);      
    }

    if (claimForm?.info?.subrogation?.subrogationDate) {
      setSubrogTxtDate(claimForm?.info?.subrogation?.subrogationDate);
      setConfirmApplySubroga(true);
    }

    if (claimForm?.info?.subrogation?.recoveryAmount) {
      setSubrogRecoveryAmount(claimForm?.info?.subrogation?.recoveryAmount);
    }

    if (claimForm?.info?.subrogation?.amountRecovered) {
      setSubrogAmountRecovered(claimForm?.info?.subrogation?.amountRecovered);
    }

    if (claimForm?.info?.subrogation?.nonRecoveryCause) {
      setSubrogNonRecoveryCause(claimForm?.info?.subrogation?.nonRecoveryCause);
    }
  };
  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    loadData();
  }, [claimForm?.info?.case]);

  useEffect(() => {
    if (!firstRender) {
      confirmSubrogation(false);
    }
  }, [subrogIsApply, subrogAmountRecovered, insuranceFinal]);

  return (
    <>
      <Box className="subrogation">
        {confirmApplySubroga && dayjs(subrogTxtDate).isValid() && insurance ? (
          <Box>
            <p className="subrogation__data--title-result">
              Valor de subrogación:{' '}
              {maskValue(subrogRecoveryAmount, '###,###.00', '#', false)}
            </p>
            <p className="subrogation__data--subtitle-result">
              Causa de no recuperación:{' '}
              {subrogIsApply && !subrogAmountRecovered
                ? subrogNonRecoveryCause
                : 'N/A'}
            </p>
            <p className="subrogation__data--subtitle-result">
              Fecha de Pago: {dayjs(subrogTxtDate).format('YYYY-MM-DD')}
            </p>
          </Box>
        ) : (
          <>
            <Box className="subrogation__data">
              <Box>
                <label>¿Aplica subrogación?</label>
                <br />
                <FormControl sx={{ textAlign: 'left' }}>
                  <RadioGroup
                    row
                    aria-labelledby="apply-subrogation"
                    name="position"
                    defaultValue={
                      claimForm?.info?.subrogation?.isApply ?? false
                    }
                    onChange={handleIsApplyChange}
                  >
                    <FormControlLabel
                      disabled={disabled}
                      value={true}
                      control={<Radio />}
                      label="Sí"
                      labelPlacement="start"
                    />
                    <FormControlLabel
                     disabled={disabled}
                      value={false}
                      control={<Radio />}
                      label="No"
                      labelPlacement="start"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
            </Box>
            {subrogIsApply && (
              <>
                <label>
                  Seleccione la aseguradora con la cuál se realizará el recobro
                </label>
                <Box className="subrogation__data" sx={{ mt: 2 }}>
                  <Box>
                    <FormControl
                      sx={{ minWidth: 235, backgroundColor: '#ffffff' }}
                    >
                      <InputLabel id="insurances">Aseguradora</InputLabel>
                      <Select
                        labelId="insurances"
                        id="slct-insurances"
                        value={insuranceValue}
                        onChange={handleCompanyListChange}
                        label="Aseguradora"
                      >
                        {companies?.map((item: any) => {
                          return (
                            <MenuItem key={item.id} value={item.name}>
                              {item.name}
                            </MenuItem>
                          );
                        })}
                        <MenuItem value={'Otro'}>Otro</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  {insuranceOther && (
                    <Box className="subrogation__w500">
                      <Field                
                        fullWidth        
                        key="insurance-amount"
                        className={insuranceRequired}
                        as={TextField}
                        type="text"
                        label="Digite el nombre de la aseguradora"
                        name="company-amount"
                        value={insurance}
                        onChange={handleInsuranceChange}
                        onBlur={blurInsurance}
                      />
                    </Box>
                  )}
                </Box>
                <Box className="subrogation__data" sx={{ mt: 2 }}>
                  <Box>
                    <label>¿Se recuperó algún monto?</label>
                    <br />
                    <FormControl sx={{ textAlign: 'left' }}>
                      <RadioGroup
                        row
                        aria-labelledby="amount-recovered-subrogation"
                        name="position"
                        defaultValue={
                          claimForm?.info?.subrogation?.amountRecovered ?? false
                        }
                        onChange={handleIsRecoveredChange}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="Sí"
                          labelPlacement="start"
                          disabled={disabled && !subrogIsApply}
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="No"
                          labelPlacement="start"
                          disabled={disabled && !subrogIsApply}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Box>

                <Box className="subrogation__data">
                  {subrogAmountRecovered && (
                    <Box className="subrogation__amount">
                      <br />
                      <Field
                        key="recovery-amount"
                        as={TextField}
                        type="text"
                        label="Valor de recuperación"
                        name="recovery-amount"
                        value={formatNumber(subrogRecoveryAmount.toString())}
                        onChange={handleAmountChange}
                      />
                    </Box>
                  )}

                  {!subrogAmountRecovered && (
                    <Box>
                      <br />
                      <FormControl
                        sx={{ minWidth: 235, backgroundColor: '#ffffff' }}
                      >
                        <InputLabel id="nonRecoveryCause">
                          Causa de no recuperación
                        </InputLabel>
                        <Select
                          labelId="nonRecoveryCause"
                          id="slct-NonRecoveryCause"
                          value={subrogNonRecoveryCause}
                          onChange={handleNonRecoveryCauseChange}
                          label="Causa de no recuperación"
                        >
                          <MenuItem value={'Option 1'}>Option 1</MenuItem>
                          <MenuItem value={'Option 2'}>Option 2</MenuItem>
                          <MenuItem value={'Option 3'}>Option 3</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  )}

                  <Box>
                    <br />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DesktopDatePicker
                        label="Fecha"
                        format={CATALOGS.getCountryFormat().dateTimeFormat}
                        value={dayjs(subrogTxtDate)}
                        slotProps={{
                          textField: {
                            variant: 'outlined',
                            margin: 'none',
                            'aria-label': 'change date',
                          },
                        }}
                        onChange={(date: any) => {
                          if (date) {
                            const dayjsDate = dayjs(date);
                            if (dayjsDate.isValid()) {
                              setSubrogTxtDate(dayjsDate.format('YYYY-MM-DD'));
                            }
                          }
                        }}
                      />
                    </LocalizationProvider>
                  </Box>
                  <Box>
                    <br />
                    <Button
                      text="Aplicar Subrogación"
                      type="button"
                      onClick={() => setOpenConfirm(true)}
                    />
                  </Box>
                </Box>
              </>
            )}
          </>
        )}
      </Box>

      <Dialog open={openConfirm}>
        <DialogTitle id="confirm-restore-title">Confirmar</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="confirm-payment-modal"
            style={{ width: '400px', height: '120px' }}
          >
            <br />
            ¿Está seguro que desea aplicar los cambios de subrogación?
            <br />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            text="Cancelar"
            variant="outlined"
            color="secondary"
            onClick={() => setOpenConfirm(false)}
          />
          <Button
            text="Sí, confirmar"
            variant="contained"
            color="primary"
            onClick={() => {
              confirmSubrogation(true);
            }}
          />
        </DialogActions>
      </Dialog>

      <Dialog open={openSendMail}>
        <DialogTitle id="confirm-restore-title">Confirmar</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="confirm-payment-modal"
            style={{ width: '400px', height: '120px' }}
          >
            <br />
            ¿Quiere notificar por Email a la aseguradora de la actualización del caso?
            <br />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            text="Cancelar"
            variant="outlined"
            color="secondary"
            onClick={() => handleSendEmailChange(false)}
          />
          <Button
            text="Sí, confirmar"
            variant="contained"
            color="primary"
            onClick={() => handleSendEmailChange(true)}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Subrogation;
