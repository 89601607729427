import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { TabPanel } from 'components/common/tabpanel/TabPanel';
import style from 'override-mui-styles';
import { useHistory } from 'react-router';
import { ActionType } from 'types/action';
import { FieldSection } from 'types/section-fields';
import SectionFields from '../section/SectionFields';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Tab,
  Tabs,
} from '@mui/material';
import {
  addInjuredToEvent,
  deleteInjuredPerson,
  IInspectionContext,
  InspectionContext,
  saveInspection,
} from 'context/inspections';
import React, {
  Fragment,
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { IInjuryPerson, IInspection } from 'types/inspection';
import { Rol } from 'types/common';
import { Field } from 'types/form-field';

interface InjuryPersonProps {
  isInspectorForm: boolean;
  sections: FieldSection[];
  setSections: (sections: FieldSection[]) => void;
  fetchInspection?: () => Promise<IInspection | null>;
  formValidation: (field?: Field, isFinish?: boolean) => boolean;
}

const getInjuryQuery = (query: string) => {
  return Number(new URLSearchParams(query).get('injury')) || 0;
};

const InjuryPersonForm: FunctionComponent<InjuryPersonProps> = ({
  isInspectorForm,
  sections,
  fetchInspection,
  formValidation,
}): JSX.Element => {
  const { state: inspectionState, dispatch } = useContext<IInspectionContext>(
    InspectionContext,
  );
  const injuryPersons = inspectionState.currentInspection?.injuryPersons?.length
    ? inspectionState.currentInspection?.injuryPersons
    : [];

  const history = useHistory();
  const injury = getInjuryQuery(history.location.search);
  const isAuditProcess =
    Number(localStorage.getItem('rolId')) === Rol.AUDIT && !isInspectorForm;
  const [tabValue, setTabValue] = useState<number>(0);
  const [injuryPersonSections, setInjuryPersonSections] = useState(sections);
  const [open, setOpen] = useState(false);
  const [initEmptyArr, setInitEmptyArr] = useState(true);
  const [injuredAdded, setInjuredAdded] = useState(false);
  const [moveToLastTab, setMoveToLastTab] = useState(true);

  useEffect(() => {
    if (tabValue === injury) {
      return;
    }
    const value = injuryPersons.length > injury ? injury : 0;
    setTabValue(value);
    history.replace(`${history.location.pathname}?injury=${value}`);
  }, [tabValue, injuryPersons, injury, history]);

  const classes = style();

  const allProps = (index: number, id?: string) => {
    return {
      id: id || `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const autoSave = useCallback(() => {
    (async (): Promise<void> => {
      await saveInspection(
        injuryPersonSections,
        inspectionState,
        false,
        false,
        dispatch,
        {
          injuryNumber: tabValue || 0,
        },
      );

      if (
        !injuryPersons.every((t: IInjuryPerson) => t.injuryId) &&
        fetchInspection
      ) {
        await fetchInspection();
      }
    })();
  }, [
    injuryPersonSections,
    inspectionState,
    dispatch,
    tabValue,
    fetchInspection,
    injuryPersons,
  ]);

  const addInjury = useCallback(async (): Promise<void> => {
    if (inspectionState.currentInspection !== null) {
      const { EventId } = inspectionState.currentInspection;
      if (EventId !== undefined && EventId > 0) {
        dispatch({
          type: ActionType.SET_LOADING,
          payload: true,
        });

        await addInjuredToEvent(EventId);

        setTimeout(async () => {
          if (fetchInspection) {
            await fetchInspection();
          }
          dispatch({
            type: ActionType.SET_LOADING,
            payload: false,
          });

          setMoveToLastTab(true);
        }, 800);
      }
    }
  }, [dispatch, fetchInspection, inspectionState.currentInspection]);

  const removeInjury = async () => {
    if (
      injuryPersons[injury] &&
      inspectionState.currentInspection?.injuryPersons
    ) {
      const currentInj = inspectionState.currentInspection?.injuryPersons.filter(
        (f) => f.injuryNumber === injury,
      )[0];
      if (currentInj) {
        await deleteInjuredPerson(currentInj.injuryId);
        window.location.reload();
      }
    }
  };

  const setInjury = (index: number) => {
    history.replace(`${history.location.pathname}?injury=${index}`);
  };

  const parseSections = useCallback(
    (sections: FieldSection[]) => {
      return sections
        .sort((a, b) => a.idOrder - b.idOrder)
        .map((s) => ({
          ...s,
          fields: s.fields.map((f) => ({
            ...f,
            originalValue:
              f.originalValue ?? (injuryPersons[tabValue] as any)?.[f.name],
            value: (injuryPersons[tabValue] as any)?.[f.name],
            inactiveBlur: true,
          })),
        }));
    },
    [tabValue, injuryPersons],
  );

  const handleSectionChange = (sections: FieldSection[]) => {
    const injury =
      Number(new URLSearchParams(history.location.search).get('injury')) || 0;

    const _injuryPersons = [...injuryPersons];

    sections.forEach((s) => {
      s.fields.forEach(
        (f) => ((_injuryPersons[injury] as any)[f.name] = f.value),
      );
    });

    setInjuryPersonSections([...sections]);
    dispatch({
      type: ActionType.SET_INSPECTION_INJURY_PERSONS,
      payload: [..._injuryPersons],
    });
  };

  const tabClick = (index: number) => {
    if (
      !isAuditProcess ||
      (isAuditProcess && !inspectionState.auditEvent.auditIsActive)
    ) {
      setInjury(index);
    }
  };

  useEffect(() => {
    if (injuryPersons.length === 0 && initEmptyArr) {
      addInjury();
      setInjuredAdded(true);
      setInitEmptyArr(false);
    }

    if (injuredAdded) {
      autoSave();
      setInjuredAdded(false);
    }
  }, [
    injuredAdded,
    injuryPersons,
    initEmptyArr,
    autoSave,
    dispatch,
    addInjury,
  ]);

  useEffect(() => {
    if (isAuditProcess && inspectionState.auditEvent.auditInjuredSection) {
      dispatch({
        type: ActionType.SET_AUDIT_TRIGGER_INJURED_SECTION,
        payload: false,
      });
      autoSave();
    }
  }, [
    dispatch,
    inspectionState.auditEvent.auditInjuredSection,
    isAuditProcess,
    autoSave,
  ]);

  useEffect(() => {
    if (moveToLastTab) {
      let tab = injuryPersons.length - 1;
      tab = tab < 0 ? 0 : tab;

      history.replace(`${history.location.pathname}?injury=${tab}`);
      setMoveToLastTab(false);
    }
  }, [moveToLastTab, injuryPersons, history]);

  return (
    <div className="dinamic-tab-form">
      <div className="heading">
        <Tabs
          value={tabValue}
          onChange={(event: any, newValue: number) => setTabValue(newValue)}
          aria-label=""
        >
          {injuryPersons.map((t, i) => (
            <Tab
              key={i}
              label={`Lesionado ${i + 1}`}
              {...allProps(i)}
              onClick={() => tabClick(i)}
            />
          ))}
        </Tabs>
        {(!isAuditProcess ||
          (isAuditProcess && !inspectionState.auditEvent.auditIsActive)) && (
          <div className="buttons">
            <div className="add-btn" onClick={addInjury}>
              <AddCircleOutlineIcon />
            </div>
            <div className="remove-btn" onClick={() => setOpen(true)}>
              <RemoveCircleOutlineIcon />
            </div>
          </div>
        )}
      </div>
      {injuryPersons.map((t, i) => (
        <TabPanel key={i} value={tabValue} index={i}>
          {parseSections(injuryPersonSections).map((s, j) => (
            <Fragment key={j}>
              <div className="title-container">
                <h2 className="inspectionForm__title">{s.title}</h2>
              </div>
              <Divider />
              <SectionFields
                key={j}
                sections={parseSections(injuryPersonSections)}
                fields={s.fields}
                save={autoSave}
                classes={classes}
                setSections={handleSectionChange}
                isInspectorForm={isInspectorForm}
                isVirtualInspector={Boolean(
                  inspectionState.currentInspection?.IsVitualInspector,
                )}
                formValidation={formValidation}
              />
            </Fragment>
          ))}
        </TabPanel>
      ))}

      <Dialog
        onClose={(event, reason) => {
          if(reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            // Set 'open' to false, however you would do that with your particular code.
            setOpen(false);
          }
        }}
        disableEscapeKeyDown
        maxWidth="md"
        aria-labelledby="confirmation-dialog-title"
        open={open}
      >
        <DialogTitle id="confirmation-dialog-title">
          Eliminar Lesionado
        </DialogTitle>
        <DialogContent>
          ¿Seguro que desea eliminar el Lesionado {injury + 1}?
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            variant="outlined"
            onClick={() => setOpen(false)}
            color="secondary"
          >
            Cancelar
          </Button>
          <Button
            disableElevation
            variant="contained"
            color="primary"
            onClick={removeInjury}
          >
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default InjuryPersonForm;
